import React, { useEffect } from "react";
import { useState } from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import SwipeableTemporaryDrawer from "../Components/Material/MaterialSidebar";

import { useRef } from "react";
import * as XLSX from "xlsx";

import instance from "../Instance";
import Cookies from "js-cookie";
import { Backdrop, CircularProgress } from "@mui/material";

import { BarChart } from "../Components/BarGraph";
import { returnRandomColor } from "../util/returnRandomColor";
import BasicButton from "../Components/Material/Button";
import FileSaver from "file-saver";

const SchoolInsight = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolReportData, setSchoolReportData] = useState([]);

  const [year, setYear] = useState({
    start: "2023-04-01",
    end: "2024-03-31",
  });

  const [chartData, setChartData] = useState([]);
  const [productChartData, setProductChartData] = useState([]);
  const sidebarRef = useRef();

  const show = null;

  const navInfo = {
    title: "School Insight",
    details: ["Home", "/ School Insight"],
  };

  useEffect(() => {
    getSchoolData();
  }, [year]);

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };

  const getSchoolData = async () => {
    const analyticData = await instance({
      url: `reports/topSchoolsAndItems`,
      data: {
        fromDate: year.start,
        toDate: year.end,
      },
      method: "POST",
      headers: {
        Authorization: `${Cookies.get("accessToken")}`,
      },
    });

    if (analyticData.status === 200) {
      setSchoolReportData(analyticData.data.data.topSchools);
      let schoolData = Object.entries(analyticData.data.data.topSchools).map(
        (item) => {
          return {
            label: item[0],
            data: item[1]?.total,
            color: returnRandomColor(),
          };
        }
      );

      let productData = Object.entries(analyticData.data.data.topItems).map(
        (item) => {
          return {
            label: item[0],
            data: item[1],
            color: returnRandomColor(),
          };
        }
      );

      setChartData(schoolData.sort((a, b) => b.data - a.data));
      setProductChartData(productData.sort((a, b) => b.data - a.data));
    }
  };

  useEffect(() => {
    document.title = "CRM - School Insight";
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };
    window.addEventListener("resize", handleWidth);
    handleWidth();
    window.scroll(0, 0);
    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  const changeYear = (year) => {
    console.log(year);
    setLoading(true);
    setYear({ start: year.start, end: year.end });
    setLoading(false);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (type) => {
    let dataToExport = type === "school" ? schoolReportData : productChartData;
    let columnsName = [];
    if (type === "school") {
      columnsName = ["School Name", "Product", "Value"];
    } else {
      columnsName = ["Product Name", "Value"];
    }
    let reqExportData = [];
    Object.entries(dataToExport).map((item) => {
      Object.entries(item[1]).map((schoolData) => {
        if (schoolData[0] !== "total") {
          let reqObj = {
            SchoolName: item[0],
            Product: schoolData[0],
            Value: schoolData[1],
          };

          reqExportData.push(reqObj);
        }
      });
    });
    // for (let obj of dataToExport) {
    //   let reqObj = {
    //     SchoolName: obj.label,
    //     Value: obj.data,
    //   };

    //   reqExportData.push(reqObj);
    // }

    let fileName = type === "school" ? "SchoolData" : "ProductData";
    const ws = XLSX.utils.json_to_sheet(reqExportData);
    /* custom headers */
    XLSX.utils.sheet_add_aoa(ws, [columnsName], {
      origin: "A1",
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <div className="flex w-[100%] min-h-[100vh]">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Sidebar
          highLight={"manage_school"}
          sidebarCollapsed={sidebarCollapsed}
          show={show}
        />

        <div>
          <SwipeableTemporaryDrawer
            ref={sidebarRef}
            sidebarCollapsed={sidebarCollapsed}
            show={show}
            highLight={"manage_school"}
          />
        </div>
        <div
          className={`flex flex-col w-[100vw] relative transition-all ease-linear duration-300 lg:w-[83vw] lg:ml-[18vw] ${
            window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[85vw]"
          } `}
        >
          <Navbar
            handleSidebarCollapsed={handleSidebarCollapsed}
            info={navInfo}
            changeYear={changeYear}
            defaultYear={"FY 2023-24"}
          />

          <div className="min-h-[90vh] relative flex w-full justify-center items-center bg-[#141728] flex-col">
            <h1 className="text-gray-100 sm:text-2xl text-base font-semibold absolute top-[2rem] left-[2rem]">
              Schools Insights
            </h1>
            <div className="md:w-[50vw] w-full justify-center mt-[5rem] flex flex-col items-center">
              <div
                onClick={() => {
                  exportToCSV("school");
                }}
              >
                <BasicButton size={"small"} text={"Download Report"} />
              </div>
              <BarChart
                chartData={chartData}
                title={"Top 10 Schools"}
                labels={["Top 10 Schools"]}
              />
            </div>
            <div className="md:w-[50vw] w-full justify-center my-[5rem] flex flex-col items-center">
              <div
                onClick={() => {
                  exportToCSV("product");
                }}
              >
                <BasicButton size={"small"} text={"Download Report"} />
              </div>
              <BarChart
                chartData={productChartData}
                title={"Top 10 Products"}
                labels={["Top 10 Products"]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolInsight;
