import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import Button from "@mui/material/Button";

// import Divider from "@mui/material/Divider";

import logoLight from "../../assets/img/logo-light-icon.png";
import { useState } from "react";
import {
  Place,
  AccountBalance,
  Circle,
  Dashboard,
  KeyboardArrowDown,
  ListAlt,
  LocalShipping,
  LocationCityOutlined,
  LocationOn,
  School,
  ShoppingBag,
  AssignmentReturnOutlined,
  ReceiptOutlined,
  PrintOutlined,
  Timeline,
  AddTask,
  CurrencyRupee,
  Money,
  ManageSearch,
  KeyboardReturn,
  Discount,
  DocumentScanner,
  DirectionsWalk,
  Insights,
} from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import Cookies from "js-cookie";
import instance from "../../Instance";
import TransitionsModal from "./Model";
import { useRef } from "react";
import AdminSidebar from "../Sidebar/AdminSidebar";
import SalesSidebar from "../Sidebar/SalesSidebar";
// import DialogSlide from "./Dialog";

const SwipeableTemporaryDrawer = React.forwardRef((props, ref) => {
  const [modelOpen, setModelOpen] = useState(false);

  const [userType, setUserType] = useState();

  const [isZsmLogin, setIsZsmLogin] = useState(false);
  const [exp, setExp] = useState(false);


  const [isSchoolClicked, setIsSchoolClicked] = useState(
    props.show === 2 ? false : true
  );
  const [user, setUser] = useState({});
  let highLight = props.highLight;
  const [isSchoolDetailClicked, setIsSchoolDetailClicked] = useState(
    props.show === 2 ? true : false
  );

  const dialogRef = useRef();
  const openDialog = () => {
    dialogRef.current.openDialog();
  };

  useLayoutEffect(() => {
    const getUser = async () => {
      const res = await instance({
        url: "user/profile",
        method: "GET",
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
      }).catch((err) => {
        if (err.response.status === 401) {
          setModelOpen(true);
        }
      });
      setUser(res.data.message);
    };
    getUser();
  }, []);

  React.useEffect(() => {
    if (Cookies.get("type") === "zsm") {
      setIsZsmLogin(true);
    }
    const userlogintype = Cookies.get("type");
    setUserType(userlogintype);
    if (props.show === null) {
      setIsSchoolClicked(false);
      setIsSchoolDetailClicked(false);
    }
  }, []);
  const [state, setState] = React.useState({
    left: false,
  });
  const sidebarRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    openSidebar() {
      //   toggleDrawer("left", true);
      setState({ left: true });
    },
  }));

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="flex items-center gap-3 justify-center py-4">
        <img
          src={logoLight}
          className=" w-[10vw] md:w-[3.7vw] h-auto object-cover"
          alt=""
        />
        <h4 className="text-gray-100">Eupheus Learning</h4>
      </div>

      <aside className="flex flex-col px-6 text-gray-200 py-4">
        <span className="text-lg">Hi, {user.first_name}</span>
        <span className="text-sm text-gray-300">{user.emp_id}</span>
        <hr className="text-gray-100 mt-4" />
      </aside>
      {userType === "training" ? (
        <>
          <Link to="/manageSchoolTraining">
            <aside
              className={`px-6 py-2 flex gap-4 cursor-pointer ${
                highLight === "manageSchool" ? "bg-gray-500" : ""
              } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <School
                className={`${
                  highLight === "manageSchool"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "manageSchool"
                    ? "text-gray-200"
                    : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Manage School
              </span>
            </aside>
          </Link>

          <Link to="/invoice_training">
            <aside
              className={`px-6 py-2 flex gap-4 ${
                highLight === "invoice" ? "bg-gray-500" : ""
              } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <ReceiptOutlined
                className={`${
                  highLight === "invoice" ? "!text-[#659DBD]" : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "invoice" ? "text-gray-200" : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Invoice Tagging
              </span>
            </aside>
          </Link>

          <Link to="/locationTraining">
            <aside
              className={`px-6 py-2 flex gap-4 cursor-pointer ${
                highLight === "location" ? "bg-gray-500" : ""
              } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <Place
                className={`${
                  highLight === "location"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "location" ? "text-gray-200" : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Location
              </span>
            </aside>
          </Link>

          <Link to="/ck_school_training">
            <aside
              className={`px-6 py-2 flex gap-4 cursor-pointer ${
                highLight === "ckSchool" ? "bg-gray-500" : ""
              } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <School
                className={`${
                  highLight === "ckSchool"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "ckSchool" ? "text-gray-200" : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                CK Schools
              </span>
            </aside>
          </Link>

          <Link to="/order_training">
            <aside
              className={`px-6 py-2 flex gap-4 cursor-pointer ${
                highLight === "order" ? "bg-gray-500" : ""
              } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <ReceiptOutlined
                className={`${
                  highLight === "order" ? "!text-[#659DBD]" : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "order" ? "text-gray-200" : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Order Tagging
              </span>
            </aside>
          </Link>
        </>
      ) : null}

      {userType === "user" ? (
        <SalesSidebar highLight={highLight} isZsmLogin={isZsmLogin} />
      ) : null}

      {userType === "finance" ? (
        <>
          <Link to="/finance/aof">
            <aside
              className={`px-6 py-2 hover:bg-gray-500 flex ${
                highLight === "aof" ? "bg-gray-500" : ""
              } rounded-md gap-4 cursor-pointer group`}
            >
              <div className="flex gap-4">
                <Dashboard
                  className={`${
                    highLight === "aof" ? "!text-[#659DBD]" : "!text-gray-400"
                  } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
                />
                <span
                  className={`${
                    highLight === "aof" ? "text-gray-200" : "text-gray-400"
                  } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
                >
                  AOF
                </span>
              </div>
              {/* <hr className="text-gray-300" /> */}
            </aside>
          </Link>
          <Link to="/finance/discount">
            <aside
              className={`px-6 py-2 hover:bg-gray-500 flex ${
                highLight === "discount" ? "bg-gray-500" : ""
              } rounded-md gap-4 cursor-pointer group`}
            >
              <div className="flex gap-4">
                <Discount
                  className={`${
                    highLight === "discount"
                      ? "!text-[#659DBD]"
                      : "!text-gray-400"
                  } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
                />
                <span
                  className={`${
                    highLight === "discount" ? "text-gray-200" : "text-gray-400"
                  } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
                >
                  Discount
                </span>
              </div>
              {/* <hr className="text-gray-300" /> */}
            </aside>
          </Link>
        </>
      ) : null}
      {userType === "warehouse_GP" ? (
        <>
          <Link to="/warehouse/all_return">
            <aside
              className={`px-6 py-2 flex gap-4 ${
                highLight === "manage_return_req" ? "bg-gray-500" : ""
              } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <ManageSearch
                className={`${
                  highLight === "manage_return_req"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "manage_return_req"
                    ? "text-gray-200"
                    : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Manage Order Return
              </span>
            </aside>
          </Link>
        </>
      ) : null}
      {userType === "sales_coordinator" ? (
        <>
          <Link to="/sc/all_return">
            <aside
              className={`px-6 py-2 flex gap-4 ${
                highLight === "manage_return_req" ? "bg-gray-500" : ""
              } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <ManageSearch
                className={`${
                  highLight === "manage_return_req"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "manage_return_req"
                    ? "text-gray-200"
                    : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Manage Order Return
              </span>
            </aside>
          </Link>
          <Link to="/manageSchool">
            <aside
              className={`px-6 py-2 flex gap-4 cursor-pointer ${
                highLight === "manageSchool" ? "bg-gray-500" : ""
              } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <School
                className={`${
                  highLight === "manageSchool"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "manageSchool"
                    ? "text-gray-200"
                    : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Manage School
              </span>
            </aside>
          </Link>
          <Link to="/print_pdf">
            <aside
              className={`px-6 py-2 flex gap-4 ${
                highLight === "printpdf" ? "bg-gray-500" : ""
              } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <PrintOutlined
                className={`${
                  highLight === "printpdf"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "printpdf" ? "text-gray-200" : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Doc Print
              </span>
            </aside>
          </Link>
          <Link to="/tag_school_against_inv">
            <aside
              className={`px-6 py-2 flex gap-4 ${
                highLight === "tag_school" ? "bg-gray-500" : ""
              } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <DocumentScanner
                className={`${
                  highLight === "tag_school"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "tag_school" ? "text-gray-200" : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Tag School
              </span>
            </aside>
          </Link>
        </>
      ) : null}

      {userType === "user" ||
      userType === "editorial" ||
      userType === "IT" ||
      userType === "finance" ||
      userType === "training" ? (
        <Link>
          <aside
            onClick={() => setExp(!exp)}
            className={`px-6 py-2 flex justify-between gap-4 ${
              highLight === "myExpense" ? "bg-gray-500" : ""
            } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
          >
            <div className="flex gap-4">
              <Money
                className={`${
                  highLight === "myExpense"
                    ? "!text-gray-100"
                    : "!text-gray-400"
                } group-hover:!text-gray-100 !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "myExpense" ? "text-gray-200" : "text-gray-400"
                } group-hover:!text-gray-100 font-semibold transition-all duration-150 ease-linear`}
              >
                Expense Management
              </span>
            </div>
            <div
              className={`transition-all duration-200  ease-linear ${
                exp ? null : "-rotate-90"
              }`}
            >
              <KeyboardArrowDown className={`text-gray-600 `} />
            </div>
          </aside>
          <Collapse in={exp}>
            {" "}
            <div
              className={`${
                exp ? "h-fit opacity-100 visible" : null
              } transition-all ease-linear duration-200`}
            >
              <Link to="/reimbursement_report">
                <div
                  className={`flex items-center transition-all ease-linear duration-100 mr-8 ml-6 group `}
                >
                  <Circle
                    className={`!text-[.7rem] !transition-all !ease-linear !duration-200 text-gray-300`}
                  />
                  <h1
                    className={`pl-9 text-gray-300 transition-all ease-linear text-sm font-semibold duration-200  py-2 cursor-pointer`}
                  >
                    Reimbursement Report
                  </h1>
                </div>
              </Link>

              <Link to="/employeestatement_report">
                <div
                  className={`flex items-center transition-all ease-linear duration-100 mr-8 ml-6 group `}
                >
                  <Circle
                    className={`!text-[.7rem] !transition-all !ease-linear !duration-200 text-gray-300`}
                  />
                  <h1
                    className={`pl-9 text-gray-300 transition-all ease-linear text-sm font-semibold duration-200  py-2 cursor-pointer`}
                  >
                    Employee Statement (Reimbursement)
                  </h1>
                </div>
              </Link>
            </div>
          </Collapse>
        </Link>
      ) : // <Link to="/reimbursement_report">
      //   <aside
      //     className={`px-6 py-2 flex gap-4 cursor-pointer ${
      //       highLight === "myExpense" ? "bg-gray-500" : ""
      //     } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
      //   >
      //     <Money
      //       className={`${
      //         highLight === "myExpense"
      //           ? "!text-[#659DBD]"
      //           : "!text-gray-400"
      //       } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
      //     />
      //     <span
      //       className={`${
      //         highLight === "myExpense" ? "text-gray-200" : "text-gray-400"
      //       } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
      //     >
      //       Expense Management
      //     </span>
      //   </aside>
      // </Link>
      null}

      {userType === "editorial" ? (
        <>
          <Link to="/printing/newPrintingReq">
            <aside
              className={`px-6 py-2 flex gap-4 cursor-pointer ${
                highLight === "addPrintRequest" ? "bg-gray-500" : ""
              } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <School
                className={`${
                  highLight === "addPrintRequest"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "addPrintRequest"
                    ? "text-gray-200"
                    : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Add Print Request
              </span>
            </aside>
          </Link>
          <Link to="/printing/checkStatus">
            <aside
              className={`px-6 py-2 flex gap-4 cursor-pointer ${
                highLight === "checkPrintStatus" ? "bg-gray-500" : ""
              } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
            >
              <School
                className={`${
                  highLight === "checkPrintStatus"
                    ? "!text-[#659DBD]"
                    : "!text-gray-400"
                } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
              />
              <span
                className={`${
                  highLight === "checkPrintStatus"
                    ? "text-gray-200"
                    : "text-gray-400"
                } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
              >
                Check Status
              </span>
            </aside>
          </Link>
        </>
      ) : null}
      {userType === "trainer" ? (
        <>
          <Link to="/interventions/trainer/dashboard">
            <aside
              className={`px-6 py-2 hover:bg-gray-500 flex ${
                highLight === "dashboard" ? "bg-gray-500" : ""
              } rounded-md gap-4 cursor-pointer group`}
            >
              <div className="flex gap-4">
                <Dashboard
                  className={`${
                    highLight === "dashboard"
                      ? "!text-[#659DBD]"
                      : "!text-gray-400"
                  } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
                />
                <span
                  className={`${
                    highLight === "dashboard"
                      ? "text-gray-200"
                      : "text-gray-400"
                  } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
                >
                  DashBoard
                </span>
              </div>
              {/* <hr className="text-gray-300" /> */}
            </aside>
          </Link>
          <Link to="/interventions/trainer/insights">
            <aside
              className={`px-6 py-2 hover:bg-gray-500 flex ${
                highLight === "insights" ? "bg-gray-500" : ""
              } rounded-md gap-4 cursor-pointer group`}
            >
              <div className="flex gap-4">
                <Insights
                  className={`${
                    highLight === "insights"
                      ? "!text-[#659DBD]"
                      : "!text-gray-400"
                  } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
                />
                <span
                  className={`${
                    highLight === "insights" ? "text-gray-200" : "text-gray-400"
                  } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
                >
                  Insights
                </span>
              </div>
              {/* <hr className="text-gray-300" /> */}
            </aside>
          </Link>
        </>
      ) : null}
      {userType === "supervisor" ? (
        <>
          <Link to="/interventions/supervisor/dashboard">
            <aside
              className={`px-6 py-2 hover:bg-gray-500 flex ${
                highLight === "dashboard" ? "bg-gray-500" : ""
              } rounded-md gap-4 cursor-pointer group`}
            >
              <div className="flex gap-4">
                <Dashboard
                  className={`${
                    highLight === "dashboard"
                      ? "!text-[#659DBD]"
                      : "!text-gray-400"
                  } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
                />
                <span
                  className={`${
                    highLight === "dashboard"
                      ? "text-gray-200"
                      : "text-gray-400"
                  } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
                >
                  DashBoard
                </span>
              </div>
              {/* <hr className="text-gray-300" /> */}
            </aside>
          </Link>
        </>
      ) : null}
      {userType === "admin" ? <AdminSidebar highLight={highLight} /> : null}
    </Box>
  );

  return (
    <div ref={sidebarRef}>
      <TransitionsModal open={modelOpen} />;
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
});

export default SwipeableTemporaryDrawer;
