import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";
import SearchDropDown from "../../../Components/SearchDropDown";
import SwipeableTemporaryDrawer from "../../../Components/Material/MaterialSidebar";
import instance from "../../../Instance";
import Cookies from "js-cookie";
import BasicButton from "../../../Components/Material/Button";
import { Backdrop, CircularProgress } from "@mui/material";
import ReactGA from "react-ga4";
import { useNavigate, useParams } from "react-router-dom";
import UploadButton from "../../../Components/Material/UploadButton";
import BasicTextFields from "../../../Components/Material/TextField";
import { Delete } from "@mui/icons-material";
import { useFormik } from "formik";
import { ShowError, ShowSuccess } from "../../../util/showError";
import RowRadioButtonsGroup from "../../../Components/Material/RowRadioButtonGroup";
import DatePicker from "../../../Components/Material/Date";
import { handleDate } from "../../../util/handleDate";

const SubmitIntervention = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [highLight, setHighLight] = useState("");
  const [loading, setLoading] = useState(false);
  const sidebarRef = useRef();
  const [trainerData, setTrainerData] = useState({});

  const params = useParams();

  const schoolInfo = { id: params.schoolId, label: params.schoolName };

  const navInfo = {
    title: "Add Intervention",
    details: ["Home", " / Add Intervention"],
  };

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };

    window.addEventListener("resize", handleWidth);
    handleWidth();
    const pagePath = window.location.pathname;

    ReactGA.send({
      hitType: "pageview",
      page: pagePath,
      title: "User Page Count",
    });
    window.scroll(0, 0);
    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  useLayoutEffect(() => {
    const getTrainerData = async () => {
      const TrainerData = await instance({
        url: "interventions/getInterventionTrainer",
        method: "GET",
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
      });
      setTrainerData(TrainerData.data.data);
    };

    getTrainerData();
  }, []);

  return (
    <div className="flex bg-[#111322]">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Sidebar sidebarCollapsed={sidebarCollapsed} highLight={highLight} />

      <div>
        <SwipeableTemporaryDrawer
          ref={sidebarRef}
          sidebarCollapsed={sidebarCollapsed}
          highLight={highLight}
          // show={show}
        />
      </div>

      <div
        className={`flex flex-col w-[100vw] lg:w-[83vw] lg:ml-[18vw] ${
          window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[60vw]"
        } `}
      >
        <Navbar
          handleSidebarCollapsed={handleSidebarCollapsed}
          info={navInfo}
        />
        <div className="min-h-[100vh] pt-[2vh] max-h-full bg-[#141728]">
          <div className=" sm:px-8 px-2 py-3 bg-[#141728]">
            <p className="text-gray-100 sm:text-xl text-base">
              School Name: {schoolInfo.label}
            </p>
            <div className="flex flex-col px-6 mb-8 py-3 mt-6 gap-6 rounded-md bg-slate-600">
              {params.id === "55cfee4c-c16c-4e8e-b268-2d9a3de1f4cc" ||
              params.id === "5b173925-f304-4bc4-b061-504c6b7b00ff" ||
              params.id === "aca31c07-1a1a-4f07-a61b-2344829da12f" ||
              params.id === "62532dc8-780a-47f1-9c4c-b3e87f2ee20e" ? (
                <PhysicalIntervention
                  setLoading={setLoading}
                  interventionId={params.id}
                  schoolInfo={schoolInfo}
                  trainerData={trainerData}
                />
              ) : null}
              {params.id === "4c208a2e-abdc-46bf-842b-8c8b487dad8f" ? (
                <ReadinessCall
                  setLoading={setLoading}
                  interventionId={params.id}
                  schoolInfo={schoolInfo}
                  trainerData={trainerData}
                />
              ) : null}
              {params.id === "9530b0c8-35e6-4ff3-8fb1-f036137e029e" ? (
                <LiveIntervention
                  setLoading={setLoading}
                  interventionId={params.id}
                  schoolInfo={schoolInfo}
                  trainerData={trainerData}
                />
              ) : null}
              {params.id === "bd5a7886-32f8-4066-b2e7-f6b0c0e6adee" ||
              params.id === "118dd7ab-6d3c-4e07-9954-299452fd627f" ? (
                <Webinar
                  setLoading={setLoading}
                  interventionId={params.id}
                  schoolInfo={schoolInfo}
                  trainerData={trainerData}
                />
              ) : null}
              {params.id === "c71ba229-4ca3-49e1-acbd-553730179f54" ||
              params.id === "fbd1db2c-c9e1-4506-9e35-77c9b5eb71b1" ||
              params.id === "42acab8c-b97d-4fe7-9161-ca5a0da8b389" ? (
                <Calls
                  setLoading={setLoading}
                  interventionId={params.id}
                  schoolInfo={schoolInfo}
                  trainerData={trainerData}
                />
              ) : null}
              {params.id === "d0ec9063-b2ff-4b13-a6bd-a73c0de8ff1c" ? (
                <FinalCall
                  setLoading={setLoading}
                  interventionId={params.id}
                  schoolInfo={schoolInfo}
                  trainerData={trainerData}
                />
              ) : null}
              {params.id === "b2ea633a-fcdb-4208-9b17-3df3b154692d" ? (
                <Renew
                  setLoading={setLoading}
                  interventionId={params.id}
                  schoolInfo={schoolInfo}
                  trainerData={trainerData}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PhysicalIntervention = ({
  setLoading,
  interventionId,
  schoolInfo,
  trainerData,
}) => {
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      remarks: "",
      date: handleDate(new Date().toString()),
    },
    validate: (values) => {
      let errors = {};
      if (values.remarks.length < 1) {
        errors.remarks = "Required";
        ShowError("Please Enter Remarks");
      }
      if (values.date === "") {
        errors.date = "Required";
        ShowError("Please Select Date");
      }
      if (images.length !== 2) {
        errors.images = "Required";
        ShowError("Please Upload Images");
      }

      return errors;
    },
    onSubmit: async (values) => {
      let imagesUrls = [];

      for (let i = 0; i < images.length; i++) {
        const item = images[i];
        let formdata = new FormData();
        let file = item;
        if (file.type !== "image/jpeg" && file.type !== "image/png") {
          alert("Please Select an image file only");
          return;
        }
        formdata.append("img", file);
        formdata.append("test", "test");
        setLoading(true);
        const res = await instance({
          url: `imagetoS3/add_image_s3`,
          method: "POST",
          data: formdata,
          headers: {
            Authorization: Cookies.get("accessToken"),
          },
        });
        setLoading(false);
        if (res.status === 200) {
          imagesUrls.push(res.data.replace(/\s/g, ""));
        } else {
          ShowError("Cannot upload image");
        }
      }
      console.log(imagesUrls);
      if (imagesUrls.length === 2) {
        const res = await instance({
          url: `interventions/createIntervention`,
          method: "POST",
          data: {
            schoolCode: schoolInfo.id,
            // schoolName: schoolInfo.label,
            interventionTypeId: interventionId,
            date: values.date,
            trainerId: trainerData.id,

            files: imagesUrls.map((item) => {
              return { url: item };
            }),
            remarks: values.remarks,
          },
          headers: {
            Authorization: Cookies.get("accessToken"),
          },
        });
        if (res.status === 200) {
          ShowSuccess("Intervention Submitted");
          setTimeout(() => {
            navigate("/interventions/trainer/dashboard");
          }, 3000);
        }
      }
    },
  });

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex gap-4">
        <label className="text-gray-100 font-semibold">
          Upload 2 Images of Physical Intervention
        </label>
        <UploadButton
          accept={"image/*"}
          name={"Upload"}
          uploadContent={(file) => {
            if (file.size > 1048576) {
              ShowError("Please Select Image Size Less than 1 MB");
              return;
            }
            if (images.length < 2) {
              setImages([...images, file]);
            }
          }}
        />
      </div>
      {images.map((item, index) => {
        return (
          <p
            className="text-gray-100 font-semibold"
            key={index}
            onClick={() => {
              setImages(images.filter((_, i) => i !== index));
            }}
          >
            {index + 1 + " : "}
            {item.name}
            <Delete className="!text-red-500 !ml-4 !cursor-pointer" />
          </p>
        );
      })}
      <DatePicker
        handleOrderProcessingForm={(date, _) => {
          const modifiedDate = handleDate(date.toString());
          formik.values.date = modifiedDate;
        }}
        label={"Select Date"}
        disableYear={2024}

        // disablePast={true}
      />
      <BasicTextFields
        lable={"Remarks"}
        maxLength={"250"}
        handleOrderProcessingForm={(val) => {
          formik.values.remarks = val;
        }}
      />
      <div onClick={formik.handleSubmit}>
        <BasicButton text={"Submit"} />
      </div>
    </div>
  );
};
const ReadinessCall = ({
  setLoading,
  interventionId,
  schoolInfo,
  trainerData,
}) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      remarks: "",
      isDone: "",
      date: handleDate(new Date().toString()),
    },
    validate: (values) => {
      let errors = {};
      if (values.remarks.length < 1) {
        errors.remarks = "Required";
        ShowError("Please Enter Remarks");
      }
      if (values.date === "") {
        errors.date = "Required";
        ShowError("Please Select Date");
      }
      if (values.isDone === "") {
        errors.isDone = "Required";
        ShowError("Please Select Readiness Call Status");
      }

      return errors;
    },
    onSubmit: async (values) => {
      const res = await instance({
        url: `interventions/createIntervention`,
        method: "POST",
        data: {
          schoolCode: schoolInfo.id,
          // schoolName: schoolInfo.label,
          // seriesId: seriesId,
          interventionTypeId: interventionId,
          trainerId: trainerData.id,

          date: values.date,
          isDone: values.isDone,
          remarks: values.remarks,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      if (res.status === 200) {
        ShowSuccess("Intervention Submitted");
        setTimeout(() => {
          navigate("/interventions/trainer/dashboard");
        }, 3000);
      }
    },
  });

  return (
    <div className="w-full flex flex-col gap-4">
      <RowRadioButtonsGroup
        handleRadioButtons={(_, value) => {
          formik.values.isDone = value === "yes" ? true : false;
        }}
        name={"readinessCall"}
        heading={"Readiness Call"}
        value={[
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ]}
      />

      <DatePicker
        handleOrderProcessingForm={(date, _) => {
          const modifiedDate = handleDate(date.toString());
          formik.values.date = modifiedDate;
        }}
        label={"Select Date"}
        disableYear={2024}
        // disablePast={true}
      />

      <BasicTextFields
        lable={"Remarks"}
        maxLength={"250"}
        handleOrderProcessingForm={(val) => {
          formik.values.remarks = val;
        }}
      />
      <div onClick={formik.handleSubmit}>
        <BasicButton text={"Submit"} />
      </div>
    </div>
  );
};

const LiveIntervention = ({
  setLoading,
  interventionId,
  schoolInfo,
  trainerData,
}) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      remarks: "",
      isDone: "",
      date: handleDate(new Date().toString()),
    },
    validate: (values) => {
      let errors = {};
      if (values.remarks.length < 1) {
        errors.remarks = "Required";
        ShowError("Please Enter Remarks");
      }
      if (values.date === "") {
        errors.date = "Required";
        ShowError("Please Select Date");
      }
      if (values.isDone === "") {
        errors.isDone = "Required";
        ShowError("Please Select Status");
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      const res = await instance({
        url: `interventions/createIntervention`,
        method: "POST",
        data: {
          schoolCode: schoolInfo.id,
          // schoolName: schoolInfo.label,
          // seriesId: seriesId,
          date: values.date,
          trainerId: trainerData.id,

          interventionTypeId: interventionId,
          isDone: values.isDone,
          remarks: values.remarks,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      if (res.status === 200) {
        ShowSuccess("Intervention Submitted");
        setTimeout(() => {
          navigate("/interventions/trainer/dashboard");
        }, 3000);
      }
      setLoading(false);
    },
  });

  return (
    <div className="w-full flex flex-col gap-4">
      <RowRadioButtonsGroup
        handleRadioButtons={(_, value) => {
          formik.values.isDone = value === "yes" ? true : false;
        }}
        name={"readinessCall"}
        heading={"Live"}
        value={[
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ]}
      />
      <DatePicker
        handleOrderProcessingForm={(date, _) => {
          const modifiedDate = handleDate(date.toString());
          formik.values.date = modifiedDate;
        }}
        label={"Select Date"}
        disableYear={2024}

        // disablePast={true}
      />

      <BasicTextFields
        lable={"Remarks"}
        maxLength={"250"}
        handleOrderProcessingForm={(val) => {
          formik.values.remarks = val;
        }}
      />
      <div onClick={formik.handleSubmit}>
        <BasicButton text={"Submit"} />
      </div>
    </div>
  );
};
const Webinar = ({ setLoading, interventionId, schoolInfo, trainerData }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      remarks: "",
      webinar: "",
      date: handleDate(new Date().toString()),
    },
    validate: (values) => {
      let errors = {};
      if (values.remarks.length < 1) {
        errors.remarks = "Required";
        ShowError("Please Enter Remarks");
      }
      if (values.date === "") {
        errors.date = "Required";
        ShowError("Please Select Date");
      }

      if (values.webinar === "") {
        errors.webinar = "Required";
        ShowError("Please Select A Platform");
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      const res = await instance({
        url: `interventions/createIntervention`,
        method: "POST",
        data: {
          schoolCode: schoolInfo.id,
          date: values.date,
          interventionTypeId: interventionId,
          trainerId: trainerData.id,

          webinarMode: values.webinar,
          remarks: values.remarks,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      if (res.status === 200) {
        ShowSuccess("Intervention Submitted");
        setTimeout(() => {
          navigate("/interventions/trainer/dashboard");
        }, 3000);
      }
      setLoading(false);
    },
  });

  return (
    <div className="w-full flex flex-col gap-4">
      <RowRadioButtonsGroup
        handleRadioButtons={(_, value) => {
          formik.values.webinar = value;
        }}
        name={"webinar"}
        heading={"Select Platform"}
        value={[
          { label: "Zoom", value: "zoom" },
          { label: "Google", value: "google" },
          { label: "Microsoft Teams", value: "ms" },
        ]}
      />
      <DatePicker
        handleOrderProcessingForm={(date, _) => {
          const modifiedDate = handleDate(date.toString());
          formik.values.date = modifiedDate;
        }}
        label={"Select Date"}
        disableYear={2024}

        // disablePast={true}
      />
      <BasicTextFields
        lable={"Remarks"}
        maxLength={"250"}
        handleOrderProcessingForm={(val) => {
          formik.values.remarks = val;
        }}
      />
      <div onClick={formik.handleSubmit}>
        <BasicButton text={"Submit"} />
      </div>
    </div>
  );
};

const Calls = ({ setLoading, interventionId, schoolInfo, trainerData }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      remarks: "",
      response: "",
      date: handleDate(new Date().toString()),
    },
    validate: (values) => {
      let errors = {};
      if (values.remarks.length < 1) {
        errors.remarks = "Required";
        ShowError("Please Enter Remarks");
      }
      if (values.date === "") {
        errors.date = "Required";
        ShowError("Please Select Date");
      }

      if (values.response === "") {
        errors.response = "Required";
        ShowError("Please Select Status");
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      const res = await instance({
        url: `interventions/createIntervention`,
        method: "POST",
        data: {
          schoolCode: schoolInfo.id,
          date: values.date,
          interventionTypeId: interventionId,
          trainerId: trainerData.id,

          callResponse: values.response,
          remarks: values.remarks,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      if (res.status === 200) {
        ShowSuccess("Intervention Submitted");
        setTimeout(() => {
          navigate("/interventions/trainer/dashboard");
        }, 3000);
      }
      setLoading(false);
    },
  });

  return (
    <div className="w-full flex flex-col gap-4">
      <RowRadioButtonsGroup
        handleRadioButtons={(_, value) => {
          formik.values.response = value;
        }}
        name={"response"}
        heading={"Select Status"}
        value={[
          { label: "Satisfied", value: "satisfied" },
          { label: "Unsatisfied", value: "unsatisfied" },
        ]}
      />
      <DatePicker
        handleOrderProcessingForm={(date, _) => {
          const modifiedDate = handleDate(date.toString());
          formik.values.date = modifiedDate;
        }}
        label={"Select Date"}
        disableYear={2024}

        // disablePast={true}
      />

      <BasicTextFields
        lable={"Remarks"}
        maxLength={"250"}
        handleOrderProcessingForm={(val) => {
          formik.values.remarks = val;
        }}
      />
      <div onClick={formik.handleSubmit}>
        <BasicButton text={"Submit"} />
      </div>
    </div>
  );
};

const FinalCall = ({ setLoading, interventionId, schoolInfo, trainerData }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      remarks: "",
      response: "",
      date: handleDate(new Date().toString()),
    },
    validate: (values) => {
      let errors = {};
      if (values.remarks.length < 1) {
        errors.remarks = "Required";
        ShowError("Please Enter Remarks");
      }
      if (values.date === "") {
        errors.date = "Required";
        ShowError("Please Select Date");
      }
      if (values.response === "") {
        errors.response = "Required";
        ShowError("Please Select Status");
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      const res = await instance({
        url: `interventions/createIntervention`,
        method: "POST",
        data: {
          schoolCode: schoolInfo.id,
          date: values.date,
          trainerId: trainerData.id,
          interventionTypeId: interventionId,
          finalCallResponse: values.response,
          remarks: values.remarks,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      if (res.status === 200) {
        ShowSuccess("Intervention Submitted");
        setTimeout(() => {
          navigate("/interventions/trainer/dashboard");
        }, 3000);
      }
      setLoading(false);
    },
  });

  return (
    <div className="w-full flex flex-col gap-4">
      <RowRadioButtonsGroup
        handleRadioButtons={(_, value) => {
          formik.values.response = value;
        }}
        name={"response"}
        heading={"Select Status"}
        value={[
          { label: "Win", value: "win" },
          { label: "Lose", value: "lose" },
          { label: "Hold", value: "hold" },
        ]}
      />
      <DatePicker
        handleOrderProcessingForm={(date, _) => {
          const modifiedDate = handleDate(date.toString());
          formik.values.date = modifiedDate;
        }}
        label={"Select Date"}
        disableYear={2024}

        // disablePast={true}
      />

      <BasicTextFields
        lable={"Remarks"}
        maxLength={"250"}
        handleOrderProcessingForm={(val) => {
          formik.values.remarks = val;
        }}
      />
      <div onClick={formik.handleSubmit}>
        <BasicButton text={"Submit"} />
      </div>
    </div>
  );
};

const Renew = ({ setLoading, interventionId, schoolInfo, trainerData }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      remarks: "",
      isDone: "",
      date: handleDate(new Date().toString()),
    },
    validate: (values) => {
      let errors = {};
      if (values.remarks.length < 1) {
        errors.remarks = "Required";
        ShowError("Please Enter Remarks");
      }
      if (values.date === "") {
        errors.date = "Required";
        ShowError("Please Select Date");
      }
      if (values.isDone === "") {
        errors.isDone = "Required";
        ShowError("Please Select Status");
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      const res = await instance({
        url: `interventions/createIntervention`,
        method: "POST",
        data: {
          schoolCode: schoolInfo.id,
          date: values.date,
          trainerId: trainerData.id,

          interventionTypeId: interventionId,
          isDone: values.isDone,
          remarks: values.remarks,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      if (res.status === 200) {
        ShowSuccess("Intervention Submitted");
        setTimeout(() => {
          navigate("/interventions/trainer/dashboard");
        }, 3000);
      }
      setLoading(false);
    },
  });

  return (
    <div className="w-full flex flex-col gap-4">
      <RowRadioButtonsGroup
        handleRadioButtons={(_, value) => {
          formik.values.isDone = value === "yes" ? true : false;
        }}
        name={"readinessCall"}
        heading={"Renew"}
        value={[
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ]}
      />
      <DatePicker
        handleOrderProcessingForm={(date, _) => {
          const modifiedDate = handleDate(date.toString());
          formik.values.date = modifiedDate;
        }}
        label={"Select Date"}
        disableYear={2024}

        // disablePast={true}
      />

      <BasicTextFields
        lable={"Remarks"}
        maxLength={"250"}
        handleOrderProcessingForm={(val) => {
          formik.values.remarks = val;
        }}
      />
      <div onClick={formik.handleSubmit}>
        <BasicButton text={"Submit"} />
      </div>
    </div>
  );
};

export default SubmitIntervention;
