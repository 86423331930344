import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BasicTextFields from "../Material/TextField";
import { ShowError, ShowSuccess } from "../../util/showError";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import instance from "../../Instance";
import { Slider } from "@mui/material";

const SalesRemarkDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const { setLoading } = props;

  const formik = useFormik({
    initialValues: {
      remarks: "",
      interventionId: null,
      ratings: 0,
    },
    validate: (values) => {
      let errors = {};
      if (values.remarks.length < 1) {
        errors.remarks = "Required";
        ShowError("Please Enter Remarks");
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      const salesRemarks = await instance({
        url: `interventions/addSalesRemarks/${formik.values.interventionId}`,
        method: "POST",
        data: {
          remarks: values.remarks,
          ratings: values.ratings,
        },
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
      });
      setLoading(false);
      setOpen(false);
      ShowSuccess(salesRemarks.data.message);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  React.useImperativeHandle(ref, () => ({
    openDialog(id) {
      formik.values.interventionId = id;
      setOpen(true);
    },
  }));

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Enter Remark"}</DialogTitle>
        <DialogContent>
          <BasicTextFields
            inputColor={"black"}
            handleOrderProcessingForm={(value) => {
              formik.values.remarks = value;
            }}
          />
          <DialogTitle id="alert-dialog-title">{"Enter Ratings"}</DialogTitle>

          <Slider
            aria-label="Temperature"
            defaultValue={0}
            onChange={(e) => {
              formik.values.ratings = e.target.value;
            }}
            valueLabelDisplay="auto"
            shiftStep={1}
            step={1}
            marks
            min={0}
            max={5}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={formik.handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default SalesRemarkDialog;
