import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Loader from "../../Components/Loader";
import SwipeableTemporaryDrawer from "../../Components/Material/MaterialSidebar";
import documentImg from "../../assets/img/documents.png";
import { Link, useNavigate } from "react-router-dom";
import BasicButton from "../../Components/Material/Button";
import instance from "../../Instance";
import Cookies from "js-cookie";
import SearchDropDown from "../../Components/SearchDropDown";
import BasicTextFields from "../../Components/Material/TextField";
import { ShowError, ShowSuccess } from "../../util/showError";

const UserChangePassword = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const sidebarRef = useRef();
  const show = null;
  const [allUser, setAllUser] = useState([]);
  const [user, setUser] = useState(null);
  const newPassword = useRef("");

  const navInfo = {
    title: "Admin Change Password",
    details: ["Home", "Admin Change Password"],
  };

  const handleSidebarCollapsed = () => {
    // setSidebarCollapsed(!sidebarCollapsed);
    sidebarRef.current.openSidebar();
  };

  const getUsers = async () => {
    const res = await instance({
      url: "user/getAllusers",
      method: "GET",
      headers: {
        Authorization: `${Cookies.get("accessToken")}`,
      },
    });
    setAllUser(res.data.message);
  };

  const changePassword = async () => {
    try {
      setLoading(true);
      const res = await instance({
        url: "auth/resetPasswordByAdmin",
        method: "POST",
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
        data: {
          userId: user.id,
        },
      });
      if (res.data.status === "success") {
        ShowSuccess(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      ShowError("Something went wrong, Please try again");
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };
    window.addEventListener("resize", handleWidth);
    handleWidth();
    getUsers();

    window.scroll(0, 0);

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);
  return (
    <>
      <div className="flex w-[100%] min-h-[100vh]">
        {loading ? <Loader /> : null}

        <Sidebar
          highLight={"user_management"}
          sidebarCollapsed={sidebarCollapsed}
          show={show}
        />

        <div>
          <SwipeableTemporaryDrawer
            ref={sidebarRef}
            sidebarCollapsed={sidebarCollapsed}
            show={show}
            highLight={"user_management"}
          />
        </div>
        <div
          className={`flex flex-col w-[100vw] relative transition-all ease-linear duration-300 lg:w-[83vw] lg:ml-[18vw] ${
            window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[85vw]"
          } `}
        >
          <Navbar
            handleSidebarCollapsed={handleSidebarCollapsed}
            info={navInfo}
          />

          {showMap ? (
            <div className="h-[90vh] bg-gray-300">
              {/* <GoogleMap
                sidebarCollapsed={sidebarCollapsed}
                currentLocation={currentLocation}
              /> */}
            </div>
          ) : (
            <div className="min-h-[90vh] relative flex w-full justify-center items-start gap-4 bg-[#141728]">
              <div className="grid grid-cols-2 grid-rows-2 md:grid-cols-2 md:grid-rows-1 w-full justify-items-center items-center px-6 mb-8 py-3 mt-6 gap-12 rounded-md bg-slate-600">
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-gray-100">Select User</label>

                  <SearchDropDown
                    label={"Select User"}
                    handleOrderProcessingForm={(value, type) => {
                      setUser(value);
                    }}
                    color={"rgb(243, 244, 246)"}
                    data={allUser}
                    Name="get_all_user"
                  />
                </div>

                <div
                  onClick={() => {
                    if (user !== null) {
                      changePassword();
                    }
                  }}
                  className="h-fit"
                >
                  <BasicButton
                    text={"Submit"}
                    disable={user === null ? true : false}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserChangePassword;
