import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";
import SwipeableTemporaryDrawer from "../../../Components/Material/MaterialSidebar";
import instance from "../../../Instance";
import { useLayoutEffect } from "react";
import Cookies from "js-cookie";
import { Backdrop, CircularProgress } from "@mui/material";
import ReactGA from "react-ga4";
import axios from "axios";
import CollapsibleTable from "../../../Components/TrainerInterventions/CollapsableTable";
import ckInstance from "../../../ckInstance";

const TrainerDashboard = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [highLight, setHighLight] = useState("dashboard");
  const [loading, setLoading] = useState(false);
  const [schools, setSchool] = useState([]);
  const sidebarRef = useRef();
  const navInfo = {
    title: "Dashboard",
    details: ["Home", " / Dashboard"],
  };

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };

    window.addEventListener("resize", handleWidth);
    handleWidth();
    const pagePath = window.location.pathname;

    ReactGA.send({
      hitType: "pageview",
      page: pagePath,
      title: "User Page Count",
    });
    window.scroll(0, 0);
    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  const returnCkAuthToken = async () => {
    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("client_id", "c2Nob29sX21pdHJh");
    params.append("secret", "Y2xhc3NrbGFwQDIwMjM=");
    params.append("email", "prateek.mudliar@classklap.com");

    const res = await ckInstance({
      url: `v1/login/eupheus`,
      method: "POST",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return res.data.accessToken;
  };

  useLayoutEffect(() => {
    const getCkSchools = async () => {
      const token = await returnCkAuthToken();
      const TrainerData = await instance({
        url: "interventions/getInterventionTrainer",
        method: "GET",
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
      });
      const getSchool = await ckInstance({
        url: `visor/schoolsDetails/?email=${TrainerData?.data?.data?.ck_email}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const schoolCodes = getSchool.data.map((school) => {
        return school.code;
      });

      const salesData = await instance({
        url: `school/ckschools/getUsersByCkCodeList`,
        method: "POST",
        data: {
          ckCodes: schoolCodes,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      const schoolListData = getSchool.data.map((school) => {
        return {
          id: school.code,
          label: school.name,
          state: school.state,
          sales: salesData.data.message[school.code],
        };
      });
      setSchool(schoolListData);
    };
    
    getCkSchools();
  }, []);


  const columns = ["School Name","Code", "State", "Sales Person"];

  return (
    <div className="flex bg-[#111322]">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Sidebar sidebarCollapsed={sidebarCollapsed} highLight={highLight} />

      <div>
        <SwipeableTemporaryDrawer
          ref={sidebarRef}
          sidebarCollapsed={sidebarCollapsed}
          highLight={highLight}
          // show={show}
        />
      </div>

      <div
        className={`flex flex-col w-[100vw] lg:w-[83vw] lg:ml-[18vw] ${
          window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[60vw]"
        } `}
      >
        <Navbar
          handleSidebarCollapsed={handleSidebarCollapsed}
          info={navInfo}
        />
        <div className="min-h-[100vh] pt-[2vh] max-h-full bg-[#141728]">
          <div className=" sm:px-8 px-2 py-3 bg-[#141728]">
            <CollapsibleTable
              columns={columns}
              data={schools}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerDashboard;
