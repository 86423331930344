import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Loader from "../../Components/Loader";
import SwipeableTemporaryDrawer from "../../Components/Material/MaterialSidebar";
import { Link, useNavigate } from "react-router-dom";
import BasicButton from "../../Components/Material/Button";
import instance from "../../Instance";
import Cookies from "js-cookie";
import DataTable from "../../Components/DataTable";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";

const UpdateSchoolReq = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const sidebarRef = useRef();
  const [statusFilter, setStatusFilter] = useState("Pending");
  const show = null;

  const navInfo = {
    title: "Update School",
    details: ["Home", "Update School"],
  };

  const returnFilteredList = () => {
    if (schoolList.length > 0) {
      const list = schoolList.filter((item) => {
        if (statusFilter === "All") {
          return true;
        }
        if (statusFilter === "Pending") {
          return item.approval_status === "pending";
        }
        if (statusFilter === "Approved") {
          return item.approval_status === "approved";
        }
        if (statusFilter === "Rejected") {
          return item.approval_status === "rejected";
        }
      });
      return list;
    } else {
      return [];
    }
  };

  const Tablecolumns = [
    {
      field: "school_name",
      headerName: "School Name",
      width: 300,
    },
    {
      field: "approval_status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "rep_name",
      headerName: "Updated By",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Requested Date",
      width: 200,
    },
    {
      field: "zsm",
      headerName: "ZSM",
      width: 200,
    },
  ];

  const handleSidebarCollapsed = () => {
    // setSidebarCollapsed(!sidebarCollapsed);
    sidebarRef.current.openSidebar();
  };
  const getSchoolList = async () => {
    try {
      const res = await instance({
        method: "GET",
        url: "school/getSchoolsListForApproval",
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
      });

      const data = res.data.message.map((item) => {
        return {
          ...item,
          rep_name:
            item.updated_by_user_master.first_name +
            " " +
            item.updated_by_user_master.last_name,
          zsm: item?.zsm?.first_name + " " + item?.zsm?.last_name,
          createdAt: item?.createdAt?.split("T")[0],
        };
      });
      setSchoolList(data);
    } catch (error) {}
  };

  useLayoutEffect(() => {
    getSchoolList();
  }, []);

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };
    window.addEventListener("resize", handleWidth);
    handleWidth();

    window.scroll(0, 0);

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);
  return (
    <>
      <div className="flex w-[100%] min-h-[100vh]">
        {loading ? <Loader /> : null}

        <Sidebar
          highLight={"manage_school"}
          sidebarCollapsed={sidebarCollapsed}
          show={show}
        />

        <div>
          <SwipeableTemporaryDrawer
            ref={sidebarRef}
            sidebarCollapsed={sidebarCollapsed}
            show={show}
            highLight={"manage_school"}
          />
        </div>
        <div
          className={`flex flex-col w-[100vw] relative transition-all ease-linear duration-300 lg:w-[83vw] lg:ml-[18vw] ${
            window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[85vw]"
          } `}
        >
          <Navbar
            handleSidebarCollapsed={handleSidebarCollapsed}
            info={navInfo}
          />

          <div className="w-full relative">
            <div className="flex items-center justify-center gap-3 absolute top-[3.2rem] left-[3.5%] z-50">
              <div className="flex flex-col md:flex-row gap-0 md:gap-2">
                <span>Status</span>
              </div>
              <select
                className="px-4 w-[6rem] lg:w-40 focus:outline-0 transition-all duration-500 ease-linear py-1 rounded-lg "
                onClick={(e) => {
                  setStatusFilter(e.target.value);
                }}
              >
                <option value={"Pending"}>Pending</option>
                <option value={"Approved"}>Approved</option>
                <option value={"Rejected"}>Rejected</option>
                <option value={"All"}>All</option>
              </select>
            </div>

            <DataTable
              rows={returnFilteredList()}
              checkbox={false}
              Tablecolumns={Tablecolumns}
              tableName="Admin_Update_Schools"
            />
          </div>
        </div>
      </div>
    </>
  );
};

// const SelectStatus = ({ value }) => {
//   return (
//     <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
//       <InputLabel
//         id="demo-select-small-label"
//         sx={{ color: "black" }}
//         className="!text-black"
//       >
//         Status
//       </InputLabel>
//       <Select
//         value={value}
//         className="!bg-gray-300"
//         label="Age"
//         sx={{ color: "black" }}
//         Change={handleChange}
//       >
//         <MenuItem value={10}>Pending</MenuItem>
//         <MenuItem value={20}>Approved</MenuItem>
//         <MenuItem value={30}>Rejected</MenuItem>
//         <MenuItem value={30}>All</MenuItem>
//       </Select>
//     </FormControl>
//   );
// };

export default UpdateSchoolReq;
