import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import instance from "../../Instance";
import { useState } from "react";
import Cookies from "js-cookie";
import { TextField } from "@mui/material";
import { useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSlide2 = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [cardNum, setCardNum] = useState("");
  const [DocDate, setDocDate] = useState("");
  const [DocTotal, setDocTotal] = useState("");
  const [BillFrom, setBillFrom] = useState("");
  const [BillTo, setBillTo] = useState("");
  const [DispatchFrom, setDispatchFrom] = useState("");
  const [ShipsTo, setShipsTo] = useState("");

  const getInvoiceDetails = async () => {
    let url = `ckInvoice/getckinvoices/${props.invoiceId}`;
    if (Cookies.get("type") === "sales_coordinator") {
      url = `eup_invoice/geteupinvoices/detail/${props.invoiceId}`;
    }
    const res = await instance({
      url,
      method: "GET",
      headers: {
        Authorization: Cookies.get("accessToken"),
      },
    });

    if (res.data.status === "success") {
      setCardNum(res.data.message[0].docnum);
      setDocDate(res.data.message[0].docdate);
      setDocTotal(res.data.message[0].doctotal);
      if (Cookies.get("type") === "sales_coordinator") {
        setBillFrom(res.data.message[0].eup_invoice_addresses[0].BillFromName);
        setBillTo(res.data.message[0].eup_invoice_addresses[0].BillToName);
        setDispatchFrom(
          res.data.message[0].eup_invoice_addresses[0].DispatchFromAddress1
        );

        setShipsTo(res.data.message[0].eup_invoice_addresses[0].ShipToAddress1);

        setInvoiceItems(res.data.message[0].eup_invoice_items);
      } else {
        setBillFrom(res.data.message[0].ck_invoice_addresses[0].BillFromName);
        setBillTo(res.data.message[0].ck_invoice_addresses[0].BillToName);
        setDispatchFrom(
          res.data.message[0].ck_invoice_addresses[0].DispatchFromAddress1
        );

        setShipsTo(res.data.message[0].ck_invoice_addresses[0].ShipToAddress1);

        setInvoiceItems(res.data.message[0].ck_invoice_items);
      }
      setOpen(true);
    }
  };

  const snackbarRef = useRef();

  React.useImperativeHandle(ref, () => ({
    openDialog() {
      getInvoiceDetails();
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="!w-[100vw]"
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle className="!bg-gray-500 text-white"></DialogTitle>
        <DialogContent className="!bg-gray-500">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="!text-gray-600 !sm:text-base !text-sm grid sm:grid-cols-1 grid-cols-1 sm:gap-8 gap-6"
          >
            <div className="flex justify-end">
              <IconButton onClick={handleClose}>
                <CloseIcon className="!text-gray-100" />
              </IconButton>
            </div>

            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid xs={2} sm={4} md={4}>
                  <TextField
                    label="Doc Num"
                    variant="standard"
                    type={"text"}
                    disabled={false}
                    defaultValue={cardNum}
                    value={cardNum}
                    InputLabelProps={{ style: { color: "white" } }}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid xs={2} sm={4} md={4}>
                  <TextField
                    label="Doc Date"
                    variant="standard"
                    type={"text"}
                    disabled={false}
                    defaultValue={DocDate}
                    value={DocDate}
                    InputLabelProps={{ style: { color: "white" } }}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid xs={2} sm={4} md={4}>
                  <TextField
                    label="Doc Total"
                    variant="standard"
                    type={"text"}
                    disabled={false}
                    defaultValue={DocTotal}
                    value={DocTotal}
                    InputLabelProps={{ style: { color: "white" } }}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>

          <div className="mt-9">
            <DialogTitle className="!bg-gray-500 text-white">
              Address
            </DialogTitle>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="!text-gray-600 !sm:text-base !text-sm grid sm:grid-cols-1 grid-cols-1 sm:gap-8 gap-6"
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid xs={2} sm={6} md={6}>
                    <TextField
                      className="!w-full"
                      label="Bill From"
                      variant="standard"
                      type={"text"}
                      disabled={false}
                      defaultValue={BillFrom}
                      value={BillFrom}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid xs={2} sm={6} md={6}>
                    <TextField
                      className="!w-full"
                      label="Bill To"
                      variant="standard"
                      type={"text"}
                      disabled={false}
                      defaultValue={BillTo}
                      value={BillTo}
                      InputLabelProps={{ style: { color: "white" } }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                <div className="mt-4">
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <TextField
                        className="!w-full "
                        label="Dispatch From"
                        variant="standard"
                        type={"text"}
                        disabled={false}
                        defaultValue={DispatchFrom}
                        value={DispatchFrom}
                        InputLabelProps={{ style: { color: "white" } }}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        className="!w-full"
                        label="Ships To"
                        variant="standard"
                        type={"text"}
                        disabled={false}
                        defaultValue={ShipsTo}
                        value={ShipsTo}
                        InputLabelProps={{ style: { color: "white" } }}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </DialogContentText>
          </div>

          <div className="mt-9">
            <DialogTitle className="!bg-gray-500 text-white">Items</DialogTitle>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="!text-gray-600 !sm:text-base !text-sm grid sm:grid-cols-1 grid-cols-1 sm:gap-8 gap-6"
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                  <TableHead className="bg-slate-500">
                    <TableRow>
                      <TableCell className="!w-[18rem]" align="center">
                        Item
                      </TableCell>
                      <TableCell className="!w-[8rem]" align="center">
                        Price
                      </TableCell>
                      <TableCell className="!w-[8rem]" align="center">
                        Quantity
                      </TableCell>
                      <TableCell className="!w-[8rem]" align="center">
                        Discount %
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="bg-slate-200">
                    {invoiceItems.map((row) => (
                      <TableRow>
                        <TableCell align="center">
                          {row.itemdescription}
                        </TableCell>
                        <TableCell align="center">{row.price}</TableCell>
                        <TableCell align="center">{row.quantity}</TableCell>
                        <TableCell align="center">
                          {row.discountpercent}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default DialogSlide2;
