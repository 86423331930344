import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Edit } from "@mui/icons-material";
import ToolTip from "../TrainerInterventions/ToolTip";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#ffffff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#ffffff",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#ffffff",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#22C14F",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

// const steps = ["Punch In", "Punch Out"];

export default function CustomizedSteppers({
  steps,
  activeStep,
  stepColor,
  name,
  handleClick,
  interventionData,
}) {
  const returnTrainerRemarks = (intId) => {
    let remarks = null;
    for (let i = 0; i < interventionData.length; i++) {
      const item = interventionData[i];
      if (item.fk_intervention_types_id === intId) {
        remarks = "Trainer's Remark :- " + item.remarks;
        break;
      }
    }
    return remarks;
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {name === undefined
          ? steps.map((label, index) => (
              <Step key={index}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <p
                    className={`${
                      stepColor && index < activeStep
                        ? `text-green-600`
                        : "text-gray-100"
                    } font-medium`}
                  >
                    {label}
                  </p>
                </StepLabel>
              </Step>
            ))
          : null}
        {name === "Interventions"
          ? steps.map((label, index) =>
              label.isValid ? (
                <Step key={index}>
                  <StepLabel
                    StepIconComponent={() => {
                      return (
                        <Edit
                          className="!text-gray-200 !cursor-pointer"
                          onClick={() => {
                            handleClick(label.id);
                          }}
                        />
                      );
                    }}
                  >
                    <p
                      className={`${
                        stepColor && index < activeStep
                          ? `text-[${stepColor}]`
                          : "text-gray-100"
                      }`}
                    >
                      {label.type}
                    </p>
                  </StepLabel>
                </Step>
              ) : (
                <Step key={index}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    <p
                      className={`${
                        stepColor && index < activeStep
                          ? `text-green-600`
                          : "text-gray-100"
                      } font-medium`}
                    >
                      {label.type}
                    </p>
                  </StepLabel>
                </Step>
              )
            )
          : null}
        {name === "SalesInterVentions"
          ? steps.map((label, index) =>
              label.type.includes("Quarterly Call") &&
              activeStep >= index + 1 ? (
                <Step key={index}>
                  <ToolTip title={returnTrainerRemarks(label.id)}>
                    <StepLabel
                      StepIconComponent={() => {
                        return (
                          <Edit
                            className="!text-gray-200 !cursor-pointer"
                            onClick={() => {
                              handleClick(label.interventionId);
                            }}
                          />
                        );
                      }}
                    >
                      <p
                        className={`${
                          stepColor && index < activeStep
                            ? `text-green-600`
                            : "text-gray-100"
                        }`}
                      >
                        {label.type}
                      </p>
                    </StepLabel>
                  </ToolTip>
                </Step>
              ) : (
                <Step key={index}>
                  <ToolTip title={returnTrainerRemarks(label.id)}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      <p
                        className={`${
                          stepColor && index < activeStep
                            ? `text-green-600`
                            : "text-gray-100"
                        } font-medium`}
                      >
                        {label.type}
                      </p>
                    </StepLabel>
                  </ToolTip>
                </Step>
              )
            )
          : null}
      </Stepper>
    </Stack>
  );
}
