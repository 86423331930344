import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import * as XLSX from "xlsx";

import Cookies from "js-cookie";
import SwipeableTemporaryDrawer from "../Components/Material/MaterialSidebar";
import BasicButton from "../Components/Material/Button";

import {
  Backdrop,
  CircularProgress,
  Dialog,
  Fab,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import instance from "../Instance";
import Snackbars from "../Components/Material/SnackBar";
import DialogSlide2 from "../Components/Material/Dialog3";
import SearchDropDown from "../Components/SearchDropDown";
import { Add } from "@mui/icons-material";
import BasicTextFields from "../Components/Material/TextField";
import { useFormik } from "formik";
import { ShowError, ShowSuccess } from "../util/showError";
import UploadButton from "../Components/Material/UploadButton";
// import Bottleneck from "bottleneck";
import PercentageLoader from "../Components/PercentageLoader";

const TagSchoolAgainstInv = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [snackbarErrStatus, setSnackbarErrStatus] = useState(true);
  const [tableData, setTableData] = useState([]);
  const sidebarRef = useRef();
  const snackbarRef = useRef();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchRow, setSearchRow] = useState([]);
  const [percentLoading, setPercentLoading] = useState(false);
  const [prog, setProg] = useState(0);

  // const limiter = new Bottleneck({
  //   maxConcurrent: 5, // Maximum number of concurrent requests
  //   // minTime: 2000, // Minimum time between each request in milliseconds
  // });

  const show = null;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const userType = Cookies.get("type");

  const navInfo = {
    title: "Tag School Against Invoice",
    details: ["Home", "/Tag School Against Invoice"],
  };

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };

  const getCkInvoices = async () => {
    setLoading(true);
    const ckInvs = await instance({
      url: `eup_invoice/getEupInvoices`,
      method: "GET",
      headers: {
        Authorization: `${Cookies.get("accessToken")}`,
      },
    });
    setLoading(false);

    setTableData(ckInvs.data.message);
  };
  useLayoutEffect(() => {
    getCkInvoices();
  }, []);

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };
    window.addEventListener("resize", handleWidth);
    handleWidth();
    window.scroll(0, 0);

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  const dialogRef = useRef();
  const addSchoolRef = useRef();
  const invId = useRef(null);

  const handleInvoiceView = (invceId) => {
    setLoading(true);
    invId.current = invceId;
    setTimeout(() => {
      dialogRef.current.openDialog();
      setLoading(false);
    }, 1000);
  };

  const handleAddSchool = (invceId) => {
    setLoading(true);
    invId.current = invceId;
    setTimeout(() => {
      addSchoolRef.current.open();
      setLoading(false);
    }, 1000);
  };

  const handleSearch = (val) => {
    const newArr = tableData.filter((item) => {
      return (
        item.docnum
          .toString()
          .toLowerCase()
          .indexOf(val.toString().toLowerCase()) > -1
      );
    });
    setSearchRow(newArr);
  };
  function formatDateFromExcel(excelDate) {
    // Convert Excel date to JavaScript date
    const javascriptDate = new Date((excelDate - 25569) * 86400 * 1000);

    // Get day, month, and year
    const day = javascriptDate.getDate().toString().padStart(2, "0");
    const month = (javascriptDate.getMonth() + 1).toString().padStart(2, "0");
    const year = javascriptDate.getFullYear();

    // Format as "dd-mm-year"
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  const UploadExcel = (data) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, {
        type: "array",
      });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert sheet data to JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const columns = jsonData[0];
      const uploadData = jsonData.splice(1).map((item) => {
        const date = item[columns.indexOf("Invoice Date")];

        return {
          invNo: item[columns.indexOf("Invoice Number")],
          invDate: formatDateFromExcel(date),
          CusCode: item[columns.indexOf("Customer Code")],
          itemCode: item[columns.indexOf("Item Code")],
          itemName: item[columns.indexOf("Item Name")],
          crmId: item[columns.indexOf("CRM ID")],
          qty: item[columns.indexOf("Quantity")],
          unitPrice: item[columns.indexOf("Unit Price")],
          discount: item[columns.indexOf("Disc %")],
          total: item[columns.indexOf("TOTAL")],
        };
      });
      const itemGroupByItemId = Object.groupBy(uploadData, (product) => {
        return product.invNo;
      });

      const groupedByInvNo = Object.values(itemGroupByItemId);
      console.log(groupedByInvNo);
      const postTagging = async (invoiceNumber, invoiceDate, items) => {
        await instance({
          url: `eup_invoice/createTaggingExcel`,
          method: "POST",
          headers: {
            Authorization: Cookies.get("accessToken"),
          },
          data: {
            invoiceNumber,
            invoiceDate,
            items,
          },
        }).catch((err) => {
          console.log("err", err);
        });
      };
      async function processArraySequentially(array) {
        let completeReq = 0;
        for (const arr of array) {
          const invoiceNumber = arr[0].invNo;
          const invoiceDate = arr[0].invDate;
          const items = arr.map((item) => {
            return {
              schoolId: item.crmId,
              itemCode: item.itemCode,
              quantity: item.qty,
              price: item.unitPrice,
              discountPercent: Number(item.discount),
              total: item.total,
            };
          });
          await postTagging(invoiceNumber, invoiceDate, items);
          completeReq++;
          setProg((completeReq / array.length) * 100);
        }
      }
      setPercentLoading(true);
      processArraySequentially(groupedByInvNo).then(() => {
        setPercentLoading(false);
        setProg(0);
        ShowSuccess("School Invoice Tagged Successfully");
      });
      // let completeReq = 0;
      // const sendReq = async (arr) => {
      //   const invoiceNumber = arr[0].invNo;
      //   const invoiceDate = arr[0].invDate;
      //   const items = arr.map((item) => {
      //     return {
      //       schoolId: item.crmId,
      //       itemCode: item.itemCode,
      //       quantity: item.qty,
      //       price: item.unitPrice,
      //       discountPercent: Number(item.discount),
      //     };
      //   });
      //   await postTagging(invoiceNumber, invoiceDate, items);
      //   setProg((completeReq++ / groupedByInvNo.length) * 100);
      // };
      // const promise = groupedByInvNo.map((item) =>
      //   limiter.schedule(() => sendReq(item))
      // );
      // setPercentLoading(true);
      // await Promise.all(promise);
      // setProg(0);
      // setPercentLoading(false);
    };

    reader.readAsArrayBuffer(data);
  };

  return (
    <>
      <div className="flex w-[100%] min-h-[100vh]">
        <DialogSlide2 ref={dialogRef} invoiceId={invId.current} />
        <AddSchoolDialog
          ref={addSchoolRef}
          getCkInvoices={getCkInvoices}
          invoiceId={invId.current}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <PercentageLoader loading={percentLoading} prog={prog} />

        <Sidebar
          highLight={"tag_school"}
          sidebarCollapsed={sidebarCollapsed}
          show={show}
        />

        <div>
          <SwipeableTemporaryDrawer
            ref={sidebarRef}
            sidebarCollapsed={sidebarCollapsed}
            show={show}
            highLight={"tag_school"}
          />
        </div>
        <div
          className={`flex flex-col w-[100vw] relative transition-all ease-linear duration-300 lg:w-[83vw] lg:ml-[18vw] ${
            window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[85vw]"
          } `}
        >
          <Snackbars
            ref={snackbarRef}
            snackbarErrStatus={snackbarErrStatus}
            errMessage={errMessage}
          />
          <Navbar
            handleSidebarCollapsed={handleSidebarCollapsed}
            info={navInfo}
          />

          <div className="min-h-[90vh] relative flex w-full justify-center items-start gap-4 bg-[#141728]">
            <div className="w-full px-8 mb-5">
              <div className="w-full flex justify-end m-2 pr-2">
                <UploadButton
                  name={"Import Excel"}
                  uploadContent={UploadExcel}
                  accept={".xlsx, .xls, .csv"}
                />
              </div>
              <Paper>
                <TableContainer component={Paper}>
                  <Toolbar className="bg-slate-400">
                    <TextField
                      id="search-bar"
                      className="text"
                      onInput={(e) => {
                        handleSearch(e.target.value);
                      }}
                      label="Enter Invoice No"
                      variant="outlined"
                      placeholder="Search..."
                      size="small"
                    />

                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={
                        searchRow.length === 0
                          ? tableData.length
                          : searchRow.length
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                        select: {
                          "aria-label": "rows per page",
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Toolbar>

                  <Table sx={{ minWidth: 650 }} aria-label="customized table">
                    <TableHead className="bg-slate-500">
                      <TableRow>
                        <TableCell className="!w-[8rem]" align="center">
                          Invoice No
                        </TableCell>
                        <TableCell className="!w-[8rem]" align="center">
                          Doc Date
                        </TableCell>
                        <TableCell className="!w-[13rem]" align="center">
                          Customer Name
                        </TableCell>
                        <TableCell className="!w-[8rem]" align="center">
                          Doc Total
                        </TableCell>
                        <TableCell className="!w-[8rem]" align="center">
                          Add School
                        </TableCell>
                        <TableCell className="!w-[8rem]" align="center">
                          View Invoice
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-slate-200">
                      {searchRow.length === 0
                        ? (rowsPerPage > 0
                            ? tableData.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : tableData
                          ).map((row) =>
                            row.isTagged ? null : (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  {row.inv_no == "N/A" ||
                                  !row.inv_no ||
                                  row.inv_no.length == 0
                                    ? row.docnum
                                    : row.inv_no}
                                </TableCell>
                                <TableCell align="center">
                                  {row.docdate}
                                </TableCell>
                                <TableCell align="center">
                                  {row.cardname}
                                </TableCell>
                                <TableCell align="center">
                                  {row.doctotal}
                                </TableCell>

                                <TableCell align="center">
                                  <div
                                    className="sm:w-auto "
                                    onClick={() => {
                                      handleAddSchool(row.id);
                                    }}
                                  >
                                    <BasicButton
                                      size={"small"}
                                      text={"Add School"}
                                    />
                                  </div>
                                </TableCell>

                                <TableCell align="center">
                                  <div
                                    className="sm:w-auto"
                                    onClick={() => {
                                      handleInvoiceView(row.id);
                                    }}
                                  >
                                    <BasicButton size={"small"} text={"View"} />
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        : (rowsPerPage > 0
                            ? searchRow.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : searchRow
                          ).map((row) =>
                            row.isTagged ? null : (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  {row.docnum}
                                </TableCell>
                                <TableCell align="center">
                                  {row.docdate}
                                </TableCell>
                                <TableCell align="center">
                                  {row.cardname}
                                </TableCell>
                                <TableCell align="center">
                                  {row.doctotal}
                                </TableCell>

                                <TableCell align="center">
                                  {row.id ? (
                                    <div
                                      className="sm:w-auto w-[50vw]"
                                      onClick={() => {
                                        handleAddSchool(row.id);
                                      }}
                                    >
                                      <BasicButton text={"Add School"} />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {row.id ? (
                                    <div
                                      className="sm:w-auto w-[50vw]"
                                      onClick={() => {
                                        handleInvoiceView(row.id);
                                      }}
                                    >
                                      <BasicButton text={"View"} />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      <TableRow></TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddSchoolDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [school, setSchool] = React.useState([
    { id: 1, schoolName: "", schoolId: "" },
  ]);
  const [allSchools, setAllSchools] = React.useState([]);
  const [step, setStep] = React.useState(1);
  const [invDetail, setInvDetail] = React.useState({});

  const formik = useFormik({
    initialValues: {
      items: [],
    },
    validate: (values) => {
      const errors = {};

      if (
        school.length * invDetail.eup_invoice_items.length !==
        values.items.length
      ) {
        errors.items = "Please add all items";
        ShowError("Please Enter Quantity For All Items");
      } else {
        const itemGroupByItemId = Object.groupBy(values.items, (product) => {
          return product.itemId;
        });
        Object.values(itemGroupByItemId).map((itemArr) => {
          const InvItem = invDetail.eup_invoice_items.find((inv) => {
            return inv.id === itemArr[0].itemId;
          });
          let sum = 0;
          itemArr.map((item) => {
            sum += Number(item.quantity);
          });
          if (sum !== InvItem.quantity) {
            errors.items = `Total Quantity does not for ${InvItem.itemdescription}`;
            ShowError(`Total Quantity does not for ${InvItem.itemdescription}`);
          }
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      const res = await instance({
        url: `eup_invoice/createTagging`,
        method: "POST",
        data: {
          invoiceId: props.invoiceId,
          items: values.items,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      }).catch(() => {
        ShowError("Something went wrong");
      });

      if (res.data.status === "success") {
        ShowSuccess(res.data.message);
        handleClose();
        props.getCkInvoices();
      }
    },
  });

  React.useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
  }));
  useLayoutEffect(() => {
    const getAllSchools = async () => {
      const res = await instance({
        url: "school/get/allSchoolsAdmin",
        method: "GET",
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      const newArr = res.data.message.filter((school) => {
        return school.school_name !== "";
      });
      console.log(newArr);
      setAllSchools(newArr);
    };
    getAllSchools();
  }, []);

  const handleClose = () => {
    setSchool([{ id: 1, schoolName: "" }]);
    setOpen(false);
    setStep(1);
    formik.values.items = [];
  };

  const submitStepOne = async () => {
    let emptySchool = false;

    school.map((item) => {
      if (item.schoolName === "") {
        emptySchool = true;
      }
    });
    if (emptySchool) {
      ShowError("Please Enter School Name");
      return;
    }

    const invDetail = await instance({
      url: `eup_invoice/geteupinvoices/detail/${props.invoiceId}`,
      method: "GET",
      headers: {
        Authorization: Cookies.get("accessToken"),
      },
    });
    setInvDetail(invDetail.data.message[0]);
    setStep(2);
  };

  const handleForm = (qty, item, schoolId) => {
    console.log(qty, item, schoolId);
    let exist = false;
    const newItems = formik.values.items.map((entry) => {
      if (entry.schoolId === schoolId && entry.itemId === item.id) {
        exist = true;
        return {
          ...entry,
          quantity: qty,
        };
      } else {
        return entry;
      }
    });
    if (!exist) {
      formik.values.items.push({
        schoolId,
        itemId: item.id,
        quantity: qty,
        price: item.price,
        discountPercent: item.discountpercent,
      });
    } else {
      formik.values.items = newItems;
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      //   onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"lg"}
      fullWidth
    >
      <div className="flex flex-col gap-2 px-[5rem] py-4 bg-slate-500">
        {step === 1 ? (
          <>
            <h1 className="text-gray-100 font-semibold text-lg">Add Schools</h1>
            {school.map((row) => (
              <div className="flex" key={row.id}>
                <SearchDropDown
                  label={"Select School"}
                  color={"rgb(243, 244, 246)"}
                  data={allSchools}
                  Name={"school_name"}
                  handleOrderProcessingForm={(value, name) => {
                    const newArr = school.map((item) => {
                      if (item.id === row.id) {
                        return {
                          ...item,
                          schoolName: value?.school_name,
                          schoolId: value?.id,
                        };
                      } else {
                        return item;
                      }
                    });
                    setSchool(newArr);
                  }}
                />
              </div>
            ))}
            <Tooltip
              title="Add School"
              onClick={() => {
                setSchool([
                  ...school,
                  { id: school.length + 1, schoolName: "" },
                ]);
              }}
            >
              <Fab color={"red"} size="small" aria-label="add">
                <Add />
              </Fab>
            </Tooltip>
            <div className="mt-[3rem]" onClick={submitStepOne}>
              <BasicButton size={"small"} text={"Next"} />
            </div>
          </>
        ) : (
          <>
            <h1 className="text-gray-100 font-semibold text-lg">
              Fill the details
            </h1>
            <Paper>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                  <TableHead className="bg-slate-500">
                    <TableRow>
                      <TableCell
                        className="!w-[8rem] !text-gray-100"
                        align="center"
                      >
                        Item Name
                      </TableCell>
                      <TableCell
                        className="!w-[8rem] !text-gray-100"
                        align="center"
                      >
                        Quantity
                      </TableCell>

                      {school.map((item) => {
                        return (
                          <TableCell
                            className="!w-[8rem] !text-gray-100"
                            align="center"
                          >
                            {item.schoolName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody className="bg-slate-200">
                    {invDetail?.eup_invoice_items?.map((row) =>
                      row?.tree_type === "iNotATree" ||
                      row?.tree_type === "iSalesTree" ? (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">
                            {row?.itemdescription}
                          </TableCell>
                          <TableCell align="center">{row?.quantity}</TableCell>
                          {school.map((item) => {
                            return (
                              <TableCell align="center">
                                <BasicTextFields
                                  type={"number"}
                                  variant={"outlined"}
                                  inputColor={"black"}
                                  handleOrderProcessingForm={(value) => {
                                    handleForm(
                                      Number(value),
                                      row,
                                      item.schoolId
                                    );
                                  }}
                                />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ) : null
                    )}
                    <TableRow></TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <div className="mt-[1rem]" onClick={formik.handleSubmit}>
              <BasicButton text={"Submit"} />
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
});

export default TagSchoolAgainstInv;
