import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Loader from "../../Components/Loader";
import SwipeableTemporaryDrawer from "../../Components/Material/MaterialSidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../Components/Material/Button";
import instance from "../../Instance";
import Cookies from "js-cookie";
import BasicTextFields from "../../Components/Material/TextField";
import { ShowError, ShowSuccess } from "../../util/showError";

const ApproveUpdateSchoolReq = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolData, setSchoolData] = useState({
    oldData: null,
    newData: null,
  });
  const sidebarRef = useRef();
  const navigate = useNavigate();
  const show = null;

  const { id } = useParams();

  const remarks = useRef(null);

  const navInfo = {
    title: "Approve Update School",
    details: ["Home", "Approve Update School"],
  };

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };
  const getSchoolList = async () => {
    try {
      const res = await instance({
        method: "GET",
        url: "school/getComparisonData/" + id,
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
      });
      setSchoolData(res.data.data);
    } catch (error) {}
  };

  console.log(schoolData);

  useLayoutEffect(() => {
    getSchoolList();
  }, []);

  const handleSubmit = async (status) => {
    if (remarks.current === "" || remarks.current === null) {
      ShowError("Please enter remarks");
      return;
    }
    try {
      const res = await instance({
        method: "POSt",
        url: "school/updateApprovalStatus/" + id,
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
        data: {
          remarks: remarks.current,
          approvalStatus: status,
        },
      });
      if (res.data.status === "success") {
        ShowSuccess(res.data.message);
        setTimeout(() => {
          navigate("/admin/manageschool/updateSchool");
        }, 2000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };
    window.addEventListener("resize", handleWidth);
    handleWidth();

    window.scroll(0, 0);

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);
  return (
    <>
      <div className="flex w-[100%] min-h-[100vh]">
        {loading ? <Loader /> : null}

        <Sidebar
          highLight={"manage_school"}
          sidebarCollapsed={sidebarCollapsed}
          show={show}
        />

        <div>
          <SwipeableTemporaryDrawer
            ref={sidebarRef}
            sidebarCollapsed={sidebarCollapsed}
            show={show}
            highLight={"manage_school"}
          />
        </div>
        <div
          className={`flex flex-col w-[100vw] relative transition-all ease-linear duration-300 lg:w-[83vw] lg:ml-[18vw] ${
            window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[85vw]"
          } `}
        >
          <Navbar
            handleSidebarCollapsed={handleSidebarCollapsed}
            info={navInfo}
          />

          <div className="min-h-[90vh] flex w-full flex-col justify-center items-center gap-4 bg-[#141728] px-4">
            <section className="flex justify-center w-full mt-4 sm:flex-row flex-col flex-wrap mx-8 items-start gap-12">
              {schoolData.oldData !== null && schoolData.newData !== null ? (
                <>
                  <div className=" flex flex-col gap-3 items-center justify-center p-4 bg-slate-500 text-gray-100 font-semibold rounded-md">
                    <p>Old Data</p>
                    <hr className="w-[100%] text-white border-2 border-white" />

                    <p>School Name : {schoolData?.oldData?.school_name}</p>
                    <p>
                      Affiliate Code : {schoolData?.oldData?.afflication_code}
                    </p>
                    <p>Board : {schoolData?.oldData.fk_board?.board_name}</p>
                    <p>
                      Category:{" "}
                      {schoolData?.oldData.fk_category?.schoolCategory}
                    </p>
                    <p>Name : {schoolData?.oldData.school_contacts[0]?.name}</p>
                    <p>
                      Email : {schoolData?.oldData.school_contacts[0]?.email}
                    </p>
                    <p>
                      Phone : {schoolData?.oldData.school_contacts[0]?.phone}
                    </p>
                    <p>
                      Website :{" "}
                      {schoolData?.oldData.school_contacts[0]?.website}
                    </p>
                    <p>
                      Designation :{" "}
                      {schoolData?.oldData.school_contacts[0]?.designation}
                    </p>
                    <p>
                      State :{" "}
                      {schoolData?.oldData.school_addresses[0]?.fk_state?.state}
                    </p>
                    <p>
                      City :{" "}
                      {schoolData?.oldData.school_addresses[0]?.fk_city?.city}
                    </p>
                    <p>
                      Address :{" "}
                      {schoolData?.oldData?.school_addresses[0]?.address}
                    </p>
                    <p>
                      Pincode : {schoolData?.oldData?.school_addresses[0]?.pin}
                    </p>
                  </div>
                  <div className=" flex flex-col gap-3 items-center justify-center p-4 bg-slate-500 text-gray-100 font-semibold rounded-md">
                    <p>New Data</p>
                    <hr className="w-[100%] text-white border-2 border-white" />

                    <p>School Name : {schoolData?.newData?.school_name}</p>
                    <p>
                      Affiliate Code : {schoolData?.newData?.affiliation_code}
                    </p>
                    <p>Board : {schoolData?.newData.fk_board?.board_name}</p>
                    <p>
                      Category:{" "}
                      {schoolData?.newData.fk_category?.schoolCategory}
                    </p>
                    <p>Name : {schoolData?.newData?.contact_name}</p>
                    <p>Email : {schoolData?.newData?.contact_email}</p>
                    <p>Phone : {schoolData?.newData?.contact_phone}</p>
                    <p>Website : {schoolData?.newData?.contact_website}</p>
                    <p>
                      Designation : {schoolData?.newData?.contact_designation}
                    </p>
                    <p>State : {schoolData?.newData?.fk_state?.state}</p>
                    <p>City : {schoolData?.newData?.fk_city?.city}</p>
                    <p>Address : {schoolData?.newData?.address}</p>
                    <p>Pincode : {schoolData?.newData?.pincode}</p>
                  </div>
                </>
              ) : null}
            </section>

            <div className="w-full flex justify-start gap-4 flex-col bg-slate-500 p-8 rounded-md mb-10">
              <BasicTextFields
                lable={"Remarks"}
                handleOrderProcessingForm={(value, _) => {
                  remarks.current = value;
                }}
              />
              <div className="w-full flex justify-start gap-4">
                <div onClick={() => handleSubmit("approved")}>
                  <BasicButton text={"Approve"} />
                </div>
                <div onClick={() => handleSubmit("rejected")}>
                  <BasicButton text={"Reject"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveUpdateSchoolReq;
