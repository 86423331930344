import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Loader from "../../Components/Loader";
import SwipeableTemporaryDrawer from "../../Components/Material/MaterialSidebar";
import documentImg from "../../assets/img/documents.png";
import { Link, useNavigate } from "react-router-dom";
import BasicButton from "../../Components/Material/Button";
import instance from "../../Instance";
import Cookies from "js-cookie";
import SearchDropDown from "../../Components/SearchDropDown";
import BasicTextFields from "../../Components/Material/TextField";
import { ShowError, ShowSuccess } from "../../util/showError";
import { Autocomplete, TextField } from "@mui/material";

const UserCreateUser = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const sidebarRef = useRef();
  const show = null;
  const [allUser, setAllUser] = useState([]);
  const [user, setUser] = useState(null);
  const [state, setState] = useState([]);
  const [managers, setManagers] = useState([]);

  const stateVal = useRef(null);

  const navInfo = {
    title: "Admin Create User",
    details: ["Home", "Admin Create User"],
  };

  const handleSidebarCollapsed = () => {
    // setSidebarCollapsed(!sidebarCollapsed);
    sidebarRef.current.openSidebar();
  };

  const getUsers = async () => {
    const res = await instance({
      url: "user/getAllusers",
      method: "GET",
      headers: {
        Authorization: `${Cookies.get("accessToken")}`,
      },
    });
    setAllUser(res.data.message);
  };

  const getStates = async () => {
    const res = await instance({
      url: "location/state/get/allStates",
      method: "GET",
      headers: {
        Authorization: `${Cookies.get("accessToken")}`,
      },
    });
    setState(res.data.message);
  };

  const createUser = async () => {
    try {
      console.log(user, stateVal.current, managers);

      setLoading(true);
      const res = await instance({
        url: "auth/createUserByAdmin",
        method: "POST",
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
        data: {
          userId: user.id,
          managers: managers.map((item) => {
            return item.split("-")[0].trim();
          }),
          stateId: stateVal.current.id,
        },
      });
      if (res.data.status === "success") {
        ShowSuccess(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const managersList = () => {
    const list = allUser?.map((item, index) => {
      return `${item?.emp_id} - ${item?.first_name} ${item?.last_name}`;
    });

    return list;
  };

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };
    window.addEventListener("resize", handleWidth);
    handleWidth();
    getUsers();
    getStates();

    window.scroll(0, 0);

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);
  return (
    <>
      <div className="flex w-[100%] min-h-[100vh]">
        {loading ? <Loader /> : null}

        <Sidebar
          highLight={"user_management"}
          sidebarCollapsed={sidebarCollapsed}
          show={show}
        />

        <div>
          <SwipeableTemporaryDrawer
            ref={sidebarRef}
            sidebarCollapsed={sidebarCollapsed}
            show={show}
            highLight={"user_management"}
          />
        </div>
        <div
          className={`flex flex-col w-[100vw] relative transition-all ease-linear duration-300 lg:w-[83vw] lg:ml-[18vw] ${
            window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[85vw]"
          } `}
        >
          <Navbar
            handleSidebarCollapsed={handleSidebarCollapsed}
            info={navInfo}
          />

          {showMap ? (
            <div className="h-[90vh] bg-gray-300">
              {/* <GoogleMap
                sidebarCollapsed={sidebarCollapsed}
                currentLocation={currentLocation}
              /> */}
            </div>
          ) : (
            <div className="min-h-[90vh] relative flex w-full justify-center items-start gap-4 bg-[#141728]">
              <div className="grid grid-cols-2 grid-rows-2 md:grid-cols-3 md:grid-rows-2 w-full justify-items-start items-center mx-4 px-6 mb-8 py-3 mt-6 gap-12 rounded-md bg-slate-600">
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-gray-100">Select User</label>

                  <SearchDropDown
                    label={"Select User"}
                    handleOrderProcessingForm={(value, type) => {
                      setUser(value);
                    }}
                    color={"rgb(243, 244, 246)"}
                    data={allUser}
                    Name="get_all_user"
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-gray-100">Select Managers</label>

                  <Autocomplete
                    value={managers}
                    onChange={(_, newValue) => {
                      setManagers(newValue);
                    }}
                    disableClearable
                    id="controllable-states-demo"
                    multiple={true}
                    options={managersList()}
                    sx={{ width: "100%" }}
                    color="rgb(243, 244, 246)"
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        size="small"
                        label="Select Managers"
                        InputLabelProps={{
                          style: { color: "rgb(243, 244, 246) " },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-gray-100">Select State</label>

                  <SearchDropDown
                    handleOrderProcessingForm={(value, _) => {
                      stateVal.current = value;
                    }}
                    label={"Select State"}
                    data={state}
                    Name={"state_manageSchool"}
                    color={"rgb(243, 244, 246)"}
                  />
                </div>

                <div
                  onClick={() => {
                    if (
                      user !== null &&
                      managers.length > 0 &&
                      stateVal.current
                    ) {
                      createUser();
                    }
                  }}
                  className="h-fit"
                >
                  <BasicButton
                    text={"Submit"}
                    disable={
                      user !== null && managers.length > 0 && stateVal.current
                        ? false
                        : true
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserCreateUser;
