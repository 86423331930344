import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomizedSteppers from "../Material/Stepper";
import axios from "axios";
import instance from "../../Instance";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { TableFooter, TablePagination } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { userlogintype } from "../../util/returnUserType";
import SalesRemarkDialog from "./SalesRemarkDialog";
import ckInstance from "../../ckInstance";

const getSeries = async (schoolId) => {
  const token = await returnCkAuthToken();

  const seriesData = await ckInstance({
    url: `visor/assignedSeries?schoolCode=${schoolId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let gradeData = { nur_ukg: false, grade1_grade5: false, premium: false };

  Object.keys(seriesData.data).map((key) => {
    if (seriesData.data[key].length > 0) {
      if (key === "LKG" || key === "NUR" || key === "UKG") {
        gradeData.nur_ukg = true;
      }
      if (key.includes("GRADE")) {
        gradeData.grade1_grade5 = true;
      }

      seriesData.data[key].map((item) => {
        if (item.includes("PREMIUM")) {
          gradeData.premium = true;
        }
      });
    }
  });

  let pvs = 2;

  if (gradeData.grade1_grade5) {
    pvs = 3;
  }
  if (gradeData.premium && gradeData.grade1_grade5) {
    pvs = 4;
  }
  return { pvs, seriesData };
};

const returnCkAuthToken = async () => {
  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("client_id", "c2Nob29sX21pdHJh");
  params.append("secret", "Y2xhc3NrbGFwQDIwMjM=");
  params.append("email", "prateek.mudliar@classklap.com");

  const res = await ckInstance({
    url: `v1/login/eupheus`,
    method: "POST",
    data: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return res.data.accessToken;
};

const getApplicableInterventions = async (id, pvNum, setLoading) => {
  const res = await instance({
    url: `interventions/getApplicableInterventionType/${id}/${pvNum}`,
    method: "GET",
    headers: {
      Authorization: `${Cookies.get("accessToken")}`,
    },
  }).catch((err) => {
    setLoading(false);
  });
  if (res.status !== 200) {
    return [];
  }
  const sortedArr = res.data.data.sort((a, b) => a.sequence - b.sequence);
  if (pvNum < 4) {
    sortedArr.splice(11, 1);
    if (pvNum === 2) {
      sortedArr.splice(9, 1);
    }
  }
  return sortedArr;
};

function Row(props) {
  const { row, setLoading, SalesRemarksRef, page } = props;
  const [open, setOpen] = React.useState(false);
  const [interventions, setInterventions] = React.useState([]);
  const [seriesData, setSeriesData] = React.useState({});
  const [active, setActive] = React.useState(0);
  const [interventionData, setInterventionData] = React.useState([]);

  const collapse = async (id) => {
    if (open === false && interventions.length === 0) {
      setLoading(true);
      const {
        pvs,
        seriesData: { data },
      } = await getSeries(id).catch(() => {
        setLoading(false);
      });
      const interventions = await getApplicableInterventions(
        id,
        pvs,
        setLoading
      );
      if (interventions.length > 0) {
        setInterventions(interventions);
        returnActive(interventions);
        Object.keys(data).map((key) => {
          if (data[key].length > 0) {
            let newKey = key.toString().includes("_")
              ? key.replaceAll("_", " ")
              : key;
            data[newKey] = data[key].map((element) => {
              return element.replaceAll("_", " ");
            });
            if (newKey !== key) {
              delete data[key];
            }
          }
        });
        const InterventionData = await instance({
          url: `interventions/getInterventions/${id}`,
          method: "GET",
          headers: {
            Authorization: `${Cookies.get("accessToken")}`,
          },
        }).catch(() => {
          setLoading(false);
        });
        setInterventionData(InterventionData.data.data);
        setSeriesData(data);
        setLoading(false);
      }
    }
    setOpen(!open);
  };

  React.useEffect(() => {
    setOpen(false);
    setInterventions([]);
  }, [page]);

  const returnActive = (interventions) => {
    let active = 0;
    for (let i = 0; i < interventions.length; i++) {
      const element = interventions[i];
      if (element.isValid) break;
      else active++;
    }
    setActive(active);
  };

  const navigate = useNavigate();

  const handleClick = (interventionId) => {
    if (userlogintype() === "trainer") {
      navigate(
        `/interventions/trainer/${interventionId}/${row.id}/${row.label}`
      );
    } else {
      SalesRemarksRef.current.openDialog(interventionId);
    }
  };

  const returnSteps = () => {
    switch (userlogintype()) {
      case "supervisor":
        const steps = interventions.map((item) => {
          return item.type;
        });
        return steps;

        break;

      default:
        return interventions;
        break;
    }
  };

  const returnUserIntervention = () => {
    if (userlogintype() === "user") {
      return "SalesInterVentions";
    }

    if (userlogintype() === "trainer") {
      return "Interventions";
    }

    return undefined;
  };

  const returnSchoolTrainer = (schoolInfo) => {
    const { id } = schoolInfo;

    let trainerName = null;

    for (let i = 0; i < ckTrainerData.length; i++) {
      const item = ckTrainerData[i];
      if (id === item["Old School Code"] || id === item["New School Code"]) {
        trainerName = item.Trainer;
        break;
      }
    }

    return trainerName;
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              collapse(row.id);
            }}
          >
            {open ? (
              <KeyboardArrowUpIcon className="!text-gray-100" />
            ) : (
              <KeyboardArrowDownIcon className="!text-gray-100" />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="!text-gray-100">
          {row.label}
        </TableCell>
        <TableCell component="th" scope="row" className="!text-gray-100">
          {row.id}
        </TableCell>
        {row.state ? (
          <TableCell component="th" scope="row" className="!text-gray-100">
            {row.state}
          </TableCell>
        ) : null}
        {row.trainer ? (
          <TableCell component="th" scope="row" className="!text-gray-100">
            {returnSchoolTrainer(row) === null
              ? row.trainer
              : returnSchoolTrainer(row)}
          </TableCell>
        ) : null}
        {row.sales ? (
          <TableCell component="th" scope="row" className="!text-gray-100">
            {row.sales}
          </TableCell>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <CustomizedSteppers
                activeStep={active}
                brightSteps={true}
                name={returnUserIntervention()}
                steps={returnSteps()}
                stepColor={"#22C14F"}
                handleClick={handleClick}
                interventionData={interventionData}
              />
            </Box>
            <p className="!text-gray-100 mb-1">Grade --- Series</p>
            {Object.keys(seriesData).map((key, index) => {
              return (
                seriesData[key].length > 0 && (
                  <p className="!text-gray-100" key={index}>
                    {key} --- {seriesData[key].join(", ")}
                  </p>
                )
              );
            })}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ columns, data, setLoading }) {
  const [q, setQ] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const SalesRemarksRef = React.useRef();
  const search = (rows) => {
    return rows.filter((row) => {
      return (
        row.label.toLowerCase().indexOf(q) > -1 ||
        row?.state?.toLowerCase()?.indexOf(q) > -1 ||
        row?.trainer?.toLowerCase()?.indexOf(q) > -1 ||
        row?.sales?.toLowerCase()?.indexOf(q) > -1
      );
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  return (
    <div className="relative mt-9">
      <input
        className="px-8 md:w-[15vw] w-[30vw] lg:w-[13rem] focus:outline-0 hover:shadow-md transition-all duration-200 ease-linear py-1 lg:py-2 placeholder:text-gray-300 rounded-lg absolute right-4 -top-12"
        placeholder="Search"
        type="text"
        value={q}
        onChange={(e) => setQ(e.target.value.toLowerCase())}
      />
      <SalesRemarkDialog setLoading={setLoading} ref={SalesRemarksRef} />
      <TableContainer component={Paper} className="!bg-slate-600">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align="left"
                  style={{ minWidth: column.minWidth }}
                  className="!text-gray-100"
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? search(data).slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : search(data)
            ).map((row, index) => (
              <Row
                SalesRemarksRef={SalesRemarksRef}
                key={index}
                row={row}
                setLoading={setLoading}
                page={page}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={4}
                count={search(data).length}
                rowsPerPage={rowsPerPage}
                page={page}
                sx={{ color: "whitesmoke" }}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

const ckTrainerData = [
  {
    "Old School Code": "AS8004",
    "New School Code": "AS9004",
    "Name of School ": "Don Bosco School",
    City: "Sivasagar District",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8005",
    "New School Code": "AS9005",
    "Name of School ": "Little Flower School",
    City: "Dibrugarh",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "OR8010",
    "New School Code": "OR9010",
    "Name of School ": "Little Star Public School",
    City: "Po-Chindaguda,P.S-Khariar",
    State: "Odisha",
    "Sales Person": "Pramod Ku Panda",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "AS8006",
    "New School Code": "AS9006",
    "Name of School ": "Don Bosco Jibon Jyoti High School",
    City: "Golaghat",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8007",
    "New School Code": "AS9007",
    "Name of School ": "Jibon Jyoti High School",
    City: "Golaghat",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "NL8009",
    "New School Code": "NL9009",
    "Name of School ": "Oking Christan School",
    City: "Kohima",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "NL8010",
    "New School Code": "NL9010",
    "Name of School ": "Nagabazar Baptist School",
    City: "Kohima",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "UP8056",
    "New School Code": "UP9056",
    "Name of School ": "N.S. Public School",
    City: "Greater Noida West",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "ME8001",
    "Name of School ": "Median Memorial English School ",
    City: "Shillong",
    State: "Meghalaya",
    "Sales Person": "Noel Tariang",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "NL8002",
    "New School Code": "NL9002",
    "Name of School ": "Don Bosco Hr Sec School ",
    City: "Kohima",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "NL8004",
    "Name of School ": "Don Bosco Hr. Sec. School, Zubza",
    City: "Kohima",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "NL8006",
    "New School Code": "NL9006",
    "Name of School ": "Christ King Higher Secondary School",
    City: "Kohima",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "NL8007",
    "New School Code": "NL9007",
    "Name of School ": "Concern Kids School",
    City: "Dimapur",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "NL8001",
    "New School Code": "NL9001",
    "Name of School ": "Saint Johns School",
    City: "Dimapur",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "NL8005",
    "New School Code": "NL9005",
    "Name of School ": "Pike Central School",
    City: "Dimapur",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8003",
    "New School Code": "AS9003",
    "Name of School ": "Guwahati Public School",
    City: "Guwahati ",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "ME8003",
    "New School Code": "ME9003",
    "Name of School ": "Leads Secondary School",
    City: "Tura",
    State: "Meghalaya",
    "Sales Person": "Noel Tariang",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "ME8002",
    "New School Code": "ME9002",
    "Name of School ": "St. Xaviers Hss",
    City: "Tura",
    State: "Meghalaya",
    "Sales Person": "Noel Tariang",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8008",
    "New School Code": "AS9008",
    "Name of School ": "Centre For New Learning",
    City: "Bongaigaon",
    State: "Assam",
    "Sales Person": "Debanga Pratim Dutta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "NL8012",
    "New School Code": "NL9012",
    "Name of School ": "St Thomas  Hr Sec School ",
    City: "Dimapur",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8009",
    "New School Code": "AS9009",
    "Name of School ": "St. Mary'S English School",
    City: "Nalbari",
    State: "Assam",
    "Sales Person": "Debanga Pratim Dutta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8010",
    "New School Code": "AS9010",
    "Name of School ": "Bodoland Pioneer Public School",
    City: "Kokrajhar",
    State: "Assam",
    "Sales Person": "Debanga Pratim Dutta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "RJ8024",
    "Name of School ": "Bright Future Academy School",
    City: "Marwar Junction",
    State: "Rajasthan",
    "Sales Person": "Yogesh Sharma",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "AS8011",
    "New School Code": "AS9011",
    "Name of School ": "St. Joseph'S Convent Higher Sec. School",
    City: "Tezpur",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8012",
    "New School Code": "AS9012",
    "Name of School ": "Pkm E.M. School",
    City: "Bongaigaon",
    State: "Assam",
    "Sales Person": "Debanga Pratim Dutta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "OR9011",
    "Name of School ": "Dayanand Public School",
    City: "Sambalpur",
    State: "Odisha",
    "Sales Person": "Pramod Ku Panda",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "HR8054",
    "New School Code": "HR9054",
    "Name of School ": "Global Heights School",
    City: "Bahadurgarh",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8057",
    "New School Code": "UP9057",
    "Name of School ": "Phoenix International Academy",
    City: "Noida",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "AS8013",
    "New School Code": "AS9013",
    "Name of School ": "Don Bosco School ((Ouguri)",
    City: "Bongaigaon",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8014",
    "New School Code": "AS9014",
    "Name of School ": "Don Bosco School (Malivita)",
    City: "Bongaigaon",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8015",
    "New School Code": "AS9015",
    "Name of School ": "Don Bosco School (Balajhar)",
    City: "Bongaigaon",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8016",
    "New School Code": "AS9016",
    "Name of School ": "Don Bosco Hr. Sec. School (Tangla)",
    City: "Udalguri",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "ME8004",
    "New School Code": "ME9004",
    "Name of School ": "The Learning Sanctuary",
    City: "Tura",
    State: "Meghalaya",
    "Sales Person": "Noel Tariang",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS9018",
    "Name of School ": "Don Bosco Cbse School (Tezpur)",
    City: "Tezpur",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "UP8059",
    "New School Code": "UP9059",
    "Name of School ": "St. Joseph School",
    City: "Kunda",
    State: "Uttar Pradesh",
    "Sales Person": "Mr. Akash Agarwal ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "JK8001",
    "Name of School ": "Green Valley Educational Institute",
    City: "Srinagar",
    State: "Jammu & Kashmir",
    "Sales Person": "Khursheed Ahmad Dar",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "UP8058",
    "Name of School ": "Rao Kasal Public School",
    City: "Greater Noida",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "AS8017",
    "New School Code": "AS9017",
    "Name of School ": "Bethany School",
    City: "Guwahati",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "RJ8044",
    "New School Code": "RJ9044",
    "Name of School ": "Miles International School",
    City: "Sikar",
    State: "Rajasthan",
    "Sales Person": "Vivekanand Ojha",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "PB9003",
    "New School Code": "PB9003",
    "Name of School ": "Guru Nanak Public School ",
    City: "Ludhiana",
    State: "Punjab",
    "Sales Person": "Rajdeep Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "HR8026",
    "Name of School ": "Saraswati Sr Sec School",
    City: "Bhiwani",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "NL8013",
    "New School Code": "NL9013",
    "Name of School ": "Mount Zion School",
    City: "Dimapur",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "JK8018",
    "New School Code": "JK9018",
    "Name of School ": "Mutahhary Educational Society, Kargil",
    City: "Srinagar",
    State: "Jammu & Kashmir",
    "Sales Person": "Vijay Paul",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "AS8020",
    "New School Code": "AS9020",
    "Name of School ": "St. Mary'S Sr. Secondary School",
    City: "Guwahati",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "WB8004",
    "New School Code": "WB9004",
    "Name of School ": "Al Manarah International School",
    City: "Kulti",
    State: "West Bengal",
    "Sales Person": "Rajiv Mukherjee",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "HR8055",
    "New School Code": "HR9055",
    "Name of School ": "St. Thomas Kindergarten",
    City: "Hansi",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "AS8019",
    "New School Code": "AS9019",
    "Name of School ": "Carmel School",
    City: "Guwahati",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "HR8005",
    "Name of School ": "Adarsh Senior Secondary School",
    City: "Fatehabad",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8045",
    "Name of School ": "City Sr. Sec. School",
    City: "Fatehabad",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "OR8012",
    "New School Code": "OR9012",
    "Name of School ": "Delhi Public School ",
    City: "Dist Angul ",
    State: "Odisha",
    "Sales Person": "Akashaya Dhal",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "NL8003",
    "New School Code": "NL9003",
    "Name of School ": "Little Flower School",
    City: "Kohima",
    State: "Nagaland",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "JH8007",
    "New School Code": "JH9007",
    "Name of School ": "Dunes International School",
    City: "Hazaribagh",
    State: "Jharkhand",
    "Sales Person": "Nishant Bhaskar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "OR8013",
    "New School Code": "OR9013",
    "Name of School ": "St. Mary'S Convent School",
    City: "Nuapada",
    State: "Odisha",
    "Sales Person": "Pramod Ku Panda",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": " AS8021",
    "New School Code": "AS9021",
    "Name of School ": "St. Mary'S School",
    City: "Guwahati",
    State: "Assam",
    "Sales Person": "Ramesh Choudhary",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8022",
    "New School Code": "AS9022",
    "Name of School ": "Holy Child School",
    City: "Guwahati",
    State: "Assam",
    "Sales Person": "Ramesh Choudhary",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "UP8061",
    "New School Code": "UP9061",
    "Name of School ": "Raja Ranjeet Singh Inter College",
    City: "Bahraich",
    State: "Uttar Pradesh",
    "Sales Person": "Amit Kumar Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HR8056",
    "New School Code": "HR9056",
    "Name of School ": "Mother'S Care Primary School",
    City: "Charkhi Dadri",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "AS8023",
    "New School Code": "AS9023",
    "Name of School ": "Brahmaputra Valley English Academy, Bongora",
    City: "Guwahati",
    State: "Assam",
    "Sales Person": "Debanga Pratim Dutta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "JK8010",
    "Name of School ": "Shemrock Unity",
    City: "Nowshera",
    State: "Jammu & Kashmir",
    "Sales Person": "Adish Gupta",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UP8062",
    "New School Code": "UP9062",
    "Name of School ": "The Om Public School",
    City: "Bulandshahar",
    State: "Uttar Pradesh",
    "Sales Person": "Vikul Vahshisth",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "JH8008",
    "New School Code": "JH9008",
    "Name of School ": "Jharkhand Public School",
    City: "Dhanbad",
    State: "Jharkhand",
    "Sales Person": "Debasish Bid",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "JH8009",
    "New School Code": "JH9009",
    "Name of School ": "Orchid International School",
    City: "Bundu, Ranchi",
    State: "Jharkhand",
    "Sales Person": "Ankit Kumar Dudeja",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "HP8008",
    "New School Code": "HP9008",
    "Name of School ": "Rise And Shine Play School",
    City: "Kullu",
    State: "Himachal Pradesh",
    "Sales Person": "Piyush Sharma",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "PB8067",
    "New School Code": "PB9067",
    "Name of School ": "Sant Avtar Singh Yadhgari Sen.Sec. School",
    City: "Sichewali",
    State: "Punjab",
    "Sales Person": "Amrik Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "PB8066",
    "New School Code": "PB9066",
    "Name of School ": "Angel Heart Convent School",
    City: "Moga",
    State: "Punjab",
    "Sales Person": "Amrik Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "PB8065",
    "New School Code": "PB9065",
    "Name of School ": "Max Arthur Macauliffe Public School",
    City: "Samrala , Ludhiana",
    State: "Punjab",
    "Sales Person": "Rajdeep Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "HR8058",
    "New School Code": "HR9058",
    "Name of School ": "Angel Palace International School",
    City: "Palwal",
    State: "Haryana",
    "Sales Person": "Sumit Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HP8003",
    "Name of School ": "Dhaliara Public School",
    City: "Dhaliara",
    State: "Himachal Pradesh",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "UP8063",
    "New School Code": "UP9063",
    "Name of School ": "G.R. Public School",
    City: "Hapur",
    State: "Uttar Pradesh",
    "Sales Person": "Puspendra Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "PB8068",
    "New School Code": "PB9068",
    "Name of School ": "Acme Public Sen. Sec. School",
    City: "Jalalabad",
    State: "Punjab",
    "Sales Person": "Sumit Garg",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "AS8001",
    "Name of School ": "Modern English School",
    City: "Guwahati",
    State: "Assam",
    "Sales Person": "Ramesh Choudhary",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "HR8059",
    "New School Code": "HR9059",
    "Name of School ": "The Shikshyan School",
    City: "Gurgaon",
    State: "Haryana",
    "Sales Person": "Sumit Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "JK8019",
    "New School Code": "JK9019",
    "Name of School ": "Tirupati Convent School",
    City: "Jammu",
    State: "Jammu & Kashmir",
    "Sales Person": "Adish Gupta",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "AS8025",
    "New School Code": "AS9025",
    "Name of School ": "Don Bosco Higher Secondary School",
    City: "Kokrajhar",
    State: "Assam",
    "Sales Person": "Debanga Pratim Dutta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "HR8057",
    "New School Code": "HR9057",
    "Name of School ": "Acme International School",
    City: "Sohna",
    State: "Haryana",
    "Sales Person": "Vivek Tyagi",
    Trainer: "Beant",
  },
  {
    "Old School Code": "PB8069",
    "New School Code": "PB9069",
    "Name of School ": "Manav Vikas Vidya Kendra",
    City: "Ludhiana",
    State: "Punjab",
    "Sales Person": "Rajdeep Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "BH8012",
    "New School Code": "BH9012",
    "Name of School ": "Shri Ram Centennial School",
    City: "Patna",
    State: "Bihar",
    "Sales Person": "Tapan Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "AS8026",
    "New School Code": "AS9026",
    "Name of School ": "Auxilium Convent Hr. Sec. School",
    City: "Tangla",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "JK8020",
    "New School Code": "JK9020",
    "Name of School ": "St. Peter'S Nursery School",
    City: "Jammu",
    State: "Jammu & Kashmir",
    "Sales Person": "Adish Gupta",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "UP8064",
    "New School Code": "UP9064",
    "Name of School ": "Sangam International School",
    City: "Prayagraj",
    State: "Uttar Pradesh",
    "Sales Person": "Prashant Singh",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "RJ7005",
    "Name of School ": "Dreamland Public School",
    City: "Sikar",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ8047",
    "New School Code": "RJ9047",
    "Name of School ": "Shree Kalyan Bal Mandir Secondary School",
    City: "Sikar",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "UP8065",
    "New School Code": "UP9065",
    "Name of School ": "Indian Global Public School",
    City: "Meerut",
    State: "Uttar Pradesh",
    "Sales Person": "Puspendra Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "UP8066",
    "New School Code": "UP9066",
    "Name of School ": "Raj Uchchatar Madhyamik Vidyalaya",
    City: "Varanasi",
    State: "Uttar Pradesh",
    "Sales Person": "Yogesh Rastogi",
    Trainer: "Abid",
  },
  {
    "Old School Code": "RJ8048",
    "New School Code": "RJ9048",
    "Name of School ": "Dev Vidyalaya",
    City: "Sikar",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "AS8027",
    "New School Code": "AS9027",
    "Name of School ": "Kishan Lal Malpani Bal Mandir",
    City: "Jorhat",
    State: "Assam",
    "Sales Person": "Parthasarathi Mahanta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": " RJ8046",
    "New School Code": "RJ9046",
    "Name of School ": "Dhanraj School",
    City: "Nagaur",
    State: "Rajasthan",
    "Sales Person": "Vivekanand Ojha",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "OR8014",
    "New School Code": "OR9014",
    "Name of School ": "Vss Public School",
    City: "Bargarh",
    State: "Odisha",
    "Sales Person": "Pramod Ku Panda",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "DL8011",
    "New School Code": "DL9011",
    "Name of School ": "Hamilton International School",
    City: "New Delhi",
    State: "Delhi",
    "Sales Person": "Naresh Vashisth",
    Trainer: "Beant",
  },
  {
    "Old School Code": "PB8071",
    "New School Code": "PB9071",
    "Name of School ": "S.G.N. Public Sr. Sec. School",
    City: "Nashera Pattan",
    State: "Punjab",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "JK8021",
    "New School Code": "JK9021",
    "Name of School ": "Learning Temple English Medium High School",
    City: "Kathua",
    State: "Jammu & Kashmir",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "PB8070",
    "New School Code": "PB9070",
    "Name of School ": "Shamrock International School",
    City: "Dinanagar",
    State: "Punjab",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UP8069",
    "New School Code": "UP9069",
    "Name of School ": "St. Xavier'S World School Junior",
    City: "Meerut",
    State: "Uttar Pradesh",
    "Sales Person": "Puspendra Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "UP8067",
    "New School Code": "UP9067",
    "Name of School ": "Bela Public School",
    City: "Varanasi",
    State: "Uttar Pradesh",
    "Sales Person": "Mayank Raj",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8068",
    "New School Code": "UP9068",
    "Name of School ": "Academic Global School",
    City: "Gorakhpur",
    State: "Uttar Pradesh",
    "Sales Person": "Mayank Raj",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8070",
    "New School Code": "UP9070",
    "Name of School ": "Smt. Draupadi Devi College",
    City: "Gorakhpur",
    State: "Uttar Pradesh",
    "Sales Person": "Mayank Raj",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HP8009",
    "New School Code": "HP9009",
    "Name of School ": "Sai Ram International Public School",
    City: "Nalagarh, District - Solan",
    State: "Himachal Pradesh",
    "Sales Person": "Piyush Sharma",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "PB8018",
    "Name of School ": "St. Mary'S School",
    City: "District - Rupnagar (Ropar)",
    State: "Punjab",
    "Sales Person": "Piyush Sharma",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "JK8022",
    "New School Code": "JK9022",
    "Name of School ": "My New School",
    City: "Kupwara ",
    State: "Jammu & Kashmir",
    "Sales Person": "Khursheed Ahmad Dar",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HA7008",
    "Name of School ": "Swami Vivekanand Sr Sec School",
    City: "Sirsa",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "AS8029",
    "New School Code": "AS9029",
    "Name of School ": "Maria Nivas School",
    City: "Dergaon",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "AS8028",
    "New School Code": "AS9028",
    "Name of School ": "Don Bosco School, Jorhat",
    City: "Jorhat",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "JH8010",
    "New School Code": "JH9010",
    "Name of School ": "Stephen'S Public School",
    City: "Hazaribagh",
    State: "Jharkhand",
    "Sales Person": "Nishant Bhaskar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "HR8061",
    "New School Code": "HR9061",
    "Name of School ": "Prayaas International School",
    City: "Sonipat",
    State: "Haryana",
    "Sales Person": "Sandeep Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8060",
    "New School Code": "HR9060",
    "Name of School ": "Bal Vikas Sr. Sec. School",
    City: "Bahadurgarh",
    State: "Haryana",
    "Sales Person": "Gaurav Jha",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8072",
    "New School Code": "UP9072",
    "Name of School ": "Midas Junior High School",
    City: "Padrauna",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "RJ8045",
    "New School Code": "RJ9045",
    "Name of School ": "Divine International School",
    City: "Jhunjhunu",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "UP8040",
    "Name of School ": "Pragyan Public School",
    City: "Gorakhpur",
    State: "Uttar Pradesh",
    "Sales Person": "Mayank Raj",
    Trainer: "Abid",
  },
  {
    "Old School Code": "OR8015",
    "New School Code": "OR9015",
    "Name of School ": "St. John'S School",
    City: "Sambalpur",
    State: "Odisha",
    "Sales Person": "Pramod Ku Panda",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": " UP8073",
    "New School Code": "UP9073",
    "Name of School ": "Gangotri Public Schol",
    City: "Deoria",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "OR8016",
    "New School Code": "OR9016",
    "Name of School ": "St. Luke'S School",
    City: "Sambalpur",
    State: "Odisha",
    "Sales Person": "Pramod Ku Panda",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "UP8076",
    "New School Code": "UP9076",
    "Name of School ": "Suraj International School",
    City: "Ghazipur",
    State: "Uttar Pradesh",
    "Sales Person": "Praveen Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8079",
    "New School Code": "UP9079",
    "Name of School ": "Ram Doot International School",
    City: "Ghazipur",
    State: "Uttar Pradesh",
    "Sales Person": "Dilshad Ali ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8078",
    "New School Code": "UP9078",
    "Name of School ": "Mother Haleema Central School",
    City: "Varanasi ",
    State: "Uttar Pradesh",
    "Sales Person": "Dilshad Ali ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8077",
    "New School Code": "UP9077",
    "Name of School ": "The Eden School",
    City: "Mirzapur ",
    State: "Uttar Pradesh",
    "Sales Person": "Dilshad Ali ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HR8062",
    "New School Code": "HR9062",
    "Name of School ": "Model Sanskriti Sr. Sec. School",
    City: "Bhiwani",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP8080",
    "New School Code": "UP9080",
    "Name of School ": "Jp Education Academy",
    City: "Gorakhpur",
    State: "Uttar Pradesh",
    "Sales Person": "Mayank Raj",
    Trainer: "Abid",
  },
  {
    "Old School Code": "PB8037",
    "Name of School ": "St. Soldier Elite Divine Public School",
    City: "Jalandhar",
    State: "Punjab",
    "Sales Person": "Amrik Singh",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "PB8036",
    "Name of School ": "St. Soldier Elite Divine Public School",
    City: "Ludhiana",
    State: "Punjab",
    "Sales Person": "Amrik Singh",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "RJ8001",
    "Name of School ": "Navjeevan Academy Senior Secondary School",
    City: "Sikar",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code":
      "PB8033                                                        ",
    "New School Code": "PB9033",
    "Name of School ": "St Soldier Elite Divine Public School",
    City: "Jalandhar",
    State: "Punjab",
    "Sales Person": "Amrik Singh",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "UP8081",
    "New School Code": "UP9081",
    "Name of School ": "Brainy Junior Public School",
    City: "Aligarh",
    State: "Uttar Pradesh",
    "Sales Person": "Afshan Ayaz",
    Trainer: "Yash",
  },
  {
    "Old School Code": "OR8017",
    "New School Code": "OR9017",
    "Name of School ": "Parisad E M School",
    City: "Rourkela",
    State: "Odisha",
    "Sales Person": "Pramod Ku Panda",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "RJ8004",
    "Name of School ": "Kanha Public School",
    City: "Alwar",
    State: "Rajasthan",
    "Sales Person": "Gaurav Jha",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "OR8009",
    "Name of School ": "Saraswati Vidya Mandir",
    City: "Sundargarh",
    State: "Odisha",
    "Sales Person": "Pramod Ku Panda",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "JH8011",
    "New School Code": "JH9011",
    "Name of School ": "Sheyn International School",
    City: "Kandra",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "PB8075",
    "New School Code": "PB9075",
    "Name of School ": "Seth Hukam Chand S. D. Public School",
    City: "Kapurthala",
    State: "Punjab",
    "Sales Person": "Vivek Malhotra",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "PB8013",
    "Name of School ": "Madhuvan Vatika Public School",
    City: "Noorpur Bedi",
    State: "Punjab",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "PB8074",
    "New School Code": "PB9074",
    "Name of School ": "S.R.B. International School",
    City: "Gharota",
    State: "Punjab",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "HR8063",
    "New School Code": "HR9063",
    "Name of School ": "Lord Krishna Public School",
    City: "Ambala Cantt",
    State: "Haryana",
    "Sales Person": "Rohit Tyagi",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "PB8031",
    "Name of School ": "National Wonder Smart School",
    City: "District - Mohali",
    State: "Punjab",
    "Sales Person": "Piyush Sharma",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "PB8007",
    "Name of School ": "Cambridge International School",
    City: "Hoshiarpur",
    State: "Punjab",
    "Sales Person": "Vivek Malhotra",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "JH8012",
    "New School Code": "JH9012",
    "Name of School ": "Maria International School",
    City: "Ranchi",
    State: "Jharkhand",
    "Sales Person": "Ankit Kumar Dudeja",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "HA7010",
    "Name of School ": "Shanti Niketan Public Sr. Sec. School",
    City: "Fatehabad",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8042",
    "Name of School ": "Pd Memorial Convent School",
    City: "Fatehabad",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8041",
    "Name of School ": "Green Valley Public Sr. Sec. School",
    City: "Hisar",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "DL8013",
    "New School Code": "DL9013",
    "Name of School ": "Accel Kids Play School",
    City: "New Delhi",
    State: "Delhi",
    "Sales Person": "Afshan Ayaz",
    Trainer: "Beant",
  },
  {
    "Old School Code": "UP8085",
    "New School Code": "UP9085",
    "Name of School ": "Kaynat Glorious Public School",
    City: "Sambhal ",
    State: "Uttar Pradesh",
    "Sales Person": "Afshan Ayaz",
    Trainer: "Yash",
  },
  {
    "Old School Code": "RJ8010",
    "Name of School ": "Vadanta International School",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "JH8003",
    "Name of School ": "Mount Litera Zee School",
    City: "Jamshedpur",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "JH8014",
    "New School Code": "JH9014",
    "Name of School ": "Zikra Girls Creative School",
    City: "Jamshedpur",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "RJ8050",
    "New School Code": "RJ9050",
    "Name of School ": "Vrindavan Public School",
    City: "Ajmer",
    State: "Rajasthan",
    "Sales Person": "Vishnu Jangid",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "JH8013",
    "New School Code": "JH9013",
    "Name of School ": "Jharkhand Public School, Koderma",
    City: "Koderma",
    State: "Jharkhand",
    "Sales Person": "Nishant Bhaskar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "AS8030",
    "New School Code": "AS9030",
    "Name of School ": "Holy Flower School",
    City: "Jorhat",
    State: "Assam",
    "Sales Person": "Parthasarathi Mahanta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "UP8024",
    "Name of School ": "Prem Public School",
    City: "Jalesar (Etah)",
    State: "Uttar Pradesh",
    "Sales Person": "Vivek Bansal",
    Trainer: "Yash",
  },
  {
    "Old School Code": "HR8066",
    "New School Code": "HR9066",
    "Name of School ": "Green Valley School",
    City: "Fatehabad",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8065",
    "New School Code": "HR9065",
    "Name of School ": "Aravali International School",
    City: "Rewari",
    State: "Haryana",
    "Sales Person": "Gaurav Jha",
    Trainer: "Beant",
  },
  {
    "Old School Code": "JH8015",
    "New School Code": "JH9015",
    "Name of School ": "Symbiosis Public School",
    City: "Ranchi",
    State: "Jharkhand",
    "Sales Person": "Ankit Kumar Dudeja",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "UP8083",
    "New School Code": "UP9083",
    "Name of School ": "Rajmata Anant Kumari Public School",
    City: "Awagarh (Etah)",
    State: "Uttar Pradesh",
    "Sales Person": "Vivek Bansal",
    Trainer: "Yash",
  },
  {
    "Old School Code": "DL8012",
    "New School Code": "DL9012",
    "Name of School ": "Navjeewan Model School",
    City: "New Delhi",
    State: "Delhi",
    "Sales Person": "Naresh Vashisth",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8074",
    "New School Code": "UP9074",
    "Name of School ": "Global Navjeewan School",
    City: "Ghaziabad",
    State: "Uttar Pradesh",
    "Sales Person": "Naresh Vashisth",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8084",
    "New School Code": "UP9084",
    "Name of School ": "Shri Raghunath Ji Academy",
    City: "Lakhimpur",
    State: "Uttar Pradesh",
    "Sales Person": "Sarvesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "RJ8051",
    "New School Code": "RJ9051",
    "Name of School ": "Emmanuel Mission Sr. Sec. School",
    City: "Jaisalmer",
    State: "Rajasthan",
    "Sales Person": "Yogesh Sharma",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HP8010",
    "New School Code": "HP9010",
    "Name of School ": "Mother Teresa Convent Global School",
    City: "Nagrota Bhagwan",
    State: "Himachal Pradesh",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HP8004",
    "Name of School ": "St. Paul'S Sr. Sec. School",
    City: "Palampur ",
    State: "Himachal Pradesh",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "RJ7002",
    "Name of School ": " Shri Gangaram Balkishan Modi Public School",
    City: "Jhunjhnu",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "UP5007",
    "Name of School ": "Scholars Public School ",
    City: "Chandauli ",
    State: "Uttar Pradesh",
    "Sales Person": "Praveen Singh ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8075",
    "New School Code": "UP9075",
    "Name of School ": "Sunsine English School",
    City: "Varanasi",
    State: "Uttar Pradesh",
    "Sales Person": "Praveen Singh ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8082",
    "New School Code": "UP9082",
    "Name of School ": "Sanjay City Model School",
    City: "Varanasi",
    State: "Uttar Pradesh",
    "Sales Person": "Rakesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HR8064",
    "New School Code": "HR9064",
    "Name of School ": "Delhi Public International School",
    City: "Rewari",
    State: "Haryana",
    "Sales Person": "Gaurav Jha",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8086",
    "New School Code": "UP9086",
    "Name of School ": "Cosmic International School",
    City: "Lucknow",
    State: "Uttar Pradesh",
    "Sales Person": "Sarvesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "OR8018",
    "New School Code": "OR9018",
    "Name of School ": "Prabhujee English Medium School",
    City: "Bhubaneswar ",
    State: "Odisha",
    "Sales Person": "Akash Kumar Behera",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "UP8009",
    "Name of School ": "Seven Hills Inter College",
    City: "Etawah ",
    State: "Uttar Pradesh",
    "Sales Person": "Nitendra Singh",
    Trainer: "Yash",
  },
  {
    "Old School Code": "UP8087",
    "New School Code": "UP9087",
    "Name of School ": "Valencia International School",
    City: "Lucknow",
    State: "Uttar Pradesh",
    "Sales Person": "Deepak Mishra",
    Trainer: "Abid",
  },
  {
    "Old School Code": "RJ8003",
    "Name of School ": "Defence Public School",
    City: "Jodhpur",
    State: "Rajasthan",
    "Sales Person": "Vivekanand Ojha",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "PB8076",
    "New School Code": "PB9076",
    "Name of School ": "Kingsview International School",
    City: "Dhuri ( Sangrur )",
    State: "Punjab",
    "Sales Person": "Rajdeep Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UP8088",
    "New School Code": "UP9088",
    "Name of School ": "Nilgiri Hills Public School",
    City: " Noida",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "UP8071",
    "New School Code": "UP9071",
    "Name of School ": "Surya Public School",
    City: "Lucknow",
    State: "Uttar Pradesh",
    "Sales Person": "Vijay Gupta",
    Trainer: "Abid",
  },
  {
    "Old School Code": "JH8005",
    "Name of School ": "S. K. Public School",
    City: "Jamshedpur",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "UP8089",
    "New School Code": "UP9089",
    "Name of School ": "Mount Fort Global Academy",
    City: "Gorakhpur",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8090",
    "New School Code": "UP9090",
    "Name of School ": "Essence Public School",
    City: "Ghaziabad",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR8002",
    "Name of School ": "Future Diamond Public School",
    City: "Ambala City",
    State: "Haryana",
    "Sales Person": "Rohit Tyagi",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "MP8017",
    "New School Code": "MP9017",
    "Name of School ": "Sanskruti School",
    City: "Pathriya",
    State: "Madhya Pradesh",
    "Sales Person": "Saheb Sharma",
    Trainer: "Yash",
  },
  {
    "Old School Code": "MP8018",
    "New School Code": "MP9018",
    "Name of School ": "Ips Academy School",
    City: "Bhind",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Yash",
  },
  {
    "Old School Code": "MP8001",
    "Name of School ": "Academic World School",
    City: "Bhind",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Yash",
  },
  {
    "Old School Code": "UP8091",
    "New School Code": "UP9091",
    "Name of School ": "R.D.N.C. Public School",
    City: "Deoria",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8027",
    "Name of School ": "Rio World Academy",
    City: "Azamgarh",
    State: "Uttar Pradesh",
    "Sales Person": "Rakesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HR8012",
    "Name of School ": "Gyandeep Model School (Gdm)",
    City: "Rewari",
    State: "Haryana",
    "Sales Person": "Gaurav Jha",
    Trainer: "Beant",
  },
  {
    "Old School Code": "JK8009",
    "Name of School ": "International Delhi Public School",
    City: "Rajouri",
    State: "Jammu & Kashmir",
    "Sales Person": "Adish Gupta",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "OR7008",
    "Name of School ": "St. Xavier'S High School",
    City: "Jagatsinghpur",
    State: "Odisha",
    "Sales Person": "Ranjan Samal",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "OR8004",
    "Name of School ": "Centurion Public School",
    City: "Rayagada ",
    State: "Odisha",
    "Sales Person": "Akash And Akshaya",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "UP8095",
    "New School Code": "UP9095",
    "Name of School ": "Ss Public School",
    City: "Varanasi",
    State: "Uttar Pradesh",
    "Sales Person": "Praveen Singh ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8096",
    "New School Code": "UP9096",
    "Name of School ": "Jaypee Vidya Mandir",
    City: "Bulandshahar",
    State: "Uttar Pradesh",
    "Sales Person": "Vikul Vahshisth",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8094",
    "New School Code": "UP9094",
    "Name of School ": "Sheetala Children School",
    City: "Varanasi",
    State: "Uttar Pradesh",
    "Sales Person": "Praveen Singh ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HR8068",
    "New School Code": "HR9068",
    "Name of School ": "Vikas International School",
    City: "Rewari.",
    State: "Haryana",
    "Sales Person": "Gaurav Jha",
    Trainer: "Beant",
  },
  {
    "Old School Code": "RJ8053",
    "New School Code": "RJ9053",
    "Name of School ": "Bal Bharti International School",
    City: "Sujangarh",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR8067",
    "New School Code": "HR9067",
    "Name of School ": "Glh Public School",
    City: "Rewari",
    State: "Haryana",
    "Sales Person": "Gaurav Jha",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8093",
    "New School Code": "UP9093",
    "Name of School ": "Kds International Public School",
    City: "Gorakhpur",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "CT8001",
    "Name of School ": "Bharat Mata Sr. Sec. School",
    City: "Naya Raipur",
    State: "Chhattisgarh",
    "Sales Person": "M. Azad Shah",
    Trainer: "Yash",
  },
  {
    "Old School Code": "UP8092",
    "New School Code": "UP9092",
    "Name of School ": "Cambridge International School",
    City: "Deoria",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8054",
    "Name of School ": "Real Paradise Academy",
    City: "Tulsi Nagar,Padrauna ",
    State: "Uttar Pradesh",
    "Sales Person": "Mayank Raj",
    Trainer: "Abid",
  },
  {
    "Old School Code": "MP8007",
    "Name of School ": "Global International Public  School",
    City: "Jhansi Road, Gwalior ",
    State: "Madhya Pradesh",
    "Sales Person": "Ajay Kumar Tiwary",
    Trainer: "Yash",
  },
  {
    "Old School Code": "RJ8027",
    "Name of School ": "Central Children Academy",
    City: "Jhunjhnu",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR8073",
    "New School Code": "HR9073",
    "Name of School ": "St. Thomas Primary School",
    City: "Hansi",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "MP8005",
    "Name of School ": "Canyon Higher Secondary School",
    City: "Bhopal",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "UP8097",
    "New School Code": "UP9097",
    "Name of School ": "Rashad Moral School",
    City: "Azamgarh",
    State: "Uttar Pradesh",
    "Sales Person": "Dilshad Ali ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HR8072",
    "New School Code": "HR9072",
    "Name of School ": "Hello Kidz Svm School",
    City: "Tohana",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8009",
    "Name of School ": "Vivekanand Sr. Sec. School",
    City: "Rewari",
    State: "Haryana",
    "Sales Person": "Gaurav Jha",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "HR8029",
    "Name of School ": "Oxford Public School",
    City: "Hisar",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HR8035",
    "Name of School ": "Oxford Public School",
    City: "Mohali",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HR8031",
    "Name of School ": "Oxford Public School",
    City: "Hisar",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HR8030",
    "Name of School ": "Oxford Public School",
    City: "Hisar",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HR8071",
    "New School Code": "HR9071",
    "Name of School ": "S.S.Public School",
    City: "Tohana",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "BH8013",
    "New School Code": "BH9013",
    "Name of School ": "Garden International School",
    City: "Patna",
    State: "Bihar",
    "Sales Person": "Dheeraj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "WB8006",
    "New School Code": "WB9006",
    "Name of School ": "The Milestone School",
    City: "Nalhati",
    State: "West Bengal",
    "Sales Person": "Rajiv Mukherjee",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "PB8078",
    "New School Code": "PB9078",
    "Name of School ": "Bhagwan Mahavir Jain School",
    City: "Banga ",
    State: "Punjab",
    "Sales Person": "Vivek Malhotra",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "RJ8054",
    "New School Code": "RJ9054",
    "Name of School ": "Gurukripa Public School",
    City: "Jhunjhunu",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR8070",
    "New School Code": "HR9070",
    "Name of School ": "Saraswati Vidya Mandir",
    City: "Tohana",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "GJ8006",
    "Name of School ": "Cosmos Castle International School (Gc Branch)",
    City: "Ahmedabad ",
    State: "Gujarat ",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "GJ8005",
    "Name of School ": "Cosmos Castle International School, Sg Road",
    City: "Ahmedabad ",
    State: "Gujarat ",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "GJ8033",
    "New School Code": "GJ9033",
    "Name of School ": "Sanskar International School",
    City: "Jamnagar",
    State: "Gujarat ",
    "Sales Person": "Shyamsundar Pramanik",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "GJ8010",
    "Name of School ": "Sb Sharma World School",
    City: "Jamnagar",
    State: "Gujarat ",
    "Sales Person": "Shyamsundar Pramanik",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "PB8004",
    "Name of School ": "Sanskar Valley School",
    City: "Talwara",
    State: "Punjab",
    "Sales Person": "Vivek Malhotra",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "PB9077",
    "Name of School ": "Jogindra Convent School",
    City: "Ferozepur",
    State: "Punjab",
    "Sales Person": "Sumit Garg",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HR8069",
    "New School Code": "HR9069",
    "Name of School ": "New Happy Public School",
    City: "Yamunanagar",
    State: "Haryana",
    "Sales Person": "Rohit Tyagi",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "JK8008",
    "Name of School ": "Chenab Valley School",
    City: "Jammu",
    State: "Jammu & Kashmir",
    "Sales Person": "Adish Gupta",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "JK8004",
    "Name of School ": "K2 Educational Institute",
    City: "Srinagar ",
    State: "Jammu & Kashmir",
    "Sales Person": "Khurdheed Ahmad Dar ",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "AS8031",
    "New School Code": "AS9031",
    "Name of School ": "St. Francis D Assisi High School",
    City: "Bongaigaon",
    State: "Assam",
    "Sales Person": "Ravikant Mishra",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "PB8080",
    "New School Code": "PB9080",
    "Name of School ": "Shaheed Udham Singh School",
    City: "Lohian",
    State: "Punjab",
    "Sales Person": "Vivek Malhotra",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "PB8079",
    "New School Code": "PB9079",
    "Name of School ": "Royal Convent School ",
    City: "Moga",
    State: "Punjab",
    "Sales Person": "Sandeep Singh ",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "HR8038",
    "Name of School ": "Moti Lal Nehru Public School",
    City: "Jind",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8017",
    "Name of School ": "Evergay High School",
    City: "Kaithal",
    State: "Haryana",
    "Sales Person": "Ashok Narang",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP8099",
    "New School Code": "UP9099",
    "Name of School ": "Future Diamond Public School",
    City: "G.B Nagar ",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "PB8022",
    "Name of School ": "Wisdom Convent School",
    City: "Fazilka",
    State: "Punjab",
    "Sales Person": "Sumit Garg",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "RJ8055",
    "New School Code": "RJ9055",
    "Name of School ": "Dream High School Of Excellence",
    City: "Didwana",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ8039",
    "Name of School ": "Prince Lotus Valley, Piprali Rd",
    City: " Sikar",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ8005",
    "Name of School ": "Prince Lotus Valley",
    City: " Sikar",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "UP8037",
    "Name of School ": "Vijayshree Gyanashthali",
    City: "Deoria",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "JH8017",
    "New School Code": "JH9017",
    "Name of School ": "Oxford Kids School",
    City: "Jamshedpur",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "JH8002",
    "Name of School ": "Motilal Nehru Public School",
    City: "Jamshedpur",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "JH8004",
    "Name of School ": "Kasidih High School",
    City: "Jamshedpur",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "BH8015",
    "New School Code": "BH9015",
    "Name of School ": "Sanskar Vidhya",
    City: "Daudnagar",
    State: "Bihar",
    "Sales Person": "Danish Iqbal",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "HP8011",
    "New School Code": "HP9011",
    "Name of School ": "Akal Academy Baru Sahib",
    City: "Baru Sahib",
    State: "Himachal Pradesh",
    "Sales Person": "Vijay Paul",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "RJ8057",
    "New School Code": "RJ9057",
    "Name of School ": "Virendra Tiny Tot'S Paradise School",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Manish Sharma",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ8008",
    "Name of School ": "Aims Academy",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "UP8101",
    "New School Code": "UP9101",
    "Name of School ": "Kids Care & Pre-School",
    City: "Greater Noida West",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "DL8014",
    "New School Code": "DL9014",
    "Name of School ": "Mother'S Den School",
    City: "New Delhi",
    State: "Delhi",
    "Sales Person": "Naresh Vashisth",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8052",
    "Name of School ": "Arya Public School",
    City: "Kaushambi",
    State: "Uttar Pradesh",
    "Sales Person": "Prashant Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "DL8015",
    "New School Code": "DL9015",
    "Name of School ": "Angel Public School",
    City: "New Delhi",
    State: "Delhi",
    "Sales Person": "Naresh Vashisth",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "JK8015",
    "Name of School ": "Vir Savarkar Hr. Sec. School",
    City: "Basohli",
    State: "Jammu & Kashmir",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "JK8024",
    "New School Code": "JK9024",
    "Name of School ": "Little Angel Hr. Sec. School",
    City: "Kathua",
    State: "Jammu & Kashmir",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "RJ8056",
    "New School Code": "RJ9056",
    "Name of School ": "G.B.Modi Vidya Mandir",
    City: "Jhunjhunu",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "MP8019",
    "New School Code": "MP9019",
    "Name of School ": "Vidhya Niketan Pre Primary School",
    City: "Chhatarpur ",
    State: "Madhya Pradesh",
    "Sales Person": "Ankur Yadav",
    Trainer: "Yash",
  },
  {
    "Old School Code": "PB8034",
    "Name of School ": "Sant Baba Bhag Singh International School",
    City: "Adampur",
    State: "Punjab",
    "Sales Person": "Vivek Malhotra",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "PB8081",
    "New School Code": "PB9081",
    "Name of School ": "Delhi Convent School",
    City: "Moga",
    State: "Punjab",
    "Sales Person": "Sandeep Singh ",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "HR8019",
    "Name of School ": "S.K. Global Academy",
    City: "Kaithal",
    State: "Haryana",
    "Sales Person": "Ashok Narang",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "BH8014",
    "New School Code": "BH9014",
    "Name of School ": "Bachpan Play School",
    City: "Sasaram",
    State: "Bihar",
    "Sales Person": "Sagar Sinha",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "WB8005",
    "New School Code": "WB9005",
    "Name of School ": "Holy Child English Academy",
    City: "Malda",
    State: "West Bengal",
    "Sales Person": "Subhadip Dey",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "HR3001",
    "Name of School ": "Narar Anchal Senior Secondary School",
    City: "Kaithal",
    State: "Haryana",
    "Sales Person": "Ashok Narang",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "PB8015",
    "Name of School ": "Mukat Public School",
    City: "Rajpura, Distt. Patiala",
    State: "Punjab",
    "Sales Person": "Piyush Sharma",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HP8012",
    "New School Code": "HP9012",
    "Name of School ": "S.S.R.V.M Sr. Sec. School",
    City: "Una",
    State: "Himachal Pradesh",
    "Sales Person": "Piyush Sharma",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "RJ8058",
    "New School Code": "RJ9058",
    "Name of School ": "Vivekanand Public School",
    City: "Jhunjhunu",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "GJ8034",
    "New School Code": "GJ9034",
    "Name of School ": "Om Shanti English Medium School",
    City: "Morbi",
    State: "Gujarat ",
    "Sales Person": "Shyamsundar Pramanik",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "UP8105",
    "New School Code": "UP9105",
    "Name of School ": "Vibgyor International School",
    City: "Hapur",
    State: "Uttar Pradesh",
    "Sales Person": "Chhitiz Sisodia",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR8075",
    "New School Code": "HR9075",
    "Name of School ": "Bk Public Sr. Sec. School",
    City: "Bhiwani",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "JK8016",
    "Name of School ": "Nav Jagriti Niketan Hr. Sec. School",
    City: "Kathua",
    State: "Jammu & Kashmir",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "JK8026",
    "New School Code": "JK9026",
    "Name of School ": "Unique Public Hr. Sec. School",
    City: "Kathua",
    State: "Jammu & Kashmir",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8108",
    "New School Code": "UP9108",
    "Name of School ": "Jaypee Vidya Mandir, Tomri",
    City: "Bulandshahar",
    State: "Uttar Pradesh",
    "Sales Person": "Vikul Vashitha",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "PB8032",
    "Name of School ": "Shri Guru Nanak Dev Ji International School",
    City: "Parowal",
    State: "Punjab",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "PB8084",
    "New School Code": "PB9084",
    "Name of School ": "Shri Guru Harkrishan Modren Sr. Sec. School Branch-2",
    City: "Leel Khurd",
    State: "Punjab",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "PB8083",
    "New School Code": "PB9083",
    "Name of School ": "Shri Guru Harkrishan Modren Sr.Sec.School Branch-1",
    City: "Wadala Granthian",
    State: "Punjab",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "JK8025",
    "New School Code": "JK9025",
    "Name of School ": "Rigveda Convent Hr. Sec. School",
    City: "Kathua",
    State: "Jammu & Kashmir",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8106",
    "New School Code": "UP9106",
    "Name of School ": "B & H International School",
    City: "Gautam Budh Nagar",
    State: "Uttar Pradesh",
    "Sales Person": "Chhitiz Sisodia",
    Trainer: "Beant",
  },
  {
    "Old School Code": "DL8016",
    "New School Code": "DL9016",
    "Name of School ": "Js International School",
    City: "Delhi",
    State: "Delhi",
    "Sales Person": "Karanjit Singh",
    Trainer: "Beant",
  },
  {
    "Old School Code": "MP8020",
    "New School Code": "MP9020",
    "Name of School ": "Christ Convent School ",
    City: "Sagar",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Yash",
  },
  {
    "Old School Code": "RJ8009",
    "Name of School ": "Paramount Global School",
    City: "Bikaner",
    State: "Rajasthan",
    "Sales Person": "Vivekanand Ojha",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR8016",
    "Name of School ": "Litera Heritage School",
    City: "District - Panchkula",
    State: "Haryana",
    "Sales Person": "Piyush Sharma",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "PB8072",
    "New School Code": "PB9072",
    "Name of School ": "Akal Academy Reeth Kheri",
    City: "Patiala",
    State: "Punjab",
    "Sales Person": "Vijay Paul",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "PB8073",
    "New School Code": "PB9073",
    "Name of School ": "Akal Academy Chunni Kalan",
    City: "Fatehgarh",
    State: "Punjab",
    "Sales Person": "Vijay Paul",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "GJ7002",
    "Name of School ": "Anand Niketan - Maninagar Campus",
    City: "Ahmedabad ",
    State: "Gujarat",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "UP8018",
    "Name of School ": "Wict Memorial Public School",
    City: "Noida",
    State: "Uttar Pradesh",
    "Sales Person": "Karan Chauhan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "OR8019",
    "New School Code": "OR9019",
    "Name of School ": "Gyanarth Public School",
    City: "Daspalla",
    State: "Odisha",
    "Sales Person": "Akash Ranjan Behera",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "AS8034",
    "New School Code": "AS9034",
    "Name of School ": "Gopal Chandra Academy",
    City: "Nalbari",
    State: "Assam",
    "Sales Person": "Debanga Pratim Dutta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "UP8109",
    "New School Code": "UP9109",
    "Name of School ": "Mr Memorial Public School",
    City: "Greater Noida",
    State: "Uttar Pradesh",
    "Sales Person": "Karan Chauhan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HP8007",
    "Name of School ": "Green Apple Public School",
    City: "Baddi, Distt. Solan",
    State: "Himachal Pradesh",
    "Sales Person": "Piyush Sharma",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "CT8002",
    "New School Code": "CT9003",
    "Name of School ": "Krishna Public School (Dunda)",
    City: "Raipur",
    State: "Chhattisgarh",
    "Sales Person": "M. Azad Shah",
    Trainer: "Yash",
  },
  {
    "Old School Code": "HR8023",
    "Name of School ": "Sutluj Public School",
    City: "Sirsa",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP8102",
    "New School Code": "UP9102",
    "Name of School ": "Sirajuddin City Montessory School",
    City: "Deoria",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8043",
    "Name of School ": "Trinity Academy",
    City: "Lucknow",
    State: "Uttar Pradesh",
    "Sales Person": "Vijay Gupta",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HR8076",
    "New School Code": "HR9076",
    "Name of School ": "Dsm Public School",
    City: "Charkhi Dadri",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "PB8086",
    "New School Code": "PB9086",
    "Name of School ": "Ambrosial Public School",
    City: "Zira",
    State: "Punjab",
    "Sales Person": "Sumit Garg",
    Trainer: "Beant",
  },
  {
    "Old School Code": "RJ8052",
    "New School Code": "RJ9052",
    "Name of School ": "Rao International School",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "MP8021",
    "New School Code": "MP9021",
    "Name of School ": "Ecs Bagless School, Gwalior",
    City: "Gwalior",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "MP8022",
    "New School Code": "MP9022",
    "Name of School ": "Universal Public School",
    City: "Gotegaon",
    State: "Madhya Pradesh",
    "Sales Person": "Satyendra Mishra",
    Trainer: "Yash",
  },
  {
    "Old School Code": "HR8080",
    "New School Code": "HR9080",
    "Name of School ": "Adarsh Bharti International School",
    City: "Fatehabad",
    State: "Haryana",
    "Sales Person": "Bhupender Singh",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "RJ8023",
    "Name of School ": "Abhinav School",
    City: "Udaipur",
    State: "Rajasthan",
    "Sales Person": "Koushal Gattani",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ8059",
    "New School Code": "RJ9059",
    "Name of School ": "Raghav World School",
    City: "Chomu(Jaipur)",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR8078",
    "New School Code": "HR9078",
    "Name of School ": "St. Bir'S School",
    City: "District - Panchkula",
    State: "Haryana",
    "Sales Person": "Piyush Sharma",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "RJ8061",
    "New School Code": "RJ9061",
    "Name of School ": "Vardhman Srikalyan International School",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ8060",
    "New School Code": "RJ9060",
    "Name of School ": "Vardhman International School",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "AS8035",
    "New School Code": "AS9035",
    "Name of School ": "Fatima Convent Senior Secondary School",
    City: "Rangia",
    State: "Assam",
    "Sales Person": "Debanga Pratim Dutta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "GJ8035",
    "New School Code": "GJ9035",
    "Name of School ": "Sanskar Public School",
    City: "Jamnagar",
    State: "Gujarat",
    "Sales Person": "Shyamsundar Pramanik",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "RJ8007",
    "Name of School ": "Mascot The School",
    City: "Ajmer",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ8062",
    "New School Code": "RJ9062",
    "Name of School ": "Gurukripa Public School, Sewad Bari",
    City: "Sikar",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR8037",
    "Name of School ": "Dayanand Sr. Sec. School",
    City: "Sirsa",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "AS8036",
    "New School Code": "AS9036",
    "Name of School ": "The Scholar School",
    City: "Kalitakuchi ",
    State: "Assam",
    "Sales Person": "Debanga Pratim Dutta",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "BH8001",
    "Name of School ": "G S Residential School",
    City: "Sasaram",
    State: "Bihar",
    "Sales Person": "Sagar Sinha",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "OR8006",
    "Name of School ": "D U Public School",
    City: "Chandikhol Jajpur ",
    State: "Odisha",
    "Sales Person": "Ranjan Samal",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "HR8043",
    "Name of School ": "Mother India Convent School",
    City: "Fatehabad",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8032",
    "New School Code": "HR9032",
    "Name of School ": "New Satluj Sr Sec School",
    City: "Sirsa",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "OR8008",
    "Name of School ": "Chinmaya Public School",
    City: "Rourkela",
    State: "Odisha",
    "Sales Person": "Pramod Ku Panda",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "HR8014",
    "Name of School ": "Gyan Deep Vidya Mandir",
    City: "Rewari",
    State: "Haryana",
    "Sales Person": "Gaurav Jha",
    Trainer: "Beant",
  },
  {
    "Old School Code": "RJ8064",
    "New School Code": "RJ9064",
    "Name of School ": "Kudos Kidz",
    City: "Bhilwara",
    State: "Rajasthan",
    "Sales Person": "Koushal Gattani",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "PB8085",
    "New School Code": "PB9085",
    "Name of School ": "Brookfield International School",
    City: "Kurali, Mohali",
    State: "Punjab",
    "Sales Person": "Piyush Sharma",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "RJ8063",
    "New School Code": "RJ9063",
    "Name of School ": "S.K.M. Public Senior Secondary School",
    City: "Sangaria",
    State: "Rajasthan",
    "Sales Person": "Vivekanand Ojha",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "OR8020",
    "New School Code": "OR9020",
    "Name of School ": "Delhi Public School, Paradip",
    City: "Jagatsinghpur",
    State: "Odisha",
    "Sales Person": "Ranjan Samal",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "PB8019",
    "Name of School ": "Shivalik Hills Public School",
    City: "Rampura Phul",
    State: "Punjab",
    "Sales Person": "Sumit Garg",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UP8098",
    "New School Code": "UP9098",
    "Name of School ": "Shree Ram Kids Global School",
    City: "Greater Noida",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "PB8087",
    "New School Code": "PB9087",
    "Name of School ": "The Alpine School",
    City: "Tarntaran",
    State: "Punjab",
    "Sales Person": "Varun Khanna",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UP8114",
    "New School Code": "UP9114",
    "Name of School ": "The School Of Scholars",
    City: "Greater Noida",
    State: "Uttar Pradesh",
    "Sales Person": "Karan Chauhan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "UP8112",
    "New School Code": "UP9112",
    "Name of School ": "Sdrv Convent School",
    City: " Gautam Budh Nagar",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "UP8113",
    "New School Code": "UP9113",
    "Name of School ": "Kids Villa School",
    City: "Noida",
    State: "Uttar Pradesh",
    "Sales Person": "Karan Chauhan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "UAE8003",
    "New School Code": "UAE9003",
    "Name of School ": "Pearl Wisdom School (Bhavans Group)",
    City: "Dubai",
    State: "Uae",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "UAE8002",
    "New School Code": "UAE9002",
    "Name of School ": "Bhavans Pearl Wisdom School (Bhavans Group)",
    City: "Sharjah ",
    State: "Uae",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "UAE8001",
    "New School Code": "UAE9001",
    "Name of School ": "Al-Saad Indian School (Bhavans)",
    City: "Abu Dhabi",
    State: "Uae",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "WB8007",
    "New School Code": "WB9007",
    "Name of School ": "Hardy Ramanujan Public School",
    City: "Siliguri",
    State: "West Bengal",
    "Sales Person": "Rajiv Mukherjee",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "HR8082",
    "New School Code": "HR9082",
    "Name of School ": "Techno Roots School",
    City: "Gurgaon",
    State: "Haryana",
    "Sales Person": "Vivek Kumar Tyagi",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "MP8023",
    "New School Code": "MP9023",
    "Name of School ": "The Kids Kompany School",
    City: "Indore",
    State: "Madhya Pradesh",
    "Sales Person": "Nitesh Khare",
    Trainer: "Yash",
  },
  {
    "Old School Code": "MP8006",
    "Name of School ": "Vidyawati Public Central School",
    City: "Bhind",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Yash",
  },
  {
    "Old School Code": "MN8004",
    "Name of School ": "Imphal Valley School",
    City: "Imphal East",
    State: "Manipur",
    "Sales Person": "Thoudam Sidartha Meitei",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "HR8081",
    "New School Code": "HR9081",
    "Name of School ": "Spring Dale Public School",
    City: "Yamunanagar",
    State: "Haryana",
    "Sales Person": "Rohit Tyagi",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "WB8008",
    "New School Code": "WB9008",
    "Name of School ": "Rose Bud English Academy",
    City: "Siliguri",
    State: "West Bengal",
    "Sales Person": "Rajiv Mukherjee",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "PB8026",
    "Name of School ": "The Lords School",
    City: "Dhuri ( Dist. Sangrur )",
    State: "Punjab",
    "Sales Person": "Rajdeep Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "HR8084",
    "New School Code": "HR9084",
    "Name of School ": "N.C.C. Sr. Model School",
    City: "Ambala City",
    State: "Haryana",
    "Sales Person": "Rohit Tyagi",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "GJ8036",
    "New School Code": "GJ9036",
    "Name of School ": "Tanna English Medium School",
    City: "Gondal",
    State: "Gujarat",
    "Sales Person": "Shyamsundar Pramanik",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "HR8034",
    "Name of School ": "Dolphin International Playway School",
    City: "Fatehabad",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "RJ8014",
    "Name of School ": "Daisy Days International School",
    City: "Barmer",
    State: "Rajasthan",
    "Sales Person": "Vijay Singh Choudhary",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "UP8034",
    "Name of School ": "Blooming Buds English School",
    City: "Ghazipur",
    State: "Uttar Pradesh",
    "Sales Person": "Rakesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HR8086",
    "New School Code": "HR9086",
    "Name of School ": "Modern Sr. Sec. School",
    City: "Rohtak",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8087",
    "New School Code": "HR9087",
    "Name of School ": "Mamc Campus School",
    City: "Agroha ",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP8021",
    "Name of School ": "Gurukulam Global School",
    City: "Mathura",
    State: "Uttar Pradesh",
    "Sales Person": "Khagesh Pandey",
    Trainer: "Niharika",
  },
  {
    "Old School Code": "UP8115",
    "New School Code": "UP9115",
    "Name of School ": "K.D. Global School",
    City: "Mathura",
    State: "Uttar Pradesh",
    "Sales Person": "Khagesh Pandey",
    Trainer: "Niharika",
  },
  {
    "Old School Code": "DL8007",
    "Name of School ": "Rosemary Public School",
    City: "Delhi",
    State: "Delhi",
    "Sales Person": "Nitesh Rastogi",
    Trainer: "Niharika",
  },
  {
    "Old School Code": "HR8088",
    "New School Code": "HR9088",
    "Name of School ": "Vd Sr. Sec. School",
    City: "Charkhi Dadri",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8089",
    "New School Code": "HR9089",
    "Name of School ": "Shree Krishna Pranami Public School",
    City: "Hissar ",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP8110",
    "New School Code": "UP9110",
    "Name of School ": "St. Sarang Convent School",
    City: "Kaushambi",
    State: "Uttar Pradesh",
    "Sales Person": "Prashant Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "MP8004",
    "Name of School ": "R.D. Public School, Multai",
    City: "Multai",
    State: "Madhya Pradesh",
    "Sales Person": "Sandeep Gour",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "MP8003",
    "Name of School ": "R.D. Public School",
    City: "Betul",
    State: "Madhya Pradesh",
    "Sales Person": "Sandeep Gour",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "JK8027",
    "New School Code": "JK9027",
    "Name of School ": "Kids Heaven Convent Hr. Sec. School",
    City: "Kathua",
    State: "Jammu & Kashmir",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "MP8024",
    "Name of School ": "Aps Academy",
    City: "Sheopur",
    State: "Madhya Pradesh",
    "Sales Person": "Saheb Sharma",
    Trainer: "Yash",
  },
  {
    "Old School Code": "JH8020",
    "New School Code": "JH9020",
    "Name of School ": "Sarvodaya Pathshala",
    City: "Giridih",
    State: "Jharkhand",
    "Sales Person": "Nishant Bhaskar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "JH8019",
    "New School Code": "JH9019",
    "Name of School ": "Bm Nexgen Public School",
    City: "Dhanwar, Giridih",
    State: "Jharkhand",
    "Sales Person": "Nishant Bhaskar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "UP8120",
    "New School Code": "UP9120",
    "Name of School ": "Pd Nigam Education Centre",
    City: "Kanpur",
    State: "Uttar Pradesh",
    "Sales Person": "Sangmeshwar P N Singh",
    Trainer: "Yash",
  },
  {
    "Old School Code": "UP8119",
    "New School Code": "UP9119",
    "Name of School ": "Pd Nigam International School",
    City: "Akbarpur",
    State: "Uttar Pradesh",
    "Sales Person": "Sangmeshwar P N Singh",
    Trainer: "Yash",
  },
  {
    "Old School Code": "JH8018",
    "New School Code": "JH9018",
    "Name of School ": "Make India School",
    City: "District - Giridih",
    State: "Jharkhand",
    "Sales Person": "Nishant Bhaskar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "GJ8011",
    "Name of School ": "Angel English Medium School",
    City: "Vyara",
    State: "Gujarat",
    "Sales Person": "Ketan Sureshbhai Tailor",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "RJ8020",
    "Name of School ": "Sophia Secondary School",
    City: "Jhunjhnu",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "GJ8037",
    "New School Code": "GJ9037",
    "Name of School ": "Crystal Public School",
    City: "Rajkot",
    State: "Gujarat",
    "Sales Person": "Yogaish Kumar",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "MP9001",
    "New School Code": "MP9001",
    "Name of School ": "Rainbow Kidds",
    City: "Gwalior",
    State: "Madhya Pradesh",
    "Sales Person": "Saheb Sharma",
    Trainer: "Yash",
  },
  {
    "Old School Code": "GJ8038",
    "New School Code": "GJ9038",
    "Name of School ": "Crystal School, Jamnagar Road",
    City: "Rajkot",
    State: "Gujarat",
    "Sales Person": "Yogaish Kumar",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "JH9002",
    "New School Code": "JH9002",
    "Name of School ": "Dayawati Modi Public School",
    City: "Chandil",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "JH9001",
    "New School Code": "JH9001",
    "Name of School ": "Mount Everest Public School",
    City: "Ramgarh",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "RJ9001",
    "New School Code": "RJ9001",
    "Name of School ": "Md Academy, Rampura",
    City: "Udaipur",
    State: "Rajasthan",
    "Sales Person": "Koushal Gattani",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ9002",
    "New School Code": "RJ9002",
    "Name of School ": "Md Academy, Ambamata",
    City: "Udaipur",
    State: "Rajasthan",
    "Sales Person": "Koushal Gattani",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ9003",
    "New School Code": "RJ9003",
    "Name of School ": "The Learning Tree International School",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Manish Sharma",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "JK9001",
    "New School Code": "JK9001",
    "Name of School ": "Delhi Public School Ganderbal",
    City: "Ganderbal ",
    State: "Jammu & Kashmir",
    "Sales Person": "Khursheed Ahmad ",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP8117",
    "New School Code": "UP9117",
    "Name of School ": "Galaxy Wisdom",
    City: "Noida",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "UP8118",
    "New School Code": "UP9118",
    "Name of School ": "Learning Tree Academy",
    City: "Greater Noida West",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "RJ8028",
    "Name of School ": "Vayu Sainik Academy",
    City: "Khetri(Jhunjhunu)",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ8016",
    "Name of School ": "Mi Public School",
    City: "Rajgarh(Alwar)",
    State: "Rajasthan",
    "Sales Person": "Manish Sharma",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "PB8030",
    "Name of School ": "Naveen Public School",
    City: "Moobak",
    State: "Punjab",
    "Sales Person": "Amrik Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UP9001",
    "New School Code": "UP9001",
    "Name of School ": "Excellent Circle Of Studies (Ecs Bagless School)",
    City: "Madhogarh,Distt- Jalaun",
    State: "Uttar Pradesh",
    "Sales Person": "Sangmeshwar P N Singh",
    Trainer: "Yash",
  },
  {
    "Old School Code": "HR8085",
    "New School Code": "HR9085",
    "Name of School ": "God Bless Public School",
    City: "Yamunanagar",
    State: "Haryana",
    "Sales Person": "Rohit Tyagi",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR8040",
    "Name of School ": "Plus Point Paathshala School",
    City: "Jind ",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR9001",
    "Name of School ": "Srishti Play School",
    City: "Jind ",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HP8014",
    "New School Code": "HP9014",
    "Name of School ": "Achievers Castle Global School",
    City: "Una",
    State: "Himachal Pradesh",
    "Sales Person": "Piyush Sharma",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "HP8015",
    "New School Code": "HP9015",
    "Name of School ": "Sunbeam International School",
    City: "Jubbal - Shimla",
    State: "Himachal Pradesh",
    "Sales Person": "Piyush Sharma",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UP8107",
    "New School Code": "UP9107",
    "Name of School ": "Oakwood World School",
    City: "Shahjahanpur",
    State: "Uttar Pradesh",
    "Sales Person": "Amit Kumar Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8116",
    "New School Code": "UP9116",
    "Name of School ": "Divine Glory Public School",
    City: "Lucknow",
    State: "Uttar Pradesh",
    "Sales Person": "Amit Kumar Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8111",
    "New School Code": "UP9111",
    "Name of School ": "Mahanagar Public Inter College",
    City: "Lucknow",
    State: "Uttar Pradesh",
    "Sales Person": "Amit Kumar Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "RJ8019",
    "Name of School ": "S.V.M. Convent Sr. Sec. School",
    City: "Via-Sadulsahar, Teh.- Sangaria",
    State: "Rajasthan",
    "Sales Person": "Vivekanand Ojha",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR9002",
    "New School Code": "HR9002",
    "Name of School ": "Nanhe Kadam Global School",
    City: "Charkhi Dadri",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HP9001",
    "New School Code": "HP9001",
    "Name of School ": "Mahavir Public School",
    City: "Tehsil - Sundernagar, District - Mandi",
    State: "Himachal Pradesh",
    "Sales Person": "Piyush Sharma",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "PB3001",
    "Name of School ": "Budha Dal Public School Patiala Junior Wing",
    City: "Patiala",
    State: "Punjab",
    "Sales Person": "Abhinav Bhardwaj",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "PB8023",
    "Name of School ": "Budha Dal Public School Samana",
    City: "Samana",
    State: "Punjab",
    "Sales Person": "Abhinav Bhardwaj",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "PB9001",
    "New School Code": "PB9001",
    "Name of School ": "Samkit International School",
    City: "Sardoolgarh,Mansa",
    State: "Punjab",
    "Sales Person": "Amrik Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "PB8029",
    "Name of School ": "Holy Child Public School",
    City: "Moonak",
    State: "Punjab",
    "Sales Person": "Amrik Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "HR8083",
    "New School Code": "HR9083",
    "Name of School ": "Sainik Public School",
    City: "Bahadurgarh",
    State: "Haryana",
    "Sales Person": "Gaurav Jha",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "PB8082",
    "New School Code": "PB9082",
    "Name of School ": "St. Mary'S School",
    City: "Ropar",
    State: "Punjab",
    "Sales Person": "Piyush Sharma",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "GJ9001",
    "Name of School ": "Vibrant Waves International Academy",
    City: "Lunawada ",
    State: "Gujarat ",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "MP8008",
    "Name of School ": "Ecs Bagless School, Bhind",
    City: "Gwalior Road Bhind",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "DL8017",
    "New School Code": "DL9017",
    "Name of School ": "Vivekanand International School",
    City: "Delhi",
    State: "Delhi",
    "Sales Person": "Nitesh Rastogi",
    Trainer: "Niharika",
  },
  {
    "Old School Code": "UP9005",
    "New School Code": "UP9005",
    "Name of School ": "The Little Scholar",
    City: " Noida ",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR8036",
    "Name of School ": "Delhi Public School Karnal",
    City: "Karnal",
    State: "Haryana",
    "Sales Person": "Ashok Narang",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP8104",
    "New School Code": "UP9104",
    "Name of School ": "Super Forty School",
    City: "Varanasi",
    State: "Uttar Pradesh ",
    "Sales Person": "Yogesh Rastogi",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP9003",
    "New School Code": "UP9003",
    "Name of School ": "St. Lawrence International School",
    City: "Varanasi",
    State: "Uttar Pradesh ",
    "Sales Person": "Yogesh Rastogi",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8103",
    "New School Code": "UP9103",
    "Name of School ": "Parantap Public School",
    City: "Varanasi",
    State: "Uttar Pradesh ",
    "Sales Person": "Manu Khanna ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HR8047",
    "Name of School ": "Bal Vatika Public School",
    City: "Fatehabad ",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP8022",
    "Name of School ": "Captain'S Academy",
    City: "Gautam Buddha Nagar",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "RJ9004",
    "New School Code": "RJ9004",
    "Name of School ": "Alpha International Academy",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "JK9002",
    "New School Code": "JK9002",
    "Name of School ": "Springdales School",
    City: "Jammu",
    State: "Jammu & Kashmir",
    "Sales Person": "Adish Gupta",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "PB9002",
    "New School Code": "PB9002",
    "Name of School ": "Captain'S School Of Excellence",
    City: "Gurdaspur",
    State: "Punjab",
    "Sales Person": "Varun Khanna",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "JK9003",
    "New School Code": "JK9003",
    "Name of School ": "Artisan Educational Institution",
    City: "Kangan",
    State: "Jammu & Kashmir",
    "Sales Person": "Mr Khursheed Ahmad ",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UT8003",
    "Name of School ": "Sheffield School",
    City: "Haridwar",
    State: "Uttarakhand",
    "Sales Person": "Prashant Mishra",
    Trainer: "Niharika",
  },
  {
    "Old School Code": "CT9002",
    "Name of School ": "Delhi Public School",
    City: "Dhamtari",
    State: "Chhattisgarh",
    "Sales Person": "M. Azad Shah",
    Trainer: "Yash",
  },
  {
    "Old School Code": "UP80010",
    "Name of School ": "Romax International School",
    City: "Mathura",
    State: "Uttar Pradesh ",
    "Sales Person": "Khagesh Pandey",
    Trainer: "Niharika",
  },
  {
    "Old School Code": "PB9004",
    "New School Code": "PB9004",
    "Name of School ": "River Woods Smart School",
    City: "Sanghol, Distt. Fatehgarh Sahib",
    State: "Punjab",
    "Sales Person": "Piyush Sharma",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UP9004",
    "New School Code": "UP9004",
    "Name of School ": "Curiosity-The Open House For Kids",
    City: "Greater Noida West",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "CT9001",
    "Name of School ": "Ham Academy",
    City: "Jagdalpur",
    State: "Chhattisgarh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Yash",
  },
  {
    "Old School Code": "RJ7006",
    "Name of School ": "Panorama World School",
    City: "Gudha Gorji(Jhunjhunu)",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "MP9004",
    "Name of School ": "Kidz A First Step Pre School",
    City: "Morena",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Yash",
  },
  {
    "Old School Code": "WB8001",
    "Name of School ": "Balnilaya Academy Foundation",
    City: "Kolkata",
    State: "West Bengal",
    "Sales Person": "Subhadip Dey",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "MP9005",
    "New School Code": "MP9005",
    "Name of School ": "Rising Public School",
    City: "Gwalior",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Yash",
  },
  {
    "Old School Code": "MP9003",
    "New School Code": "MP9003",
    "Name of School ": "Brain Shapers School",
    City: "Katni",
    State: "Madhya Pradesh",
    "Sales Person": "Ankur Yadav",
    Trainer: "Yash",
  },
  {
    "Old School Code": "RJ8011",
    "Name of School ": "Prince International School",
    City: "Jhunjhunu",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ9006",
    "New School Code": "RJ9006",
    "Name of School ": "Rishabh Academy",
    City: "Kapasan",
    State: "Rajasthan",
    "Sales Person": "Koushal Gattani",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "RJ9005",
    "New School Code": "RJ9005",
    "Name of School ": "N.C.A. Sr. Sec. School",
    City: "Sriganga Nagar",
    State: "Rajasthan",
    "Sales Person": "Vivekanand Ojha",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "GJ9002",
    "Name of School ": "I-Gurukul The Place Of Intelligence",
    City: "Surat",
    State: "Gujarat",
    "Sales Person": "Ketan Sureshbhai Tailor",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "OR9001",
    "New School Code": "OR9001",
    "Name of School ": "Blossoms School",
    City: "Bhubaneswar",
    State: "Odisha",
    "Sales Person": "Akashaya Dhal",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "GJ9003",
    "Name of School ": "Navchetan International School",
    City: "Surat",
    State: "Gujarat",
    "Sales Person": "Ketan Sureshbhai Tailor",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "RJ8015",
    "Name of School ": "Star Academy",
    City: "Jhunjhnu",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "MP9006",
    "New School Code": "MP9006",
    "Name of School ": "Abhyuday Public School",
    City: "Sagar",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Yash",
  },
  {
    "Old School Code": "MP9002",
    "New School Code": "MP9002",
    "Name of School ": "Little Dolphins Montessori School",
    City: "Indore",
    State: "Madhya Pradesh",
    "Sales Person": "Shashank Chaturvedi",
    Trainer: "Yash",
  },
  {
    "Old School Code": "RJ9007",
    "New School Code": "RJ9007",
    "Name of School ": "Kiddo Zone School",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR9004",
    "Name of School ": "Smd Little Champ Smart School",
    City: "District - Panchkula",
    State: "Haryana",
    "Sales Person": "Piyush Sharma",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HP9002",
    "Name of School ": "Shiva International Residential School",
    City: "Tehsil - Sadar, District - Bilaspur",
    State: "Himachal Pradesh",
    "Sales Person": "Piyush Sharma",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "BH8002",
    "Name of School ": "Noble Litera School",
    City: "Natwar",
    State: "Bihar",
    "Sales Person": "Sagar Sinha",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "MP9007",
    "Name of School ": "Sanskar Vidhyapeeth Sr. Sec. School",
    City: "Harda",
    State: "Madhya Pradesh",
    "Sales Person": "Sandeep Gour",
    Trainer: "Yash",
  },
  {
    "Old School Code": "HR8050",
    "Name of School ": "Swami Vivekanand Sr. Sec. School, Arniawali",
    City: "Sirsa",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "MP8002",
    "Name of School ": "Ideal Academy",
    City: "Waraseoni, Balaghat",
    State: "Madhya Pradesh",
    "Sales Person": "Ankur Yadav",
    Trainer: "Yash",
  },
  {
    "Old School Code": "UP8046",
    "Name of School ": "Regis Convent School",
    City: "Lucknow",
    State: "Uttar Pradesh",
    "Sales Person": "Deepak Mishra",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8121",
    "New School Code": "UP9126",
    "Name of School ": "Sav Inter College",
    City: "Kaushambi",
    State: "Uttar Pradesh",
    "Sales Person": "Prashant Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8029",
    "Name of School ": "Aakash Global School",
    City: "Barabanki",
    State: "Uttar Pradesh",
    "Sales Person": "Sarvesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8044",
    "Name of School ": "Aspire International School",
    City: "Hardoi",
    State: "Uttar Pradesh",
    "Sales Person": "Sarvesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP9002",
    "Name of School ": "St. Joseph'S School, Bhadohi",
    City: "Bhadohi, Varanasi",
    State: "Uttar Pradesh",
    "Sales Person": "Yogesh Rastogi",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP9007",
    "New School Code": "UP9007",
    "Name of School ": "R K Memorial International School",
    City: "Madhuban Mau",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP9009",
    "New School Code": "UP9009",
    "Name of School ": "Narayan International School",
    City: "Ayodhya",
    State: "Uttar Pradesh",
    "Sales Person": "Sarvesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP9006",
    "New School Code": "UP9006",
    "Name of School ": "Angel Learning Garden",
    City: "Mau",
    State: "Uttar Pradesh",
    "Sales Person": "Rakesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP9010",
    "New School Code": "UP9010",
    "Name of School ": "The City School",
    City: "Lucknow",
    State: "Uttar Pradesh",
    "Sales Person": "Deepak Mishra",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP9011",
    "Name of School ": "Uma Public School",
    City: "Ghazipur",
    State: "Uttar Pradesh",
    "Sales Person": "Praveen Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP9012",
    "Name of School ": "Sks Modern School",
    City: "Chandauli",
    State: "Uttar Pradesh",
    "Sales Person": "Dilshad Ali ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "UP8047",
    "Name of School ": "Career Academy",
    City: "Deoria",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "PB9005",
    "Name of School ": "Wisdom Tree International School",
    City: "Sirhind",
    State: "Punjab",
    "Sales Person": "Abhinav Bhardwaj",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "UP9014",
    "Name of School ": "Greenway Public School",
    City: "Greater Noida West",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "PB9088",
    "Name of School ": "Rainbow Model School",
    City: "Sangrur",
    State: "Punjab",
    "Sales Person": "Amrik Singh",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "HR9003",
    "Name of School ": "Global Public School",
    City: "Bhiwani",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "MP8016",
    "Name of School ": "Kids Zone School, Umaria",
    City: "Lohar Ganj, Umaria",
    State: "Madhya Pradesh",
    "Sales Person": "Ankur Yadav",
    Trainer: "Yash",
  },
  {
    "Old School Code": "UP9122",
    "Name of School ": "Shri Ram Samugh Yadav (Srsy)Inter College",
    City: "Barabanki",
    State: "Uttar Pradesh",
    "Sales Person": "Sarvesh Kumar",
    Trainer: "Abid",
  },
  {
    "Old School Code": "TN9001",
    "Name of School ": "Loyola International School",
    City: "Tehsil - Theni, T. Sindalaicherry",
    State: "Tamilnadu",
    "Sales Person": "Piyush Sharma",
    Trainer: "Yash",
  },
  {
    "Old School Code": "HR8039",
    "Name of School ": "Aim Convent School",
    City: "Bhiwani",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "HR9090",
    "Name of School ": "Saraswati Vidya Vihar Sr. Sec. School",
    City: "Bhiwani",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP9123",
    "Name of School ": "Bal Vidyalaya Madhyamik School",
    City: "Varanasi ",
    State: "Uttar Pradesh",
    "Sales Person": "Dilshad Ali ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "RJ8041",
    "Name of School ": "Shri I G Vidhya Mandir Sr. Sec. School",
    City: "Bilara",
    State: "Rajasthan",
    "Sales Person": "Yogesh Sharma",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR9092",
    "Name of School ": "Swami Chetna Sr. Sec. School",
    City: "Sonipat",
    State: "Haryana",
    "Sales Person": "Sandeep Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP9121",
    "Name of School ": "Dps Champs Karhal",
    City: "Karhal",
    State: "Uttar Pradesh",
    "Sales Person": "Deepak Mishra",
    Trainer: "Abid",
  },
  {
    "Old School Code": "HP8006",
    "Name of School ": "St. Bir'S International School",
    City: "Baddi, Distt. Solan",
    State: "Himachal Pradesh",
    "Sales Person": "Piyush Sharma",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "HR9091",
    "Name of School ": "Shaswat Chetna Sr. Sec. School",
    City: "Sonipat",
    State: "Haryana",
    "Sales Person": "Sandeep Kumar",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UT8002",
    "Name of School ": "G.P.L. Memorial School",
    City: "Haridwar",
    State: "Uttarakhand",
    "Sales Person": "Prashant Mishra",
    Trainer: "Niharika",
  },
  {
    "Old School Code": "MP9025",
    "Name of School ": "Shining Stars Public School",
    City: "Bhind",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Yash",
  },
  {
    "Old School Code": "UP9008",
    "Name of School ": "Saraswati Vision Academy",
    City: "Deoria",
    State: "Uttar Pradesh",
    "Sales Person": "Trideep Gautam",
    Trainer: "Abid",
  },
  {
    "Old School Code": "WB9001",
    "Name of School ": "Abul Hossen Academy ",
    City: "Howrah",
    State: "West Bengal",
    "Sales Person": "Prasenjit Saha",
    Trainer: "Arindam Banik",
  },
  {
    "Old School Code": "JK9028",
    "Name of School ": "New Millennium Public School",
    City: "Kathua",
    State: "Jammu & Kashmir",
    "Sales Person": "Mukesh Kumar",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "UP9013",
    "Name of School ": "Little Angels Nursery School",
    City: "Noida ",
    State: "Uttar Pradesh",
    "Sales Person": "Nitesh Mahajan",
    Trainer: "Beant",
  },
  {
    "Old School Code": "RJ8025",
    "Name of School ": "Aravali Public School",
    City: "Sikar",
    State: "Rajasthan",
    "Sales Person": "Sunil Gurjar",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "GJ8008",
    "Name of School ": "Gen-X Academia",
    City: "Ahmedabad ",
    State: "Gujarat ",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "GJ9039",
    "Name of School ": "P. B. Kotak Memorial English School",
    City: "Rajkot",
    State: "Gujarat ",
    "Sales Person": "Shyamsundar Pramanik",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "JH9021",
    "Name of School ": "Saraswati Vidya Niketan Resi. High School",
    City: "Giridih",
    State: "Jharkhand",
    "Sales Person": "Nishant Bhaskar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "JK9029",
    "Name of School ": "Shemrock Magic Tree",
    City: "Jammu",
    State: "Jammu & Kashmir",
    "Sales Person": "Adish Gupta",
    Trainer: "Dinesh",
  },
  {
    "Old School Code": "JH9022",
    "Name of School ": "Gurukul World Public School",
    City: "Saraikela, Kharsawan",
    State: "Jharkhand",
    "Sales Person": "Pankaj Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "RJ8043",
    "Name of School ": "Mother Teresa Memorial Public School",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Manish Sharma",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "HR8018",
    "Name of School ": "Stepping Stone International School",
    City: "Kaithal",
    State: "Haryana",
    "Sales Person": "Ashok Narang",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HR9093",
    "Name of School ": "Vijay Vallabh Jain International School",
    City: "Shahbad",
    State: "Haryana",
    "Sales Person": "Rohit Tyagi",
    Trainer: "Deepa",
  },
  {
    "Old School Code": "UP9124",
    "Name of School ": "Keshav Gyanasthali International School",
    City: "Prayagraj",
    State: "Uttar Pradesh",
    "Sales Person": "Prashant Singh",
    Trainer: "Anjali",
  },
  {
    "Old School Code": "AS8037",
    "New School Code": "AS9037",
    "Name of School ": "Mother'S Genius Preschool & Daycare",
    City: "Guwahati",
    State: "Assam",
    "Sales Person": "Abhilash Chandra Goswami",
    Trainer: "Joseph ",
  },
  {
    "Old School Code": "MP9026",
    "Name of School ": "Adhyan Kids International School",
    City: "Bina",
    State: "Madhya Pradesh",
    "Sales Person": "Sumit Gahlot",
    Trainer: "Yash",
  },
  {
    "Old School Code": "MP8009",
    "Name of School ": "Simran Public School",
    City: "Maihar",
    State: "Madhya Pradesh",
    "Sales Person": "Ankur Yadav",
    Trainer: "Yash",
  },
  {
    "Old School Code": "UP9125",
    "Name of School ": "Ss Public School",
    City: "Jaunpur",
    State: "Uttar Pradesh",
    "Sales Person": "Praveen Singh ",
    Trainer: "Abid",
  },
  {
    "Old School Code": "BH8007",
    "Name of School ": "Rns Delhi Public School",
    City: "Ara",
    State: "Bihar",
    "Sales Person": "Prashant Kumar",
    Trainer: "Ujjawal",
  },
  {
    "Old School Code": "RJ8032",
    "Name of School ": "Bagiya Play School",
    City: "Jaipur",
    State: "Rajasthan",
    "Sales Person": "Manish Sharma",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "KW9003",
    "Name of School ": "Bhavans Indian Educational School",
    City: "Kuwait ",
    State: "Kuwait ",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "KW9002",
    "Name of School ": "Bhavans Smart Indian School",
    City: "Kuwait ",
    State: "Kuwait ",
    "Sales Person": "Sushant Kaushal ",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HR9094",
    "Name of School ": "Guru Nanak International Academy",
    City: "Sirsa ",
    State: "Haryana",
    "Sales Person": "Vasdev ",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "HR9095",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Dhani Mahu",
    City: "Bhiwani",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9096",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Sector 33",
    City: "Gurugram",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9097",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Sector 92",
    City: "Gurugram",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9098",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Hansi",
    City: "Hisar",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9099",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Bibipur",
    City: "Jind",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9100",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Kanina",
    City: "Mahendergarh",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9101",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Kosli",
    City: "Rewari",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9074",
    "Name of School ": "Yaduvanshi Shiksha Niketan",
    City: "Mahendergarh",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9102",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Mandola",
    City: "Charkhi Dadri",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9103",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Rewari",
    City: "Rewari",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9104",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Satnali",
    City: "Mahendragarh",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9105",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Thanwas",
    City: "Mahendragarh",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "HR9106",
    "Name of School ": " Yaduvanshi Shiksha Niketan, Narnaul",
    City: "Narnaul",
    State: "Haryana",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "RJ9065",
    "Name of School ": "Yaduvanshi Shiksha Niketan, Sohali",
    City: "Jhunjhunu",
    State: "Rajasthan",
    "Sales Person": "Munish Kumar",
    Trainer: "Beant",
  },
  {
    "Old School Code": "UP9127",
    "Name of School ": "The Aditeya World School",
    City: "Varanasi",
    State: "Uttar Pradesh",
    "Sales Person": "Praveen Singh",
    Trainer: "Abid",
  },
  {
    "Old School Code": "GJ9040",
    "Name of School ": "Ashiya English School",
    City: "Ahmedabad ",
    State: "Gujarat",
    "Sales Person": "Mr. Samir Patel ",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "MP8010",
    "Name of School ": "Elegance Academy",
    City: "Udaigarh",
    State: "Madhya Pradesh",
    "Sales Person": "Nitesh Rastogi",
    Trainer: "Yash",
  },
  {
    "Old School Code": "MP9028",
    "Name of School ": "Gyanoday School",
    City: "Harda",
    State: "Madhya Pradesh",
    "Sales Person": "Sandeep Gour",
    Trainer: "Yash",
  },
  {
    "Old School Code": "RJ9066",
    "Name of School ": "First Step Bright Future School",
    City: "Churu",
    State: "Rajasthan",
    "Sales Person": "Anshuman Bhuria",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "GJ9043",
    "Name of School ": "Gyandeep Vidhyalay",
    City: "Surat",
    State: "Gujarat",
    "Sales Person": "Ketan Sureshbhai Tailor",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "MP9024",
    "Name of School ": "Maa Parvati Memorial School, Ambua",
    City: "Alirajpur",
    State: "Madhya Pradesh",
    "Sales Person": "Nitesh Rastogi",
    Trainer: "Yash",
  },
  {
    "Old School Code": "MP8011",
    "Name of School ": "Maa Parvati Memorial School",
    City: "Alirajpur",
    State: "Madhya Pradesh",
    "Sales Person": "Nitesh Rastogi",
    Trainer: "Yash",
  },
  {
    "Old School Code": "JK8003",
    "Name of School ": "Falqan Public School",
    City: "Srinagar ",
    State: "Jammu & Kashmir",
    "Sales Person": "Khursheed Ahmad  Dar",
    Trainer: "Tarun Kapoor",
  },
  {
    "Old School Code": "RJ8040",
    "Name of School ": "Sunrise Public School",
    City: "Sinwara (Sanchore), Chitalwana",
    State: "Rajasthan",
    "Sales Person": "Yogesh Sharma",
    Trainer: "Akanksha Juneja",
  },
  {
    "Old School Code": "GJ9041",
    "Name of School ": "Bai Navajbai Tata English Medium School",
    City: "Surat",
    State: "Gujarat",
    "Sales Person": "Ketan Sureshbhai Tailor",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "MP9027",
    "Name of School ": "Shiksha School",
    City: "Nowgong, Chhatarpur",
    State: "Madhaya Pradesh",
    "Sales Person": "Ankur Yadav",
    Trainer: "Yash",
  },
  {
    "Old School Code": "GJ9042",
    "Name of School ": "Gyandeep International School, Kareli",
    City: "Surat",
    State: "Gujarat",
    "Sales Person": "Ketan Sureshbhai Tailor",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "GJ9044",
    "Name of School ": "Sabari Vidya Peedom English Medium School",
    City: "Bharuch",
    State: "Gujarat",
    "Sales Person": "Ketan Sureshbhai Tailor",
    Trainer: "Rutvi",
  },
  {
    "Old School Code": "UP9128",
    "Name of School ": "St. Joseph School, Chausa Road",
    City: "Kunda",
    State: "Uttar Pradesh",
    "Sales Person": "Mr. Akash Agarwal ",
    Trainer: "Abid",
  },
];
