import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect, lazy, Suspense } from "react";
import GlobelErrorSnackbar from "./Components/Material/GlobelErrorSnackbar";
import Loader from "./Components/Material/Loader";

// import ManageOrderReturn from "./Pages/RETURN/ManageOrderReturn";
import { ReturnOrderPdf } from "./Pages/RETURN/ReturnOrderPdf";
import Maintanance from "./Pages/Maintanance";
import AllReturnWarehouse from "./Pages/Warehouse/AllReturn";
import TagSchoolAgainstInv from "./Pages/TagSchoolAgainstInv";
import TrainerDashboard from "./Pages/Interventions/Trainer/Dashboard";
import SubmitIntervention from "./Pages/Interventions/Trainer/SubmitIntervention";
import SuperVisorDashboard from "./Pages/Interventions/Supervisor/Dashbaord";
import Insights from "./Pages/Interventions/Trainer/Insights";
import SalesInterventionDashboard from "./Pages/Interventions/Sales/DashBoard";
import UserManagement from "./Pages/Admin/UserManagement";
import UserChangePassword from "./Pages/Admin/ChangePassword";
import UserCreateUser from "./Pages/Admin/CreateUser";
import UpdateSchoolReq from "./Pages/Admin/UpdateSchoolReq";
import ApproveUpdateSchoolReq from "./Pages/Admin/ApproveUpdateSchoolReq";
import SchoolInsight from "./Pages/SchoolInsigth";
const Discount = lazy(() => import("./Pages/Discount"));
// import AllReturn from "./Pages/SalesCoordinator/AllReturn";
// Pages
// import MySchool from "./Pages/MySchool";
// import SchoolDirectory from "./Pages/SchoolDirectory";
// import SalesInvoices from "./Pages/SalesInvoice";
// import UpdateStocks from "./Pages/UpdateStocks";
// import Opportunities from "./Pages/Opportunities";
// import AdminSignUp from "./Pages/AdminSignUp";
// import AdminAllUser from "./Pages/AdminAllUser";
// import AdminState from "./Pages/AdminState";
// import AdminCity from "./Pages/AdminCity";
// import AdminCountry from "./Pages/AdminCountry";
// import AdminLogin from "./Pages/AdminLogin";
// import MyDocument from "./Components/PdfDocument";
const Login = lazy(() => import("./Pages/Login"));
const Home = lazy(() => import("./Pages/Home"));
const Tagging = lazy(() => import("./Pages/Tagging"));
const ManageSchool = lazy(() => import("./Pages/ManageSchool"));
const AdminHome = lazy(() => import("./Pages/AdminHome"));
const AddNewCity = lazy(() => import("./Pages/AddNewCity"));
const SchoolTagging = lazy(() => import("./Pages/SchoolTagging"));
const Schools = lazy(() => import("./Pages/Schools"));
const TagSchool = lazy(() => import("./Pages/TagSchool"));
const AddSchool = lazy(() => import("./Pages/AddSchool"));
const SchoolPunchIn = lazy(() => import("./Pages/SchoolPunchIn"));
const OrderProcessing = lazy(() => import("./Pages/OrderProcessing"));
const ManageOrder = lazy(() => import("./Pages/ManageOrder"));
const AOF = lazy(() => import("./Pages/AOF"));
const KYS = lazy(() => import("./Pages/KYS"));
const ReleaseNote = lazy(() => import("./Pages/ReleaseNote"));
const V_1_0_0 = lazy(() => import("./Pages/ReleaseNotes/V.1.0.0"));
const UpdateSchool = lazy(() => import("./Pages/UpdateSchool"));
const Products = lazy(() => import("./Pages/KYS/Product"));
const AddProduct = lazy(() => import("./Pages/KYS/AddProduct"));
const Strength = lazy(() => import("./Pages/KYS/Strength"));
const AddStrength = lazy(() => import("./Pages/KYS/AddStrength"));
const UpdateFees = lazy(() => import("./Pages/KYS/UpdateFees"));
const Competition = lazy(() => import("./Pages/KYS/Competition"));
const AddCompetition = lazy(() => import("./Pages/KYS/AddCompetition"));
const AddWorkShop = lazy(() => import("./Pages/KYS/AddWorkShop"));
const Workshop = lazy(() => import("./Pages/KYS/Workshop"));
const PageNotFound = lazy(() => import("./Pages/PageNotFound"));
const Projection = lazy(() => import("./Pages/Projection"));
const Addprojection = lazy(() => import("./Pages/Addprojection"));
const Return = lazy(() => import("./Pages/RETURN/Return"));
const ReturnRequest = lazy(() => import("./Pages/RETURN/RequestReturn"));
const Invoice = lazy(() => import("./Pages/Invoice"));
const InvoiceItem = lazy(() => import("./Pages/InvoiceItem"));
const ManageSchoolTraining = lazy(() => import("./Pages/ManageSchoolTraining"));
const AddSchoolTraining = lazy(() => import("./Pages/AddSchoolTraining"));
const UpdateSchoolTraining = lazy(() => import("./Pages/UpdateSchoolTraining"));
const InvoiceTraining = lazy(() => import("./Pages/InvoiceTraining"));
const Inv = lazy(() => import("./Pages/PDF/Inv"));
const ViewPdf = lazy(() => import("./Pages/ViewPdf"));
const AddSchoolQuantity = lazy(() => import("./Pages/AddSchoolQuantity"));
const ClassklapDashboard = lazy(() => import("./Pages/ClassklapDashboard"));
const ClassklapManageSchool = lazy(() =>
  import("./Pages/ClassklapManageSchool")
);
const ReturnOrder = lazy(() => import("./Pages/RETURN/ReturnOrder"));
const RequestHistory = lazy(() => import("./Pages/RETURN/RequestHistory"));
const AddSchoolCK = lazy(() => import("./Pages/AddSchoolCK"));
const LocationTraining = lazy(() => import("./Pages/LocationTraining"));
const PrintPDF = lazy(() => import("./Pages/PrintPDF"));
const ViewInvoiceSingle = lazy(() => import("./Pages/ViewInvoiceSingle"));
const ViewInvoiceDouble = lazy(() => import("./Pages/ViewInvoiceDouble"));
const BulkInv = lazy(() => import("./Pages/PDF/BulkInv"));
const CustLedger = lazy(() => import("./Pages/PDF/CustLedger"));
const EmpStatement = lazy(() => import("./Pages/PDF/EmpStatement"));
const ClassklapSchool = lazy(() => import("./Pages/ClassklapSchool"));
const UpdateSchoolTrainingEU = lazy(() =>
  import("./Pages/UpdateSchoolTrainingEU")
);
const GatePass = lazy(() => import("./Pages/PDF/GatePass"));
const GatePassDashboard = lazy(() => import("./Pages/GatePassDashboard"));
const GatePassInvoice = lazy(() => import("./Pages/GatePassInvoice"));
const OrderTraining = lazy(() => import("./Pages/OrderTraining"));
const AofPdf = lazy(() => import("./Pages/PDF/AofPdf"));
const AofPdf2 = lazy(() => import("./Pages/PDF/AofPdf2"));
const AOFcreate = lazy(() => import("./Pages/AOFcreate"));
const ViewCustomerLedger = lazy(() => import("./Pages/ViewCustomerLedger"));
const AdminManageSchool = lazy(() => import("./Pages/AdminManageSchool"));
const AdminAddSchool = lazy(() => import("./Pages/AdminAddSchool"));
const AdminTagging = lazy(() => import("./Pages/AdminTagging"));
const AdminUploadInvoice = lazy(() => import("./Pages/AdminUploadInvoice"));
const ZsmAOF = lazy(() => import("./Pages/ZsmAof"));
const AdminInvoice = lazy(() => import("./Pages/AdminInvoice"));
const AdminInvoiceItem = lazy(() => import("./Pages/AdminInvoiceItem"));
const AdminAddSchoolQuantity = lazy(() =>
  import("./Pages/AdminAddSchoolQuantity")
);
const FinanceAOF = lazy(() => import("./Pages/FinanceAOF"));
const SalesheadAOF = lazy(() => import("./Pages/SalesheadAOF"));
const AOFEdit = lazy(() => import("./Pages/AOFEdit"));
const AofPdf3temp = lazy(() => import("./Pages/PDF/AofPdf3temp"));
const AofPdf4 = lazy(() => import("./Pages/PDF/AofPdf4"));
const ThankYou = lazy(() => import("./Pages/ThankYou"));
const HrHome = lazy(() => import("./Pages/HR/HrHome"));
const User = lazy(() => import("./Pages/HR/User"));
const PhoneGroup = lazy(() => import("./Pages/HR/PhoneGroup"));
const AdminCkReport = lazy(() => import("./Pages/AdminCkReport"));
const CreditNote = lazy(() => import("./Pages/PDF/CreditNote"));
const CreditSinglePdf = lazy(() => import("./Pages/CreditSinglePdf"));
const SalesToCash = lazy(() => import("./Pages/SalesToCash"));
const SendMessage = lazy(() => import("./Pages/HR/SendMessage"));
const AddPrintingReq = lazy(() => import("./Pages/Printing/AddPrintingReq"));
const CheckStatus = lazy(() => import("./Pages/Printing/CheckStatus"));
const Reimbursment = lazy(() => import("./Pages/Reimbursment"));
const EmployeeStatement = lazy(() => import("./Pages/EmployeeStatement"));

const DocPrint = lazy(() => import("./Pages/SM/DocPrint"));
const ManageOrderReturn = lazy(() =>
  import("./Pages/RETURN/ManageOrderReturn")
);
const AllReturn = lazy(() => import("./Pages/SalesCoordinator/AllReturn"));

function App() {
  const isAuth = useSelector((state) => state.auth.user);
  const MsAuth = useSelector((state) => state.auth.msAuth);

  const Admin = useSelector((state) => state.auth.admin);
  const Zsm = useSelector((state) => state.auth.zsm);
  const Finance = useSelector((state) => state.auth.finance);
  const Saleshead = useSelector((state) => state.auth.saleshead);
  const Training = useSelector((state) => state.auth.training);
  const HR = useSelector((state) => state.auth.HR);
  const Warehouse = useSelector((state) => state.auth.gatepass);
  const Editorial = useSelector((state) => state.auth.editorial);
  const IT = useSelector((state) => state.auth.IT);
  const SM = useSelector((state) => state.auth.SM);
  const SalesCoordinator = useSelector((state) => state.auth.salesCoordinator);
  const Trainer = useSelector((state) => state.auth.trainer);
  const SuperVisor = useSelector((state) => state.auth.supervisor);
  useEffect(() => {
    ReactGA.initialize("G-WWFF5R3TB6");
  }, []);

  // console.log("testing ci/cd!");

  return (
    <div>
      <div>
        {/* {console.log(Saleshead)} */}
        {/* {console.log(MsAuth)} */}
        {/* {console.log(Admin)} */}
        {/* {console.log(HR)} */}
        <div className="!font-Roboto bg-[#111322]">
          <GlobelErrorSnackbar />
          <BrowserRouter>
            {/* <Suspense fallback={<Loader loading={true} />}>
              <Routes>
                <Route path="*" element={<Maintanance />} />
              </Routes>
            </Suspense> */}
            <Suspense fallback={<Loader loading={true} />}>
              <Routes>
                <Route
                  path="/login"
                  element={isAuth || MsAuth ? <Home /> : <Login />}
                />
                <Route
                  path="/"
                  element={
                    isAuth || MsAuth || Admin || Zsm ? <Home /> : <Login />
                  }
                />
                <Route
                  path="/tagging"
                  element={isAuth || MsAuth || Zsm ? <Tagging /> : <Login />}
                />
                <Route
                  path="/manageSchool"
                  element={
                    isAuth || MsAuth || Zsm || SalesCoordinator ? (
                      <ManageSchool />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/tag_school_against_inv"
                  element={
                    SalesCoordinator ? <TagSchoolAgainstInv /> : <Login />
                  }
                />
                <Route
                  path="/manageSchoolTraining"
                  element={
                    isAuth || MsAuth || Training ? (
                      <ManageSchoolTraining />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/school/tagging"
                  element={
                    isAuth || MsAuth || Zsm ? <SchoolTagging /> : <Login />
                  }
                />
                <Route
                  path="/school/schools"
                  element={isAuth || MsAuth || Zsm ? <Schools /> : <Login />}
                />
                <Route
                  path="/tagschool"
                  element={isAuth || MsAuth || Zsm ? <TagSchool /> : <Login />}
                />
                <Route
                  path="/addschool"
                  element={
                    isAuth || MsAuth || Zsm || SalesCoordinator ? (
                      <AddSchool />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/addschooltraining"
                  element={
                    MsAuth || Training ? <AddSchoolTraining /> : <Login />
                  }
                />
                <Route
                  path="/update_school/:id"
                  element={
                    isAuth || MsAuth || Zsm ? <UpdateSchool /> : <Login />
                  }
                />
                <Route
                  path="/update_school_training/:id/:scode"
                  element={
                    MsAuth || Training || Zsm ? (
                      <UpdateSchoolTraining />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/update_school_training_eu/:scid/:stid/:scode"
                  element={
                    MsAuth || Training ? <UpdateSchoolTrainingEU /> : <Login />
                  }
                />
                <Route
                  path="/school/punch_in"
                  element={
                    isAuth || MsAuth || Zsm ? <SchoolPunchIn /> : <Login />
                  }
                />
                <Route
                  path="/order_processing"
                  element={
                    isAuth || MsAuth || Zsm ? <OrderProcessing /> : <Login />
                  }
                />
                <Route
                  path="/manage_order"
                  element={
                    isAuth || MsAuth || Zsm ? <ManageOrder /> : <Login />
                  }
                />
                <Route
                  path="/projection"
                  element={isAuth || MsAuth || Zsm ? <Projection /> : <Login />}
                />
                <Route
                  path="/addprojection"
                  element={
                    isAuth || MsAuth || Zsm ? <Addprojection /> : <Login />
                  }
                />
                <Route
                  path="/aof"
                  element={isAuth || MsAuth || Zsm ? <AOF /> : <Login />}
                />
                <Route
                  path="/kys"
                  element={isAuth || MsAuth || Zsm ? <KYS /> : <Login />}
                />
                <Route
                  path="/kys/products/:id"
                  element={isAuth || MsAuth || Zsm ? <Products /> : <Login />}
                />
                <Route
                  path="/kys/products/add_product/:id"
                  element={isAuth || MsAuth || Zsm ? <AddProduct /> : <Login />}
                />
                <Route
                  path="/kys/strength/:id"
                  element={isAuth || MsAuth || Zsm ? <Strength /> : <Login />}
                />
                <Route
                  path="/kys/strength/add_strength/:id"
                  element={
                    isAuth || MsAuth || Zsm ? <AddStrength /> : <Login />
                  }
                />
                <Route
                  path="/kys/tution_fees/:id"
                  element={isAuth || MsAuth || Zsm ? <UpdateFees /> : <Login />}
                />
                <Route
                  path="/kys/add_competition/:id"
                  element={
                    isAuth || MsAuth || Zsm ? <AddCompetition /> : <Login />
                  }
                />
                <Route
                  path="/kys/competition/:id"
                  element={
                    isAuth || MsAuth || Zsm ? <Competition /> : <Login />
                  }
                />
                <Route
                  path="/kys/workshop/:id"
                  element={isAuth || MsAuth || Zsm ? <Workshop /> : <Login />}
                />
                <Route
                  path="/kys/add_workshop/:id"
                  element={
                    isAuth || MsAuth || Zsm ? <AddWorkShop /> : <Login />
                  }
                />
                <Route
                  path="/web-release-notes"
                  element={
                    isAuth || MsAuth || Zsm ? <ReleaseNote /> : <Login />
                  }
                />
                <Route
                  path="/web-release-notes/v_1_0_0"
                  element={isAuth || MsAuth || Zsm ? <V_1_0_0 /> : <Login />}
                />
                <Route
                  path="/return"
                  element={isAuth || MsAuth || Zsm ? <Return /> : <Login />}
                />
                <Route
                  path="/return_request"
                  element={
                    isAuth || MsAuth || Zsm ? <ReturnRequest /> : <Login />
                  }
                />
                <Route
                  path="/request_order_return"
                  element={isAuth ? <ReturnOrder /> : <Login />}
                />
                <Route
                  path="/request_history"
                  element={isAuth ? <RequestHistory /> : <Login />}
                />

                <Route
                  path="/request_order_return_pdf/:id"
                  element={<ReturnOrderPdf />}
                />
                {/* <Route
                  path="/manage_order_return"
                  element={isAuth ? <ManageOrderReturn /> : <Login />}
                /> */}
                <Route
                  path="/invoice"
                  element={isAuth || MsAuth || Zsm ? <Invoice /> : <Login />}
                />
                <Route
                  path="/invoice_item/:invoiceid"
                  element={
                    isAuth || MsAuth || Zsm ? <InvoiceItem /> : <Login />
                  }
                />
                <Route
                  path="/addschoolquantity/:invoiceid"
                  element={
                    isAuth || MsAuth || Zsm ? <AddSchoolQuantity /> : <Login />
                  }
                />
                <Route
                  path="/invoice_training"
                  element={MsAuth || Training ? <InvoiceTraining /> : <Login />}
                />
                <Route
                  path="/order_training"
                  element={MsAuth || Training ? <OrderTraining /> : <Login />}
                />
                <Route
                  path="/view_pdf/:docnum/:docdate"
                  element={<ViewPdf />}
                />
                <Route path="/salesToCash" element={<SalesToCash />} />
                <Route path="/view_aof_pdf/:aofid" element={<AofPdf3temp />} />
                <Route path="/view_aof_pdf2/:aofid" element={<AofPdf2 />} />
                <Route path="/zsm/view_aof_pdf2/:aofid" element={<AofPdf2 />} />
                <Route
                  path="/credit_note_pdf/:docNum/:docdate"
                  element={<CreditNote />}
                />
                <Route
                  path="/saleshead/view_aof_pdf2/:aofid"
                  element={<AofPdf2 />}
                />
                <Route
                  path="/finance/view_aof_pdf2/:aofid"
                  element={<AofPdf2 />}
                />
                <Route
                  path="/customer/aof/verify/:aofid"
                  element={<AofPdf4 />}
                />
                <Route path="/thankyou" element={<ThankYou />} />
                <Route
                  path="/bulkinv_pdf/:bp/:todate/:fromdate"
                  element={<BulkInv />}
                />
                <Route
                  path="/ck_dashboard"
                  element={
                    isAuth || MsAuth ? <ClassklapDashboard /> : <Login />
                  }
                />
                <Route
                  path="/ck_manageSchool"
                  element={
                    isAuth || MsAuth ? <ClassklapManageSchool /> : <Login />
                  }
                />
                <Route
                  path="/addschoolck"
                  element={isAuth || MsAuth ? <AddSchoolCK /> : <Login />}
                />
                <Route
                  path="/locationTraining"
                  element={
                    MsAuth || Training ? <LocationTraining /> : <Login />
                  }
                />
                <Route
                  path="/print_pdf"
                  element={
                    isAuth || MsAuth || Zsm || Admin || SalesCoordinator ? (
                      <PrintPDF />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/invoice_pdf_single"
                  element={
                    isAuth ||
                    MsAuth ||
                    Zsm ||
                    SM ||
                    Admin ||
                    SalesCoordinator ? (
                      <ViewInvoiceSingle />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/credit/invoice_pdf_single"
                  element={
                    isAuth ||
                    MsAuth ||
                    Zsm ||
                    SM ||
                    Admin ||
                    SalesCoordinator ? (
                      <CreditSinglePdf />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/invoice_pdf_double"
                  element={
                    isAuth ||
                    MsAuth ||
                    Zsm ||
                    SM ||
                    Admin ||
                    SalesCoordinator ? (
                      <ViewInvoiceDouble />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/customer_pdf"
                  element={
                    isAuth ||
                    MsAuth ||
                    Zsm ||
                    SM ||
                    Admin ||
                    SalesCoordinator ? (
                      <ViewCustomerLedger />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/cust_ledger/:bp/:todate/:fromdate"
                  element={<CustLedger />}
                />
                <Route
                  path="/employe_statement/:employeeId/:startDate/:endDate"
                  element={<EmpStatement />}
                />
                <Route path="/gate_pass_pdf/:id" element={<GatePass />} />
                <Route
                  path="/add_new_city"
                  element={MsAuth || Training ? <AddNewCity /> : <Login />}
                />
                <Route
                  path="/ck_school_training"
                  element={MsAuth || Training ? <ClassklapSchool /> : <Login />}
                />
                <Route
                  path="/gatepass_dashboard"
                  element={
                    Warehouse || MsAuth ? <GatePassDashboard /> : <Login />
                  }
                  // element={<GatePassDashboard />}
                />
                <Route
                  path="/gatepass_invoice"
                  element={
                    Warehouse || MsAuth ? <GatePassInvoice /> : <Login />
                  }
                />
                <Route
                  path="/aof_create"
                  element={isAuth || MsAuth || Zsm ? <AOFcreate /> : <Login />}
                />
                <Route
                  path="/school_insight"
                  element={isAuth ? <SchoolInsight /> : <Login />}
                />
                <Route
                  path="/aof_edit/:aofid"
                  element={isAuth || MsAuth || Zsm ? <AOFEdit /> : <Login />}
                />
                <Route
                  path="/admin/home"
                  element={Admin || MsAuth ? <AdminHome /> : <Login />}
                />
                <Route
                  path="/admin/usersMangement"
                  element={Admin ? <UserManagement /> : <Login />}
                />
                <Route
                  path="/admin/usersMangement/changePassword"
                  element={Admin ? <UserChangePassword /> : <Login />}
                />
                <Route
                  path="/admin/usersMangement/createUser"
                  element={Admin ? <UserCreateUser /> : <Login />}
                />
                <Route
                  path="/admin/usersMangement"
                  element={Admin ? <UserManagement /> : <Login />}
                />
                <Route
                  path="/admin/manageschool/updateSchool"
                  element={Admin ? <UpdateSchoolReq /> : <Login />}
                />
                <Route
                  path="/admin/manageschool/updateSchool/:id"
                  element={Admin ? <ApproveUpdateSchoolReq /> : <Login />}
                />

                <Route
                  path="/admin/manageschool"
                  element={Admin || MsAuth ? <AdminManageSchool /> : <Login />}
                />
                <Route
                  path="/admin/uploadinvoice"
                  element={Admin || MsAuth ? <AdminUploadInvoice /> : <Login />}
                />
                <Route
                  path="/admin/addschool"
                  element={Admin || MsAuth ? <AdminAddSchool /> : <Login />}
                />
                <Route
                  path="/admin/invoice"
                  element={Admin || MsAuth ? <AdminInvoice /> : <Login />}
                />
                <Route
                  path="/admin/ckreport"
                  element={Admin || MsAuth ? <AdminCkReport /> : <Login />}
                />
                <Route
                  path="/admin/invoice_item/:invoiceid"
                  element={<AdminInvoiceItem />}
                />
                <Route
                  path="/admin/addschoolquantity/:invoiceid"
                  element={<AdminAddSchoolQuantity />}
                />
                <Route
                  path="/zsm/verify_aof"
                  element={Zsm || MsAuth ? <ZsmAOF /> : <Login />}
                />
                <Route
                  path="/finance/aof"
                  element={Finance || MsAuth ? <FinanceAOF /> : <Login />}
                />
                <Route
                  path="/finance/discount"
                  element={Finance || MsAuth ? <Discount /> : <Login />}
                  // element={<FinanceAOF />}
                />
                <Route
                  path="/saleshead/aof"
                  element={Saleshead || MsAuth ? <SalesheadAOF /> : <Login />}
                />
                <Route
                  path="/hr/home"
                  element={HR || MsAuth ? <HrHome /> : <Login />}
                />
                <Route
                  path="/hr/user"
                  element={HR || MsAuth ? <User /> : <Login />}
                />
                <Route
                  path="/hr/phonegroup"
                  element={HR || MsAuth ? <PhoneGroup /> : <Login />}
                />
                <Route
                  path="/hr/sendmessage"
                  element={HR || MsAuth ? <SendMessage /> : <Login />}
                />
                <Route
                  path="/printing/newPrintingReq"
                  element={Editorial ? <AddPrintingReq /> : <Login />}
                />
                <Route
                  path="/printing/checkStatus"
                  element={Editorial ? <CheckStatus /> : <Login />}
                />
                <Route
                  path="/reimbursement_report"
                  element={
                    isAuth || Editorial || Finance || Training || IT ? (
                      <Reimbursment />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/employeestatement_report"
                  element={
                    isAuth || Editorial || Finance || Training || IT ? (
                      <EmployeeStatement />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path="/sm/doc_print"
                  element={SM ? <DocPrint /> : <Login />}
                />
                <Route
                  path="*"
                  element={isAuth ? <PageNotFound /> : <Login />}
                />
                <Route
                  path="/sc/all_return"
                  element={SalesCoordinator ? <AllReturn /> : <Login />}
                />
                <Route
                  path="/warehouse/all_return"
                  element={Warehouse ? <AllReturnWarehouse /> : <Login />}
                />
                {/* Interventions */}
                {/* Trainer */}
                <Route
                  path="/interventions/trainer/dashboard"
                  element={Trainer ? <TrainerDashboard /> : <Login />}
                />
                <Route
                  path="/interventions/trainer/:id/:schoolId/:schoolName"
                  element={Trainer ? <SubmitIntervention /> : <Login />}
                />
                <Route
                  path="/interventions/trainer/insights"
                  element={Trainer ? <Insights /> : <Login />}
                />
                {/* SuperVisor */}
                <Route
                  path="/interventions/supervisor/dashboard"
                  element={SuperVisor ? <SuperVisorDashboard /> : <Login />}
                />
                {/* Sales */}
                <Route
                  path="/interventions/sales/dashboard"
                  element={isAuth ? <SalesInterventionDashboard /> : <Login />}
                />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}

export default App;
