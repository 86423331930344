import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";
// import SearchDropDown from "../../../Components/SearchDropDown";
import SwipeableTemporaryDrawer from "../../../Components/Material/MaterialSidebar";
// import instance from "../../../Instance";
import { useLayoutEffect } from "react";
// import Cookies from "js-cookie";
// import BasicButton from "../../../Components/Material/Button";
import { Backdrop, CircularProgress } from "@mui/material";
import ReactGA from "react-ga4";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import CollapsibleTable from "../../../Components/TrainerInterventions/CollapsableTable";
import instance from "../../../Instance";
import Cookies from "js-cookie";
import ckInstance from "../../../ckInstance";
// import { Search } from "@mui/icons-material";
// import CustomizedSteppers from "../../../Components/Material/Stepper";

const TrainerDashboard = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [highLight, setHighLight] = useState("dashboard");
  const [loading, setLoading] = useState(false);
  const [schools, setSchool] = useState([]);
  const sidebarRef = useRef();

  const navInfo = {
    title: "Dashboard",
    details: ["Home", " / Dashboard"],
  };

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };

    window.addEventListener("resize", handleWidth);
    handleWidth();
    const pagePath = window.location.pathname;

    ReactGA.send({
      hitType: "pageview",
      page: pagePath,
      title: "User Page Count",
    });
    window.scroll(0, 0);
    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  // const navigate = useNavigate();

  // const getStatus = async (schoolId, seriesId) => {
  //   const res = await instance({
  //     url: `interventions/getApplicableInterventionType/${
  //       schoolId === null ? JSON.parse(data.schoolInfo).a.id : schoolId
  //     }/${seriesId === null ? data.seriesId : seriesId}`,
  //     method: "GET",
  //     headers: {
  //       Authorization: `${Cookies.get("accessToken")}`,
  //     },
  //   });
  //   const newData = res.data.data.map((item) => {
  //     return { ...item, label: item?.type };
  //   });
  //   newData.sort((a, b) => a.sequence - b.sequence);
  //   setInterventions(newData);
  // };

  // const getSeries = async (schoolId) => {
  //   const token = await returnCkAuthToken();
  //   const seriesData = await axios.get(
  //     `https://sb0.imaxprogram.com/app/visor/assignedSeries?schoolCode=${schoolId}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );

  //   console.log(seriesData.data);
  // };

  const returnCkAuthToken = async () => {
    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("client_id", "c2Nob29sX21pdHJh");
    params.append("secret", "Y2xhc3NrbGFwQDIwMjM=");
    params.append("email", "prateek.mudliar@classklap.com");

    const res = await ckInstance({
      url: `v1/login/eupheus`,
      method: "POST",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return res.data.accessToken;
  };

  useLayoutEffect(() => {
    const getCkSchools = async () => {
      setLoading(true);
      const token = await returnCkAuthToken();

      const getSchool = await ckInstance({
        url: `visor/allSchools/2324`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let schoolCodeIdSet = new Set();
      const uniqueSchools = getSchool.data.filter((school) => {
        if (schoolCodeIdSet.has(school.code)) {
          return false;
        } else {
          schoolCodeIdSet.add(school.code);
          return true;
        }
      });

      const schoolCodes = uniqueSchools.map((school) => {
        return school.code;
      });

      const salesData = await instance({
        url: `school/ckschools/getUsersByCkCodeList`,
        method: "POST",
        data: {
          ckCodes: schoolCodes,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      const schoolListData = uniqueSchools.map((school) => {
        return {
          id: school.code,
          label: school.name,
          state: school.state,
          trainer: school.coordinator.join(", "),
          sales: salesData.data.message[school.code],
        };
      });
      schoolListData.sort((a, b) => {
        if (a.sales === "NA" && b.sales !== "NA") {
          return 1; // Move "NA" sales to the end
        } else {
          return -1; // Keep non-"NA" sales before "NA"
        }
      });
      setSchool(schoolListData);
      setLoading(false);
    };

    getCkSchools();
  }, []);

  const columns = ["School Name", "State", "Trainer", "Sales Person"];

  return (
    <div className="flex bg-[#111322]">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Sidebar sidebarCollapsed={sidebarCollapsed} highLight={highLight} />

      <div>
        <SwipeableTemporaryDrawer
          ref={sidebarRef}
          sidebarCollapsed={sidebarCollapsed}
          highLight={highLight}
          // show={show}
        />
      </div>

      <div
        className={`flex flex-col w-[100vw] lg:w-[83vw] lg:ml-[18vw] ${
          window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[60vw]"
        } `}
      >
        <Navbar
          handleSidebarCollapsed={handleSidebarCollapsed}
          info={navInfo}
        />
        <div className="min-h-[100vh] pt-[2vh] max-h-full bg-[#141728]">
          <div className=" sm:px-8 px-2 py-3 bg-[#141728]">
            <CollapsibleTable
              columns={columns}
              data={schools}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerDashboard;
