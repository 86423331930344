import {
  AddTask,
  Circle,
  CurrencyRupee,
  Dashboard,
  DirectionsWalk,
  KeyboardArrowDown,
  KeyboardReturn,
  LocalShipping,
  LocationCityOutlined,
  PrintOutlined,
  School,
  ShoppingBag,
  Timeline,
} from "@mui/icons-material";
import { Collapse } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const SalesSidebar = ({ highLight, isZsmLogin }) => {
  const [school, setSchool] = useState(false);
  const [returReq, setReturReq] = useState(false);

  return (
    <>
      <Link to="/">
        <aside
          className={`px-6 py-2 hover:bg-gray-500 flex ${
            highLight === "dashboard" ? "bg-gray-500" : ""
          } rounded-md gap-4 cursor-pointer group`}
        >
          <div className="flex gap-4">
            <Dashboard
              className={`${
                highLight === "dashboard" ? "!text-[#659DBD]" : "!text-gray-400"
              } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
            />
            <span
              className={`${
                highLight === "dashboard" ? "text-gray-200" : "text-gray-400"
              } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
            >
              DashBoard
            </span>
          </div>
          {/* <hr className="text-gray-300" /> */}
        </aside>
      </Link>
      {/* 
      <Link to="/manageSchool">
        <aside
          className={`px-6 py-2 flex gap-4 cursor-pointer ${
            highLight === "manageSchool" ? "bg-gray-500" : ""
          } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <School
            className={`${
              highLight === "manageSchool"
                ? "!text-[#659DBD]"
                : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "manageSchool" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Manage School
          </span>
        </aside>
      </Link> */}
      <Link>
        <aside
          onClick={() => setSchool(!school)}
          className={`px-6 py-2 flex justify-between gap-4 ${
            highLight === "manage_school" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <div className="flex gap-4">
            <School
              className={`${
                highLight === "manage_school"
                  ? "!text-gray-100"
                  : "!text-gray-400"
              } group-hover:!text-gray-100 !transition-all !duration-150 !ease-linear`}
            />
            <span
              className={`${
                highLight === "manage_school"
                  ? "text-gray-200"
                  : "text-gray-400"
              } group-hover:!text-gray-100 font-semibold transition-all duration-150 ease-linear`}
            >
              School
            </span>
          </div>
          <div
            className={`transition-all duration-200  ease-linear ${
              school ? null : "-rotate-90"
            }`}
          >
            <KeyboardArrowDown className={`text-gray-600 `} />
          </div>
        </aside>
        <Collapse in={school}>
          {" "}
          <div
            className={`${
              school ? "h-fit opacity-100 visible" : null
            } transition-all ease-linear duration-200`}
          >
            <Link to="/manageSchool">
              <div
                className={`flex items-center transition-all ease-linear duration-100 mr-8 ml-6 group `}
              >
                <Circle
                  className={`!text-[.7rem] !transition-all !ease-linear !duration-200 text-gray-300`}
                />
                <h1
                  className={`pl-9 text-gray-300 transition-all ease-linear text-sm font-semibold duration-200  py-2 cursor-pointer`}
                >
                  Manage School
                </h1>
              </div>
            </Link>
            {/* <Link to="/varthana_school">
              <div
                className={`flex items-center transition-all ease-linear duration-100 mr-8 ml-6 group `}
              >
                <Circle
                  className={`!text-[.7rem] !transition-all !ease-linear !duration-200 text-gray-300`}
                />
                <h1
                  className={`pl-9 text-gray-300 transition-all ease-linear text-sm font-semibold duration-200  py-2 cursor-pointer`}
                >
                  Varthana School
                </h1>
              </div>
            </Link> */}
            <Link to="/school_insight">
              <div
                className={`flex items-center transition-all ease-linear duration-100 mr-8 ml-6 group `}
              >
                <Circle
                  className={`!text-[.7rem] !transition-all !ease-linear !duration-200 text-gray-300`}
                />
                <h1
                  className={`pl-9 text-gray-300 transition-all ease-linear text-sm font-semibold duration-200  py-2 cursor-pointer`}
                >
                  School Analytics
                </h1>
              </div>
            </Link>
          </div>
        </Collapse>
      </Link>

      <Link to="/order_processing">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "order_pro" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <LocalShipping
            className={`${
              highLight === "order_pro" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "order_pro" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Order Processing
          </span>
        </aside>
      </Link>
      <Link to="/manage_order">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "manageOrder" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <ShoppingBag
            className={`${
              highLight === "manageOrder" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "manageOrder" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Manage Order
          </span>
        </aside>
      </Link>

      {isZsmLogin ? (
        <Link to="/zsm/verify_aof">
          <aside
            className={`px-6 py-2 flex gap-4 ${
              highLight === "aofVerify" ? "bg-gray-500" : ""
            } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
          >
            <AddTask
              className={`${
                highLight === "aofVerify" ? "!text-[#659DBD]" : "!text-gray-400"
              } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
            />
            <span
              className={`${
                highLight === "aofVerify" ? "text-gray-200" : "text-gray-400"
              } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
            >
              AOF Verify
            </span>
          </aside>
        </Link>
      ) : null}

      <Link to="/kys">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "kys" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <Timeline
            className={`${
              highLight === "kys" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "kys" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            KYS
          </span>
        </aside>
      </Link>
      {/* <Link to="/kys"> */}
      <Link to="/projection">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "projection" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <LocationCityOutlined
            className={`${
              highLight === "projection" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "projection" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Projection
          </span>
        </aside>
      </Link>

      <Link to="/print_pdf">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "printpdf" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <PrintOutlined
            className={`${
              highLight === "printpdf" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "printpdf" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Doc Print
          </span>
        </aside>
      </Link>
      <Link to="/salesToCash">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "salesToCash" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <CurrencyRupee
            className={`${
              highLight === "salesToCash" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "salesToCash" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Order To Cash
          </span>
        </aside>
      </Link>
      {/* <Link to="/request_order_return">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "return_req" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <KeyboardReturn
            className={`${
              highLight === "return_req" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "return_req" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Return Request
          </span>
        </aside>
      </Link> */}

      <Link>
        <aside
          onClick={() => setReturReq(!returReq)}
          className={`px-6 py-2 flex justify-between gap-4 ${
            highLight === "return_req" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <div className="flex gap-4">
            <KeyboardReturn
              className={`${
                highLight === "return_req"
                  ? "!text-[#659DBD]"
                  : "!text-gray-400"
              } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
            />
            <span
              className={`${
                highLight === "return_req" ? "text-gray-200" : "text-gray-400"
              } group-hover:!text-gray-100 font-semibold transition-all duration-150 ease-linear`}
            >
              Return Request
            </span>
          </div>
          <div
            className={`transition-all duration-200  ease-linear ${
              returReq ? null : "-rotate-90"
            }`}
          >
            <KeyboardArrowDown className={`text-gray-600 `} />
          </div>
        </aside>
        <Collapse in={returReq}>
          {" "}
          <div
            className={`${
              returReq ? "h-fit opacity-100 visible" : null
            } transition-all ease-linear duration-200`}
          >
            <Link to="/request_order_return">
              <div
                className={`flex items-center transition-all ease-linear duration-100 mr-8 ml-6 group `}
              >
                <Circle
                  className={`!text-[.7rem] !transition-all !ease-linear !duration-200 text-gray-300`}
                />
                <h1
                  className={`pl-9 text-gray-300 transition-all ease-linear text-sm font-semibold duration-200  py-2 cursor-pointer`}
                >
                  Return Request
                </h1>
              </div>
            </Link>
            <Link to="/request_history">
              <div
                className={`flex items-center transition-all ease-linear duration-100 mr-8 ml-6 group `}
              >
                <Circle
                  className={`!text-[.7rem] !transition-all !ease-linear !duration-200 text-gray-300`}
                />
                <h1
                  className={`pl-9 text-gray-300 transition-all ease-linear text-sm font-semibold duration-200  py-2 cursor-pointer`}
                >
                  Request History
                </h1>
              </div>
            </Link>
          </div>
        </Collapse>
      </Link>
      {/* <Link to="/manage_order_return">
      <aside
        className={`px-6 py-2 flex gap-4 ${
          highLight === "manage_return_req" ? "bg-gray-500" : ""
        } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
      >
        <ManageSearch
          className={`${
            highLight === "manage_return_req"
              ? "!text-[#659DBD]"
              : "!text-gray-400"
          } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
        />
        <span
          className={`${
            highLight === "manage_return_req"
              ? "text-gray-200"
              : "text-gray-400"
          } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
        >
          Manage Return Request
        </span>
      </aside>
    </Link> */}
      <Link to="/interventions/sales/dashboard">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "intervention" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <DirectionsWalk
            className={`${
              highLight === "intervention"
                ? "!text-[#659DBD]"
                : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "intervention" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Interventions
          </span>
        </aside>
      </Link>
    </>
  );
};

export default SalesSidebar;
