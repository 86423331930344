import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarChart = ({ chartData, title, labels }) => {
  console.log(chartData);

  const data = {
    labels,
    datasets: [
      ...chartData.map((item) => {
        return {
          label: item.label,
          data: [item.data],
          backgroundColor: item.color,
        };
      }),
    ],
    plugins: {
      datalabels: {
        color: "#fff",
      },
    },
  };

  return (
    <Bar
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            callbacks: {
              title: function (context) {
                return "";
              },
            },
          },
          title: {
            display: true,
            text: title,
          },
        },
      }}
      data={data}
    />
  );
};
