export const handleDate = (date) => {
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = (months.indexOf(date.split(" ")[1]) + 1)
    .toString()
    .padStart(2, "0");
  let modifiedDate = `${date.split(" ")[3]}-${month}-${date.split(" ")[2]}`;

  return modifiedDate;
};
